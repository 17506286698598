import { QUOTE_META_UPDATE, QUOTE_META_RESET } from "../actions/actionTypes";

const initialState = () => {
    return {
        id: "",
        name: "",
        details: "",
        jobNumber: "",
        jobInformation: "", // SSP-85 Deprecated
        jobName: "",
        campaignStartDate: null,
        printJobStartDate: null,
        createdOn: null,
        updatedOn: null,
        createdBy: null,
        updatedBy: null,
        tags: [],
        customer: null,
        customerCmsCode: null,
        promoCode: null,
    };
};

export const quoteMeta = (state = initialState(), action) => {
    switch (action.type) {
        case QUOTE_META_UPDATE: {
            const next = JSON.parse(JSON.stringify(state));
            const update = JSON.parse(JSON.stringify(action.payload));
            return { ...next, ...update };
        }
        case QUOTE_META_RESET:
            return initialState();
        default:
            return state;
    }
};
