import { render, html, nothing } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import dialogPolyfill from "dialog-polyfill/index.js";
import "./dialogs/printDetails";
import "./dialogs/importRounds";
import "./dialogs/quotesList";
import "./dialogs/reviewCustomerChanges";
import "./dialogs/saveCustomerChanges";
import "./dialogs/saveQuote";
import "./dialogs/customerDetails";
import "./dialogs/quotePdf";
import "./dialogs/quoteSetting";
import { clientAppBootstrappedEvent } from "./appIsLoading";

export const clientDialogCloseEvent = new Event("client:dialog:close");

// global one
let dialog;
document.addEventListener(clientAppBootstrappedEvent.type, () => {
    dialog = document.querySelector("dialog");
    if (dialog) {
        try {
            dialogPolyfill.registerDialog(dialog);
        } catch (error) {
            console.error(`Can't find dialog wrapper!`);
            console.error(error);
        }

        dialog.addEventListener("close", () => {
            document.removeEventListener(
                clientDialogCloseEvent.type,
                dialogClose
            );
            render(nothing, dialog);
        });
    }
});

const dialogClose = () => {
    dialog.close();
};

export const openDialog = (dialogElement) => {
    const dialogElementHTML = `<${dialogElement}></${dialogElement}>`;
    render(html`${unsafeHTML(dialogElementHTML)}`, dialog);
    dialog.showModal();

    document.addEventListener(clientDialogCloseEvent.type, dialogClose);
};
