export const TAG_TARGETING = "TARGETING";
export const TAG_ROUNDS_LOOKUP = "ROUNDS_LOOKUP";
export const TAG_NETWORK = "NETWORK";
export const TAG_GEO_VALUES = "GEO_VALUES";
export const TAG_NOTIFICATION = "NOTIFICATION";

const permission = (global.Notification || {}).permission || "denied";

export const plainWarning = (title, body, tag) =>
    "granted" === permission
        ? new global.Notification(title, { icon: "favicon.png", tag, body })
        : null;
