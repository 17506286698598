import { LitElement, html } from "lit-element";
import { subscribe, unsubscribe } from "redux-subscribe";

import { noop } from "../../core/actions/actions";
import { hasCustomerChanges } from "../../services/volumes";
import store from "../../store";
import { openDialog } from "../dialog";
import { clientInfoNoDelayEvent } from "../toast";

class SaveCustomerChangesDialogButton extends LitElement {
    static get properties() {
        return {
            hasChanges: { type: Boolean },
        };
    }

    updated() {
        store.dispatch(
            subscribe(
                "territories",
                "saveCustomerChangesTerritoriesUpdate",
                (data) => {
                    const territories = data.next;

                    if (territories) {
                        this.hasChanges = hasCustomerChanges(territories);
                    }
                    return noop("saveCustomerChangesTerritoriesUpdate");
                }
            )
        );
    }

    disconnectedCallback() {
        store.dispatch(
            unsubscribe("territories", "saveCustomerChangesTerritoriesUpdate")
        );
    }

    async openDialog() {
        if (this.hasChanges) {
            openDialog("rm-save-customer-changes");
        } else {
            document.dispatchEvent(
                clientInfoNoDelayEvent({
                    message: "No new changes made to the quote.",
                })
            );
        }
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <button
                @click=${() => this.openDialog()}
                title="Propose Changes"
                class="text-white ml-2 px-2 py-1 rounded-sm border border-black focus:outline-none hover:border-white 
            ${this.hasChanges ? "" : "cursor-not-allowed"}"
            >
                <span class="uppercase text-sm font-medium"
                    >Propose Changes</span
                >
            </button>
        `;
    }
}

global.customElements.define(
    "rm-save-customer-changes-dialog-button",
    SaveCustomerChangesDialogButton
);
