import { LitElement, html } from "lit-element";
import { nothing } from "lit-html";
import { subscribe, unsubscribe } from "redux-subscribe";

import store from "../../store";
import { noop } from "../../core/actions/actions";

import "../quoteSummaryTable";
import "./quotePrintSpecs";
import "./targetingSummary";

class ClientQuoteSummaryCard extends LitElement {
    constructor() {
        super();

        this.printingLine = store.getState().quoteDetails.printingLine;
        this.settings = store.getState().settings;

        store.dispatch(
            subscribe(
                "quoteDetails.printingLine",
                "printingLineWatcher",
                (data) => {
                    this.printingLine = data.next;
                    return noop("printingLineWatcher");
                }
            )
        );

        store.dispatch(
            subscribe("settings", "settingsWatcher", (data) => {
                this.settings = data.next;
                return noop("settingsWatcher");
            })
        );
    }

    static get properties() {
        return {
            printingLine: { type: Object },
            settings: { type: Object },
        };
    }

    disconnectedCallback() {
        store.dispatch(
            unsubscribe("quoteDetails.printingLine", "printingLineWatcher")
        );
        store.dispatch(unsubscribe("settings", "settingsWatcher"));
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="pt-4 px-4 block">
                ${this.settings.hideQuoteSummary
                    ? nothing
                    : html`<h3 class="pb-1 text-xl font-medium text-dark-grey">
                          Cost summary
                      </h3>`}
                ${this.settings.hideQuoteSummary
                    ? nothing
                    : html`<rm-quote-summary-table
                          enableGst
                          hideZeroLines
                      ></rm-quote-summary-table>`}
                ${this.printingLine
                    ? html`<rm-quote-print-specs></rm-quote-print-specs>`
                    : nothing}
                ${this.settings.targetingSummary
                    ? html`<rm-targeting-summary></rm-targeting-summary>`
                    : nothing}
            </div>
        `;
    }
}

global.customElements.define(
    "rm-client-quote-summary-card",
    ClientQuoteSummaryCard
);
