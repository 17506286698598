import { LitElement, html } from "lit-element";
import { nothing } from "lit-html";
import { repeat } from "lit-html/directives/repeat";
import { classMap } from "lit-html/directives/class-map";

import { clientSelectedOptionEvent } from "./typeAheadOptions";
import crossIcon from "./svg/cross";

class WordsCloud extends LitElement {
    constructor() {
        super();
        this.items = [];
        this.selected = [];

        this.baseCss = {
            flex: true,
            "text-sm": true,
            uppercase: true,
            "items-center": true,
            border: true,
            "border-blue": true,
            "py-1": true,
            "px-2": true,
            "mr-1": true,
            "mb-1": true,
            "cursor-pointer": true,
            "hover:bg-blue-25": true,
        };
        this.activeCss = {
            "border-blue": true,
            "bg-blue-25": true,
        };
    }

    static get properties() {
        return {
            items: { type: Array },
            selected: { type: Array },
            hideSelected: { type: Boolean },
            placeholder: { type: String },
        };
    }

    select(event) {
        event.stopPropagation();
        if (event.target.dataset.item) {
            this.dispatchEvent(
                clientSelectedOptionEvent(event.target.dataset.item)
            );
        }
    }

    itemCss(active) {
        let activeCss = { ...this.baseCss, ...this.activeCss };
        if (this.hideSelected) {
            activeCss = { ...activeCss, hidden: true };
        }

        return active ? classMap(activeCss) : classMap(this.baseCss);
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-row flex-wrap" @click=${this.select}>
                ${!this.items.length && this.placeholder
                    ? html`<span class="text-sm py-2"
                          >${this.placeholder}</span
                      >`
                    : nothing}
                ${repeat(
                    this.items,
                    (item) => html` <span
                        data-item="${item}"
                        class="${this.itemCss(this.selected.includes(item))}"
                    >
                        ${item}
                        ${this.selected.includes(item)
                            ? html`<span
                                  class="ml-1 px-1 text-white bg-blue pointer-events-none"
                                  >${crossIcon}</span
                              >`
                            : nothing}
                    </span>`
                )}
            </div>
        `;
    }
}

global.customElements.define("rm-words-cloud", WordsCloud);
