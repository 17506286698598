import clonedeep from "lodash-es/cloneDeep";

import {
    QUOTE_CONFIG_UPDATE,
    QUOTE_CONFIG_RESET,
    UPDATE_LODGEMENT_DETAILS,
} from "../actions/actionTypes";
import { GMAP_ROUNDS_LAYERS } from "../../const/dataLayers";
const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;
import { MAILER_TYPE } from "../../const/mailerType";

import { initialState as printingInit } from "./printingSpecs";
import { calcPageWeight } from "../../services/weight";

import { LODGEMENT_SITE, LODGEMENT_SITE_OPTIONS } from "../../const/lodgement";

import { ADMIN_FEE_DEFAULT } from "../../services/httpApis/adminFee";

const initialState = () => ({
    locked: false,
    hasFreight: true,
    printingLine: false,
    targeted: false,
    // start: used only as calculated values for APIs calls
    extraCopiesFee: 0,
    extraCopies: 0,
    // end
    lodgement: {
        [LODGEMENT_SITE.AUCKLAND]: {
            [LODGEMENT_SITE_OPTIONS.DROPS]: {
                volume: 0,
            },
            [LODGEMENT_SITE_OPTIONS.COPIES]: {
                volume: 0,
                fee: 0,
            },
        },
        [LODGEMENT_SITE.CHRISTCHURCH]: {
            [LODGEMENT_SITE_OPTIONS.DROPS]: {
                volume: 0,
            },
            [LODGEMENT_SITE_OPTIONS.COPIES]: {
                volume: 0,
                fee: 0,
            },
        },
    },
    weight: calcPageWeight(printingInit.paperSize, printingInit.gsm),
    customWeightBracket: null,
    distribution: {
        [URBAN]: {
            volume: 0,
            freight: 0,
        },
        [POSTIES]: {
            volume: 0,
            freight: 0,
        },
        [RURAL]: {
            volume: 0,
            freight: 0,
        },
        [POBOXES]: {
            volume: 0,
            freight: 0,
        },
        [COUNTERS]: {
            volume: 0,
            freight: 0,
        },
    },
    subtotalMatrix: {
        [URBAN]: 0,
        [POSTIES]: 0,
        [RURAL]: 0,
        [POBOXES]: 0,
        [COUNTERS]: 0,
    },
    volumeCost: 0,
    freightCost: 0,
    vfrCost: 0,
    printCost: 0,
    networkManagementCost: 0,
    targetingCost: 0,
    adminFee: ADMIN_FEE_DEFAULT,
    dairyFarmerPremium: 0,
    apartmentsPremium: 0,
    mailerType: MAILER_TYPE.MAILER,
    enclosed: false,
    oversized: false,
    subtotal: ADMIN_FEE_DEFAULT,
    minAmtAdjustment: 0,
    discount: 0,
});

export const quoteDetails = (state = initialState(), action) => {
    switch (action.type) {
        case UPDATE_LODGEMENT_DETAILS: {
            const next = clonedeep(state);
            const { site, option, volume, fee } = action.payload;

            // Update the volume if provided and ensure it's a positive integer
            if (volume !== undefined) {
                let numericVolume = Math.max(0, parseInt(volume, 10));
                numericVolume = isNaN(numericVolume) ? 0 : numericVolume;
                next.lodgement[site][option].volume = numericVolume;
            }

            // Update the fee if provided and ensure it's a positive decimal with two digits after the decimal point
            if (fee !== undefined) {
                let numericFee = Math.max(
                    0,
                    parseFloat(parseFloat(fee).toFixed(2))
                );
                numericFee = isNaN(numericFee) ? 0 : numericFee;
                next.lodgement[site][option].fee = numericFee;
            }

            return next;
        }
        case QUOTE_CONFIG_UPDATE: {
            const next = clonedeep(state);
            const update = action.payload;

            // check for null string reset of customWeightBracket value
            if (
                Object.prototype.hasOwnProperty.call(
                    update,
                    "customWeightBracket"
                ) &&
                "null" === update.customWeightBracket
            ) {
                update.customWeightBracket = null;
            }

            return { ...next, ...update };
        }
        case QUOTE_CONFIG_RESET:
            return initialState();
        default:
            return state;
    }
};
