const dataLayer = global.dataLayer;

const preservedDLKeys = ["gtm", "user_id"];

/**
 * Clean up data layer, preserving some keys across the session
 */
export const flushDataLayer = () => {
    if (!dataLayer) return;
};

export const getTargetingTags = (quoteId, targetingFilters) => {
    if (!dataLayer) return [];

    // transform targeting filters
    return targetingFilters
        .flatMap((filter) => filter.groups)
        .map((group) => group.variablesGroup)
        .map((variableGroup) => ({ id: quoteId, variableGroup }));
};

export const getQuoteTag = (quoteId) => {
    if (!dataLayer) return {};
    return { id: quoteId };
};

export const pushTag = (event, tag) => {
    if (dataLayer && tag)
        try {
            dataLayer.push({ event, ...tag });
        } catch (error) {
            console.log(error);
        }
};
