export const VOLUME_SLICES = {
    INCLUSIVE: "INCLUSIVE",
    EXCLUSIVE: "EXCLUSIVE",
    RESIDENTIAL: "RESIDENTIAL",
    BUSINESS: "BUSINESS",
    FARMER: "FARMER",
    DAIRY: "DAIRY",
    NEWSPAPER_INCLUSIVE: "NEWSPAPER_INCLUSIVE",
    NEWSPAPER_EXCLUSIVE: "NEWSPAPER_EXCLUSIVE",
    NEWSPAPER_ALL: "NEWSPAPER_ALL",
};
