import clonedeep from "lodash-es/cloneDeep";
import { ROUNDS_LOAD_DATA, ROUNDS_FLUSH_DATA } from "../actions/actionTypes";

const initialState = {};

export const roundsData = (state = initialState, action) => {
    switch (action.type) {
        case ROUNDS_FLUSH_DATA:
            return initialState;
        case ROUNDS_LOAD_DATA: {
            const next = clonedeep(state);
            const features = {};
            action.payload.forEach((feature) => {
                features[feature.properties.walk_id] = feature;
            });
            return { ...next, ...features };
        }
        default:
            return state;
    }
};
