import store from "../store";

import { sliceVolume } from "../core/selectors/sliceSelector";

import { GMAP_ROUNDS_LAYERS } from "../const/dataLayers";
const { RURAL } = GMAP_ROUNDS_LAYERS;
import { VOLUME_SLICES } from "../const/volumeSlices";
import { farmerPremiumQuote } from "./httpApis/farmerPremium";
const { DAIRY } = VOLUME_SLICES;

/**
 * Total cost calculation logic is based on requirement of a minimal charge for
 * volume and freight combined.
 */
export const totalCost = () => {
    return store.getState().quoteDetails.subtotal;
};

/**
 * Logic to calculate network management fee to provide respective quote line

 * @param {Number} rate Per 1000
 * @param {Number} volume 
 */
export const networkManagement = (rate, volume) => {
    return ((rate * volume) / 1000).toFixedNumber(2);
};

/**
 * Logic to calculate targeting fee to provide respective quote line

 * @param {Number} rate Per 1000
 * @param {Number} volume 
 */
export const targetingFee = (rate, volume) => {
    return ((rate * volume) / 1000).toFixedNumber(2);
};

/**
 * Logic to calculate dairy farmers premium to add up to volume quote
 * $28 per 1000
 *
 * @param {Number} volume
 */
export const dairyFarmerPremium = (rate, volume) => {
    return ((rate * volume) / 1000).toFixedNumber(2);
};

/**
 * Logic to calculate apartments premium to indicate as a separate quote line
 * $140 per 1000
 *
 * @param {Number} volume
 */
export const apartmentsPremium = (volume) => {
    return ((140 * volume) / 1000).toFixedNumber(2);
};

export const dairyFarmer = async () => {
    const dairyFarmersVolume = sliceVolume(RURAL, DAIRY);

    const { amount } = await farmerPremiumQuote(dairyFarmersVolume);
    return amount;
};
