// Number pototype update
import "./services/numberHelper";

// Store must be init as soon as possible
import "./store";

import "../css/client-presenter.css";
import "dialog-polyfill/dist/dialog-polyfill.css";

import { auth } from "./services/firebase/init";
import { clientUserAuthenticatedEvent } from "./services/firebase/auth";

// bootstrap default client components such as map
import "./services/clientDefaults";

// single application entry point
import "./components/clientWrapper";

// display progress spinner for fun
import "./components/appIsLoading";

// this happens once only, so make sure we create listener this way
document.addEventListener(
    clientUserAuthenticatedEvent.type,
    async () => {
        const user = auth.currentUser;

        // enable user-id tracking in GA
        if (global.gtag)
            try {
                global.gtag("set", { user_id: user.uid });
                global.gtag("event", "page_view");
            } catch (error) {
                console.log(error);
                console.log("Unable to capture user UID.");
            }
    },
    { once: true }
);
