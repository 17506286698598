/**
 * Collects "selected" rounds IDs from the territory across all networks, ie
 *  {
 *      POSTIES: [...],
 *      RURAL: [...],
        POSTIES: [...], 
        POBOXES: [...]
 *  }
 * 
 * @param {Object} rounds Territory rounds object
 * @returns {Object} An object of collected rounds IDs per Network
 */
export const getSelectedRounds = (rounds) => {
    const data = {
        URBAN: [],
        RURAL: [],
        POSTIES: [],
        POBOXES: [],
        COUNTERS: [],
    };

    Object.keys(rounds)
        .filter((roundId) => rounds[roundId].selected)
        .map((roundId) => data[rounds[roundId].layer].push(parseInt(roundId)));
    return data;
};
