import { GMAP_ROUNDS_LAYERS } from "./dataLayers";
const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;
import { VOLUME_SLICES } from "./volumeSlices";
const {
    INCLUSIVE,
    EXCLUSIVE,
    RESIDENTIAL,
    BUSINESS,
    FARMER,
    DAIRY,
    NEWSPAPER_EXCLUSIVE,
    NEWSPAPER_INCLUSIVE,
    NEWSPAPER_ALL,
} = VOLUME_SLICES;

export const TRANSLATED_NETWORKS = {
    URBAN: "Urban",
    POSTIES: "Postie",
    RURAL: "Rural",
    POBOXES: "PO Box and Counters",
    Urban: URBAN,
    Postie: POSTIES,
    Rural: RURAL,
    "PO Box and Counters": POBOXES,
};

export const TRANSLATED_CHANNELS = {
    [`${URBAN},${EXCLUSIVE}`]: "urban_excl",
    [`${URBAN},${INCLUSIVE}`]: "urban_incl",
    [`${URBAN},${NEWSPAPER_INCLUSIVE}`]: "newspaper_incl",
    [`${URBAN},${NEWSPAPER_EXCLUSIVE}`]: "newspaper_excl",
    [`${RURAL},${NEWSPAPER_ALL}`]: "newspaper_rural_all",
    [`${RURAL},${DAIRY}`]: "rural_dairy",
    [`${RURAL},${FARMER}`]: "rural_farmer",
    [`${RURAL},${RESIDENTIAL}`]: "rural_residential",
    [`${POBOXES},${BUSINESS}`]: "boxes_business",
    [`${POBOXES},${FARMER}`]: "boxes_farmer",
    [`${POBOXES},${RESIDENTIAL}`]: "boxes_residential",
    [`${COUNTERS},${FARMER}`]: "counters_farmer",
    [`${COUNTERS},${RESIDENTIAL}`]: "counters_residential",
    [`${POSTIES},${EXCLUSIVE}`]: "postie_excl",
    [`${POSTIES},${INCLUSIVE}`]: "postie_incl",
    [`${POSTIES},${BUSINESS}`]: "postie_business",
};

/**
 * Helper to translate FE notation into API (SQL columns)
 * @param {Object} volumeSelections Territory state volume selection matrix
 */
export const translateNetworkChannels = (volumeSelections) => {
    const networkChannels = {};
    Object.keys(volumeSelections).map((network) => {
        const channels = volumeSelections[network].map(
            (channel) => TRANSLATED_CHANNELS[`${network},${channel}`]
        );

        // Push extra channels for Counters
        if (POBOXES === network) {
            if (volumeSelections[network].includes(RESIDENTIAL)) {
                channels.push(TRANSLATED_CHANNELS[`${network},RESIDENTIAL`]);
                channels.push(TRANSLATED_CHANNELS[`${COUNTERS},RESIDENTIAL`]);
            }
            if (volumeSelections[network].includes(FARMER)) {
                channels.push(TRANSLATED_CHANNELS[`${network},FARMER`]);
                channels.push(TRANSLATED_CHANNELS[`${COUNTERS},FARMER`]);
            }
        }

        networkChannels[TRANSLATED_NETWORKS[network]] = channels;
    });

    return networkChannels;
};
