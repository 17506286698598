import { clientUnsafeWarningEvent } from "../../components/toast";
import { OCI_GATEWAY_URL } from "../../config/local";
import store from "../../store";
import { formatDateForApi } from "../dateHelper";
import { numberFormat } from "../numberHelper";
import { bracketKey } from "../weight";

export const ADMIN_FEE_DEFAULT = 0;

export const adminFeeQuote = async () => {
    let response, quote;

    const customerCmsCode = store.getState().quoteMeta.customerCmsCode;
    const campaignStartDate = store.getState().quoteMeta.campaignStartDate;
    const campaignStartDateFormatted = formatDateForApi(campaignStartDate);

    const { customWeightBracket, weight } = store.getState().quoteDetails;
    const { pageCount } = store.getState().printingSpecs;

    const apiUrl = new URL(`${OCI_GATEWAY_URL}/rates/admin`);

    // respect custom weight bracket choice to override weight/bracket calculations
    const weightBracket = customWeightBracket ?? bracketKey(weight * pageCount);

    try {
        customerCmsCode
            ? apiUrl.searchParams.append("cms_acct_code", customerCmsCode)
            : null;
        campaignStartDate
            ? apiUrl.searchParams.append(
                  "start_date",
                  campaignStartDateFormatted
              )
            : null;
        weightBracket
            ? apiUrl.searchParams.append("weight_range", weightBracket)
            : null;

        response = await fetch(apiUrl);

        if (!response.ok) {
            global.document.dispatchEvent(
                clientUnsafeWarningEvent({
                    message: `Failed to load the administration fee quote${
                        customerCmsCode
                            ? " with customer account number " + customerCmsCode
                            : ""
                    }. ${formatAdminFeeMessage()}`,
                })
            );
            return ADMIN_FEE_DEFAULT;
        }
    } catch (error) {
        global.document.dispatchEvent(
            clientUnsafeWarningEvent({
                message: `Failed to load the administration fee quote${
                    customerCmsCode
                        ? " with customer account number " + customerCmsCode
                        : ""
                }. ${formatAdminFeeMessage()}`,
            })
        );
        console.warn("Network issues!", error);
        return ADMIN_FEE_DEFAULT;
    }

    try {
        quote = await response.json();

        if (quote.rate === null) {
            return ADMIN_FEE_DEFAULT;
        }
        if (quote.rate) {
            return parseFloat(quote.rate);
        }

        global.document.dispatchEvent(
            clientUnsafeWarningEvent({
                message: `Unexpected administration fee quote value. ${formatAdminFeeMessage()}`,
            })
        );
        console.warn(`Quote value: [${JSON.stringify(quote)}]`);
        return ADMIN_FEE_DEFAULT;
    } catch (error) {
        global.document.dispatchEvent(
            clientUnsafeWarningEvent({
                message: `Unexpected administration fee quote structure. ${formatAdminFeeMessage()}`,
            })
        );
        console.warn(`Unexpected quote structure: [${quote}]`);
        return ADMIN_FEE_DEFAULT;
    }
};

const formatAdminFeeMessage = () => {
    return `Using value of <span class="text-peppermint font-bold">$${numberFormat(
        ADMIN_FEE_DEFAULT
    )}</span>.`;
};
