import {
    ROUNDS_LOAD_DATA,
    ROUNDS_FLUSH_DATA,
    EMPTY_ROUNDS_UPDATE,
} from "./actionTypes";

export const roundsLoadData = (payload) => {
    return {
        type: ROUNDS_LOAD_DATA,
        payload,
    };
};

export const roundsFlushData = () => {
    return {
        type: ROUNDS_FLUSH_DATA,
    };
};

export const emptyWalksUpdate = (payload) => {
    return {
        type: EMPTY_ROUNDS_UPDATE,
        payload,
    };
};
