import { plainWarning } from "../notifications/apiWrapper";

export const apiErrorEvent = (detail) =>
    new CustomEvent("client:api:error", {
        detail,
        bubbles: true,
        composed: true,
    });

document.addEventListener(apiErrorEvent(null).type, (event) => {
    plainWarning(event.detail.errorMsg, "Please contact ICT team");
});
