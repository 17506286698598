import store from "../../store";
import { GMAP_ROUNDS_LAYERS } from "../../const/dataLayers";
const { URBAN } = GMAP_ROUNDS_LAYERS;

export const isEmptyWalk = (walk_id) => {
    return store.getState().emptyWalks.includes(walk_id);
};

/**
 * Calculates the volume of empty walks based on the provided bulk walks and slices.
 * This function is meant to be used only on the URBAN/REACH layer
 *
 * @param {Array<number>} bulk_walks - The array of bulk walks.
 * @param {Array<string>} slices - The array of volume slices (channels).
 * @returns {number} The total mailers volume of empty walks.
 */
export const emptyWalksVolume = (bulk_walks, slices) => {
    // slices or walks can not be empty, if it is, return 0
    if (bulk_walks.length === 0 || slices.length === 0) {
        return 0;
    }

    const emptyWalks = store.getState().emptyWalks;
    const walks = bulk_walks.filter((walk_id) =>
        emptyWalks.includes(Number(walk_id))
    );

    // now use the store state with the roundsData hashmap to get the slice volume
    // of each empty walk and sum them up
    return walks.reduce((acc, walk_id) => {
        return slices.reduce((acc, slice) => {
            return (
                acc +
                store.getState().roundsData[walk_id].properties.volumes[URBAN][
                    slice
                ]
            );
        }, acc);
    }, 0);
};

/**
 * Calculates the ratio of selected matching objects to all selected objects.
 * @param {Object} territoryRounds - The hashmap of territory rounds object with network and selection state
 * @param {Array} emptyWalks - The ranked array of empty walks
 * @param {Array<string>} slices - The array of volume slices (channels).
 * @returns {number} - The calculated ratio
 */
const calculateEmptiesRatio = (territoryRounds, slices) => {
    let selectedTotal = 0;
    let selectedMatching = 0;
    const roundsData = store.getState().roundsData;
    const emptyWalks = store.getState().emptyWalks;

    for (let key in territoryRounds) {
        if (territoryRounds[Number(key)].selected) {
            selectedTotal += slices.reduce((acc, slice) => {
                return (
                    acc +
                    roundsData[Number(key)].properties.volumes[URBAN][slice]
                );
            }, 0);
            if (emptyWalks.includes(Number(key))) {
                selectedMatching += slices.reduce((acc, slice) => {
                    return (
                        acc +
                        roundsData[Number(key)].properties.volumes[URBAN][slice]
                    );
                }, 0);
            }
        }
    }

    return selectedTotal === 0 ? 0 : selectedMatching / selectedTotal;
};

/**
 * Adjusts the selection of objects to achieve a target ratio.
 * @param {Object} territoryRounds - The hashmap of territory rounds object with network and selection state
 * @param {Array<string>} slices - The array of volume slices (channels).
 * @param {number} targetRatio - The target ratio to achieve (as a decimal, e.g., 0.7 for 70%).
 * @returns {Object} - The updated territory rounds hashmap that could be used in action.
 */
export const adjustSelectionToRatio = (
    territoryRounds,
    slices,
    targetRatio
) => {
    const emptyWalks = store.getState().emptyWalks;

    // Initial ratio
    let currentRatio = calculateEmptiesRatio(territoryRounds, slices);

    // If the current ratio is already lower than or equal to the target, no action needed
    if (currentRatio <= targetRatio) {
        return territoryRounds;
    }

    // Create a list of keys that are both in the hashmap and sortedArray, and currently selected
    let matchingKeys = emptyWalks.filter(
        (key) => territoryRounds[key] && territoryRounds[key].selected
    );

    // Deselect objects from the bottom of the matching list until we reach the target ratio
    for (let i = matchingKeys.length - 1; i >= 0; i--) {
        let key = matchingKeys[i];
        territoryRounds[key].selected = false;

        currentRatio = calculateEmptiesRatio(territoryRounds, slices);

        if (currentRatio <= targetRatio) {
            break;
        }
    }

    return territoryRounds;
};

export const getRoundOnSelectedNetwork = (territory) => {
    return Object.keys(territory.rounds).filter((walk_id) =>
        territory.networkSelections.includes(territory.rounds[walk_id].layer)
    );
};
