import { LitElement, html } from "lit-element";
import { until } from "lit-html/directives/until";
import clonedeep from "lodash-es/cloneDeep";

import "../wordsCloud";

import store from "../../store";
import { numberFormat } from "../../services/numberHelper";
import {
    setupAllCustomerChanges,
    territoriesSubtotal,
} from "../../services/volumes";
import { calcNetworkFee, freight, volume } from "../../services/quoteCalcs";
import { nothing } from "lit-html";
import { getQuotableTerritories } from "../../core/selectors/territorySelector";
import { getTargetedTerritories } from "../../core/selectors/targetingSelector";

export class CostChangesSummary extends LitElement {
    constructor() {
        super();
        this.settings = store.getState().settings;
    }

    progressBar() {
        return html`
            <div class="col-span-12">
                <rm-progress-spinner></rm-progress-spinner>
            </div>
        `;
    }

    renderDiff(prevVal, curVal) {
        const diff = curVal - prevVal;
        const diffPercent = Math.abs(((diff / prevVal) * 100).toFixed(2));

        let sign = "+";
        let diffSymbol = "arrow_upward";
        let bgColor = "bg-success";

        if (diff < 0) {
            sign = "-";
            diffSymbol = "arrow_downward";
            bgColor = "bg-danger";
        } else if (diff === 0) {
            bgColor = "bg-dark-grey";
        }

        return html`
            <div class="col-span-3 p-1 ${bgColor} text-white text-center">
                ${diff === 0
                    ? html`No change`
                    : html`
                          <span class="material-icons-outlined align-middle"
                              >${diffSymbol}</span
                          >
                          $${numberFormat(Math.abs(diff))} (
                          ${sign}${diffPercent}% )
                      `}
            </div>
            <div class="col-span-4">
                ${diff === 0 || this.settings.hideQuoteSummary
                    ? nothing
                    : html`
                          <span>$${numberFormat(prevVal)}</span>
                          <span class="material-icons-outlined align-top"
                              >trending_flat</span
                          >
                          <span>$${numberFormat(curVal)}</span>
                      `}
            </div>
        `;
    }

    async renderVolumeDiff(prevTerritories, curTerritories) {
        const prevVolume = (await volume(territoriesSubtotal(prevTerritories)))
            .total;
        const curVolume = (await volume(territoriesSubtotal(curTerritories)))
            .total;

        return html`
            <div class="col-span-3">Volume cost</div>
            ${this.renderDiff(prevVolume, curVolume)}
        `;
    }

    async renderFreightDiff(prevTerritories, curTerritories) {
        const prevFreight = (
            await freight(territoriesSubtotal(prevTerritories))
        ).total;
        const curFreight = (await freight(territoriesSubtotal(curTerritories)))
            .total;

        return html`
            <div class="col-span-3">Freight cost</div>
            ${this.renderDiff(prevFreight, curFreight)}
        `;
    }

    async renderVfrDiff(prevTerritories, curTerritories) {
        const prevVfr = (await freight(territoriesSubtotal(prevTerritories)))
            .vfr;
        const curVfr = (await freight(territoriesSubtotal(curTerritories))).vfr;

        return html`
            <div class="col-span-3">Freight surcharge</div>
            ${this.renderDiff(prevVfr, curVfr)}
        `;
    }

    async renderNetworkDiff(curTerritories) {
        // Original quote network fee
        const quote = store.getState().quoteDetails;
        const prevNetworkFee = quote.networkManagementCost;
        const prevTargetingFee = quote.targetingCost || 0;

        // Proposed changes
        const subtotalMatrix = territoriesSubtotal(
            getQuotableTerritories(curTerritories)
        );
        const targetedMarix = territoriesSubtotal(
            getTargetedTerritories(curTerritories)
        );
        const { networkManagementCost, targetingCost } = await calcNetworkFee(
            subtotalMatrix,
            targetedMarix
        );

        return html`
            <div class="col-span-3">Network fee</div>
            ${this.renderDiff(prevNetworkFee, networkManagementCost)}
            <div class="col-span-3">Targeting fee</div>
            ${this.renderDiff(prevTargetingFee, targetingCost)}
        `;
    }

    renderCostChangesSummary() {
        const prevTerritories = store.getState().territories;
        const curTerritories = setupAllCustomerChanges(
            clonedeep(prevTerritories),
            store.getState().roundsData,
            true
        );

        return html`
            <div class="items-center px-4 py-1 mb-2 grid grid-cols-12 gap-4">
                ${until(
                    this.renderVolumeDiff(prevTerritories, curTerritories),
                    this.progressBar()
                )}
                ${until(
                    this.renderFreightDiff(prevTerritories, curTerritories),
                    this.progressBar()
                )}
                ${until(
                    this.renderVfrDiff(prevTerritories, curTerritories),
                    this.progressBar()
                )}
                ${until(
                    this.renderNetworkDiff(curTerritories),
                    this.progressBar()
                )}
            </div>
        `;
    }

    render() {
        if (this.settings.hideQuoteSummary) {
            return nothing;
        }
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="pt-4 px-4 block">
                <h3 class="pb-1 text-xl font-medium text-dark-grey">
                    Cost Changes
                </h3>
                ${this.renderCostChangesSummary()}
            </div>
        `;
    }
}

global.customElements.define("rm-cost-changes-summary", CostChangesSummary);
