import clonedeep from "lodash-es/cloneDeep";

import { GMAP_RANGE_MODE } from "../../const/rangeMode";
import { GMAP_RANGE_VALUE_UPDATE } from "../actions/actionTypes";

const initialState = {
    [GMAP_RANGE_MODE.TIME]: 10, // minutes
    [GMAP_RANGE_MODE.DISTANCE]: 5, // kilometers
};

export const mapRangeValues = (state = initialState, action) => {
    switch (action.type) {
        case GMAP_RANGE_VALUE_UPDATE: {
            const next = clonedeep(state);
            next[action.payload.mode] = action.payload.value;
            return next;
        }
        default:
            return state;
    }
};
