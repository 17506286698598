import { LitElement, html } from "lit-element";

import store from "../../store";

import { clientDialogCloseEvent } from "../dialog";
import { updateQuotePdf } from "../../services/httpApis/saveQuote";
import { isEmailsValid } from "../../services/validations/email";
import { clientInfoEvent, clientWarningEvent } from "../toast";
import { QUOTE_PDF_TRIGGER_URL } from "../../config/local";

export class QuotePdf extends LitElement {
    constructor() {
        super();
        this.reset();
    }

    static get properties() {
        return {
            sendToMe: { type: Boolean },
            quotePdf: { type: Object },
            error: { type: Object },
        };
    }

    toggleSendToMe(value) {
        this.sendToMe = value;
        const quoteMeta = store.getState().quoteMeta;
        this.quotePdf.email = [quoteMeta.createdBy];
        if (this.sendToMe) {
            this.quotePdf.cc = [];
        }
    }

    reset() {
        const quoteMeta = store.getState().quoteMeta;
        this.sendToMe = false;
        this.quotePdf = {
            notes: "",
            email: [quoteMeta.createdBy],
            cc: [quoteMeta.updatedBy],
        };
        this.error = { email: "", cc: "" };
    }

    get emailValue() {
        return this.quotePdf.email.join(",");
    }

    get ccValue() {
        return this.quotePdf.cc.join(",");
    }

    onEmailInput(mailString) {
        this.updateError({ email: "" });
        this.quotePdf.email = mailString
            .split(",")
            .map((email) => email.trim());
    }

    onCcInput(mailString) {
        this.updateError({ cc: "" });

        this.quotePdf.cc = mailString.split(",").map((email) => email.trim());
    }

    hasError() {
        if (isEmailsValid(this.quotePdf.email)) {
            this.updateError({ email: "Please enter valid emails." });
        }

        if (0 === this.quotePdf.cc.length) {
            this.updateError({ cc: "" });
        } else {
            if (isEmailsValid(this.quotePdf.cc)) {
                this.updateError({ cc: "Please enter valid cc emails." });
            }
        }
        return this.error.email || this.error.cc;
    }

    updateError(prop) {
        this.error = { ...this.error, ...prop };
    }

    async onConfirm() {
        const quoteMeta = store.getState().quoteMeta;

        if (this.hasError()) return;

        if (await updateQuotePdf(this.quotePdf)) {
            document.dispatchEvent(clientDialogCloseEvent);
        }

        // trigger remote webhook to initiate the process
        fetch(`${QUOTE_PDF_TRIGGER_URL}/?quoteId=${quoteMeta.id}`).then(
            (response) => {
                if (response.ok) {
                    global.document.dispatchEvent(
                        clientInfoEvent({
                            message:
                                "Succesfully requested quote PDF, arriving to mailbox soon!",
                        })
                    );
                } else {
                    global.document.dispatchEvent(
                        clientWarningEvent({
                            message:
                                "Couldn't request quote PDF, please try it again!",
                        })
                    );
                }
            }
        );
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-col h-full">
                <section
                    class="flex flex-wrap flex-grow-0 justify-between py-2 px-4 -mx-4 border-b border-dark-grey"
                >
                    <h3 class="text-xl font-medium">Send Quote PDF</h3>
                    <button
                        @click=${() =>
                            document.dispatchEvent(clientDialogCloseEvent)}
                        title="Close [Esc]"
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">
                            Close
                            <span class="text-xs lowercase">[Esc]</span>
                        </span>
                    </button>
                </section>
                <section class="flex flex-1 flex-wrap content-start pt-2">
                    <div class="w-1/2">
                        <div class="flex items-center pt-2">
                            <input
                                type="checkbox"
                                class="checkbox align-middle"
                                .checked=${this.sendToMe}
                                @change=${(e) =>
                                    this.toggleSendToMe(e.target.checked)}
                            />
                            <label class="block text-right pl-2"
                                >Send to me only</label
                            >
                        </div>
                        <div class="flex pt-2">
                            <label class="block text-right">TO email:</label>
                            <div class="flex-1">
                                <input
                                    type="text"
                                    class="w-3/4 block border rounded-sm leading-tight p-1 ml-3"
                                    .value="${this.emailValue}"
                                    ?disabled=${this.sendToMe}
                                    @input=${(e) =>
                                        this.onEmailInput(e.target.value)}
                                />
                                <label
                                    class="ml-3 whitespace-no-wrap ${!this.error
                                        .email
                                        ? "hidden"
                                        : ""}"
                                    >${this.error.email}</label
                                >
                            </div>
                        </div>

                        <div class="flex pt-2">
                            <label class="block text-right">CC email:</label>
                            <div class="flex-1">
                                <input
                                    type="text"
                                    class="w-3/4 block border rounded-sm leading-tight p-1 ml-3"
                                    .value=${this.ccValue}
                                    ?disabled=${this.sendToMe}
                                    @input=${(e) =>
                                        this.onCcInput(e.target.value)}
                                />
                                <label
                                    class="ml-3 whitespace-no-wrap ${!this.error
                                        .cc
                                        ? "hidden"
                                        : ""}"
                                    >${this.error.cc}</label
                                >
                            </div>
                        </div>
                    </div>

                    <div class="w-1/2 flex flex-col">
                        <label class="block"
                            >Notes [This node will be added to PDF]</label
                        >
                        <div class="flex flex-grow">
                            <textarea
                                rows="4"
                                class="w-full border rounded-sm mt-3 p-1"
                                .value=${this.quotePdf.notes}
                                @input=${(e) =>
                                    (this.quotePdf.notes = e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                </section>
                <section
                    class="flex -mx-4 pt-4 px-4 border-t border-dark-grey justify-between"
                >
                    <button
                        title="Reset"
                        @click=${() => this.reset()}
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">Reset</span>
                    </button>
                    <button
                        title="Confirm"
                        @click=${() => this.onConfirm()}
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">Confirm</span>
                    </button>
                </section>
            </div>
        `;
    }
}

global.customElements.define("rm-quote-pdf", QuotePdf);
