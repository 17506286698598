import { LitElement, html } from "lit-element";
import { repeat } from "lit-html/directives/repeat";
import clonedeep from "lodash-es/cloneDeep";

import "../wordsCloud";

import store from "../../store";
import {
    setupTerritoryCustomerChanges,
    territorySubtotal,
} from "../../services/volumes";
import { nothing } from "lit-html";

export class TerritoryChangesSummary extends LitElement {
    constructor() {
        super();
    }

    renderTerritoryDiff(oldVal, newVal) {
        const diff = newVal - oldVal;
        const diffPercent = Math.abs(((diff / oldVal) * 100).toFixed(2));

        let sign = "+";
        let diffSymbol = "arrow_upward";
        let bgColor = "bg-success";

        if (diff < 0) {
            sign = "-";
            diffSymbol = "arrow_downward";
            bgColor = "bg-danger";
        } else if (diff === 0) {
            bgColor = "bg-dark-grey";
        }

        return html`
            <div class="col-span-3 p-1 ${bgColor} text-white text-center">
                ${diff === 0
                    ? html`No change`
                    : html`
                          <span class="material-icons-outlined align-middle"
                              >${diffSymbol}</span
                          >
                          ${Math.abs(diff)} ( ${sign}${diffPercent}% )
                      `}
            </div>
            <div class="col-span-4">
                ${diff === 0
                    ? nothing
                    : html`
                          <span>${oldVal} mailers</span>
                          <span class="material-icons-outlined align-middle"
                              >trending_flat</span
                          >
                          <span>${newVal} mailers</span>
                      `}
            </div>
        `;
    }

    renderTerritorySummary(territory) {
        const curTerritory = setupTerritoryCustomerChanges(
            clonedeep(territory),
            store.getState().roundsData,
            true
        );
        const prevTerritorySubtotal = territorySubtotal(territory);
        const curTerritorySubtotal = territorySubtotal(curTerritory);

        return html`
            <div
                class="flex items-center px-4 py-1 mb-2 grid grid-cols-12 gap-4"
            >
                <div class="col-span-3">
                    <div
                        class="h-4 w-4 float-left mt-1 mr-1"
                        style="background-color:${territory.colour}"
                    ></div>
                    <span
                        >${(territory.location.formatted || {}).main_text ||
                        ""}</span
                    >
                </div>
                ${this.renderTerritoryDiff(
                    prevTerritorySubtotal,
                    curTerritorySubtotal
                )}
            </div>
        `;
    }

    render() {
        const territories = store.getState().territories;

        return html`
            <style>
                @import "main.css";
            </style>
            <div class="pt-4 px-4 block">
                <h3 class="pb-1 text-xl font-medium text-dark-grey">
                    Territories Changes
                </h3>
                ${repeat(territories, (territory) =>
                    this.renderTerritorySummary(territory)
                )}
            </div>
        `;
    }
}

global.customElements.define(
    "rm-territory-changes-summary",
    TerritoryChangesSummary
);
