import clonedeep from "lodash-es/cloneDeep";

import { GMAP_RANGE_MODE } from "../../const/rangeMode";
import { GMAP_RANGE_MODE_TOGGLE } from "../actions/actionTypes";

const initialState = {
    [GMAP_RANGE_MODE.NONE]: true,
    [GMAP_RANGE_MODE.TIME]: false,
    [GMAP_RANGE_MODE.DISTANCE]: false,
    [GMAP_RANGE_MODE.INFO]: false,
};

export const mapRangeMode = (state = initialState, action) => {
    switch (action.type) {
        case GMAP_RANGE_MODE_TOGGLE: {
            const next = clonedeep(state);
            Object.keys(next).forEach((key) => (next[key] = false));
            return { ...next, ...action.payload };
        }
        default:
            return state;
    }
};
