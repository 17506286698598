import store from "../store";
import { getActiveTerritory } from "../core/selectors/territorySelector";
import { roundsNetworkToggle } from "../core/actions/actions";

import { GMAP_BUILDING_MODE } from "../const/buidlingMode";
const { AREA, RANGE } = GMAP_BUILDING_MODE;

import { GMAP_DRAWING_MODE } from "../const/drawingMode";

import { GMAP_RANGE_MODE } from "../const/rangeMode";

import { GMAP_ROUNDS_LAYERS } from "../const/dataLayers";
const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;

/**
 * Activate respective network layers for active territory
 */
export const visualiseNetworkSelections = (selectedTerritory = null) => {
    const territory = selectedTerritory || getActiveTerritory();
    // load territory network selections
    const selections = {
        [URBAN]: false,
        [POSTIES]: false,
        [RURAL]: false,
        [POBOXES]: false,
    };
    territory.networkSelections.forEach((item) => (selections[item] = true));
    delete selections[COUNTERS];
    store.dispatch(roundsNetworkToggle(selections));
};

export const isMapMode = (mode) => {
    const buildingMode = store.getState().mapBuildingMode;
    const drawingMode = store.getState().mapDrawingMode;
    const rangeMode = store.getState().mapRangeMode;

    return (
        (buildingMode[AREA] && drawingMode[GMAP_DRAWING_MODE[mode]]) ||
        (buildingMode[RANGE] && rangeMode[GMAP_RANGE_MODE[mode]])
    );
};

export const isDrawingMode = (mode) => {
    const buildingMode = store.getState().mapBuildingMode;
    const drawingMode = store.getState().mapDrawingMode;

    return buildingMode[AREA] && drawingMode[mode];
};

export const IsRangeMode = (mode) => {
    const buildingMode = store.getState().mapBuildingMode;
    const rangeMode = store.getState().mapRangeMode;

    return buildingMode[RANGE] && rangeMode[mode];
};
