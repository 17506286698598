import { LitElement, html } from "lit-element";

import { quoteMetaUpdate } from "../../core/actions/actions";
import store from "../../store";

import { cmsRule, customerRules } from "../../services/validations/customer";

import debounce from "../../services/debounce";
import {
    getCustomerContact,
    searchCustomer,
} from "../../services/httpApis/customer";
import { save } from "../../services/httpApis/saveQuote";
import { formatPhoneNumber } from "../../services/numberHelper";
import {
    isValueValid,
    validateErrors,
} from "../../services/validations/validator";
import { clientDialogCloseEvent } from "../dialog";
import { clientInfoEvent, clientWarningEvent } from "../toast";
import { live } from "lit-html/directives/live";

class CustomerDetails extends LitElement {
    constructor() {
        super();
        this.cmsCode = store.getState().quoteMeta.customerCmsCode;
        this.customer = store.getState().quoteMeta.customer;
        this.error = {};
        this.customerList = [];
        this.contacts = [];
        this.name = "";
        this.contact = "";
        this.phone = "";
        this.email = "";
    }

    static get properties() {
        return {
            cmsCode: { type: String },
            customer: { type: Object },
            confirmed: { type: Boolean },
            error: { type: Object },
            fieldChanged: { type: Boolean },
            customerList: { type: Array },
            contacts: { type: Array },
            name: { type: String },
            contact: { type: String },
            phone: { type: String },
            email: { type: String },
        };
    }

    firstUpdated() {
        this.shadowRoot.querySelector("input").focus();

        if (this.customer) {
            this.cmsCode = this.customer.cmsAcctCode;
            this.name = this.customer.name || "";
            this.contact = this.customer.contact || "";
            this.phone = this.customer.phone || "";
            this.email = this.customer.email || "";
            this.fetchCustomerContacts();
        }
    }

    async onConfirm() {
        this.customer.name = this.name;
        this.customer.contact = this.contact;
        this.customer.phone = this.phone;
        this.customer.email = this.email;

        this.error = validateErrors(customerRules, this.customer);
        if (!this.isCustomerValid) return;
        store.dispatch(
            quoteMetaUpdate({
                customerCmsCode: this.cmsCode,
                customer: this.customer,
            })
        );
        document.dispatchEvent(clientDialogCloseEvent);

        // attempt to save the quote
        const meta = store.getState().quoteMeta;
        if (meta.id) {
            // it doesn't resolve until it saves or fails for other reason than network connection
            const res = await save();
            if (res) {
                global.document.dispatchEvent(
                    clientInfoEvent({ message: "The quote has been saved!" })
                );
            } else {
                global.document.dispatchEvent(
                    clientWarningEvent({
                        message:
                            "Couldn't save the quote, ensure to try saving it again!",
                    })
                );
            }
        } else {
            global.document.dispatchEvent(
                clientInfoEvent({
                    message:
                        "We captured the details, don't forget to save the quote!",
                })
            );
        }
    }

    onReset() {
        this.cmsCode = null;
        this.customer = null;
        this.fieldChanged = false;
        this.customerList = [];
        this.contacts = [];
        this.name = "";
        this.contact = "";
        this.phone = "";
        this.email = "";
    }

    resetContact() {
        this.contacts = [];
        this.name = "";
        this.contact = "";
        this.phone = "";
        this.email = "";

        this.fieldChanged = true;
    }

    get confirmDisabled() {
        return !this.fieldChanged || !this.isCodeValid;
    }

    get postcode() {
        let postcode = (this.customer || {}).postcode || "";
        return postcode ? String(postcode).padStart(4, "0") : postcode;
    }

    get isCodeValid() {
        return isValueValid(cmsRule, this.cmsCode);
    }

    async fetchCustomerContacts() {
        if (!this.cmsCode) return;

        const contacts = await getCustomerContact(this.cmsCode);
        if (!contacts) return;

        this.contacts = contacts;
    }

    onCmsInput(searchValue) {
        this.resetContact();
        this.cmsCode = searchValue;
        debounce(async () => {
            this.customerList = (await searchCustomer(searchValue)) || [];
            this.updateCustomer();
        }, 500)();
    }

    updateCustomer() {
        const customer = this.customerList.find(
            (c) => c.cmsAcctCode === this.cmsCode
        );

        if (customer) {
            this.customer = customer;
            this.name = customer.name;

            this.fieldChanged = true;
        }
    }

    updateContact(contactName) {
        const contact = this.contacts.find((c) => c.name === contactName);

        if (contact) {
            this.contact = contact.name;
            this.email = contact.email || "";
            this.phone = formatPhoneNumber(
                contact.phone || contact.mobilePhone
            );
        } else {
            this.contact = contactName;
        }
        this.fieldChanged = true;
    }

    updateError(prop) {
        this.error = { ...this.error, ...prop };
    }

    get isCustomerValid() {
        return !Object.values(this.error).some((el) => "" !== el);
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-col h-full">
                <section
                    class="flex flex-wrap flex-grow-0 justify-between py-2 px-4 -mx-4 border-b border-dark-grey"
                >
                    <h3 class="text-xl font-medium">Customer details</h3>
                    <button
                        @click=${() =>
                            document.dispatchEvent(clientDialogCloseEvent)}
                        title="Close [Esc]"
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">
                            Close
                            <span class="text-xs lowercase">[Esc]</span>
                        </span>
                    </button>
                </section>
                <section class="flex flex-1 flex-wrap content-start pt-2">
                    <div class="flex w-full">
                        <div class="contents content-start">
                            <div class="w-1/3 py-2 pr-2">
                                <label class="block text-right"
                                    >Customer Account Number</label
                                >
                            </div>
                            <div class="relative w-2/3 py-2 flex items-center">
                                <div class="w-2/3">
                                    <input
                                        list="customer-list"
                                        class="relative
                                    block border rounded-sm leading-tight w-full p-1"
                                        type="text"
                                        placeholder="Enter customer name or account number to search"
                                        .value=${this.cmsCode}
                                        @input=${(e) =>
                                            this.onCmsInput(e.target.value)}
                                        @change=${() =>
                                            this.fetchCustomerContacts()}
                                    />
                                    <datalist id="customer-list">
                                        ${this.customerList.map(
                                            (c) =>
                                                html`<option
                                                    value="${c.cmsAcctCode}"
                                                >
                                                    ${c.name}
                                                </option>`
                                        )}
                                    </datalist>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap w-full content-start pb-4">
                        <div class="w-1/3 py-2 pr-2">
                            <label class="block text-right">Name:</label>
                        </div>

                        <div class="w-2/3 py-2 font-medium">
                            <input
                                class="block border rounded-sm leading-tight w-2/3 p-1"
                                disabled
                                readonly
                                .value=${this.name}
                            />
                        </div>

                        <div class="w-1/3 py-2 pr-2">
                            <label class="block text-right">Contact:</label>
                        </div>
                        <div class="relative w-2/3 font-medium">
                            <div class="w-2/3 py-2">
                                <input
                                    list="contact-list"
                                    class="block border rounded-sm leading-tight w-full p-1"
                                    placeholder="Select or enter contact name"
                                    ?disabled=${!this.isCodeValid}
                                    .value=${this.contact}
                                    @input=${(e) =>
                                        this.updateContact(e.target.value)}
                                />
                                <datalist id="contact-list">
                                    ${this.contacts.map(
                                        (c) =>
                                            html`<option value="${c.name}">
                                                ${c.name}
                                            </option>`
                                    )}
                                </datalist>
                            </div>
                        </div>

                        <div class="flex w-full items-baseline">
                            <div class="w-1/3 pr-2">
                                <label class="block text-right">Phone:</label>
                            </div>
                            <div class="w-2/3 py-2 font-medium">
                                <input
                                    type="tel"
                                    class="block border rounded-sm leading-tight w-2/3 p-1"
                                    ?disabled=${!this.isCodeValid}
                                    .value=${live(this.phone)}
                                    @input=${(e) => {
                                        this.phone = e.target.value;
                                        this.fieldChanged = true;
                                    }}
                                />
                                <label
                                    class="whitespace-no-wrap text-danger ${!this
                                        .error.phone
                                        ? "hidden"
                                        : ""}"
                                    >${this.error.phone}</label
                                >
                            </div>
                        </div>

                        <div class="flex w-full items-baseline">
                            <div class="w-1/3 pr-2">
                                <label class="block text-right">Email:</label>
                            </div>
                            <div class="w-2/3 py-2 font-medium">
                                <input
                                    type="email"
                                    class="block border rounded-sm leading-tight w-2/3 p-1"
                                    ?disabled=${!this.isCodeValid}
                                    .value=${this.email}
                                    @input=${(e) => {
                                        this.email = e.target.value;
                                        this.fieldChanged = true;
                                    }}
                                />

                                <label
                                    class="whitespace-no-wrap text-danger ${!this
                                        .error.email
                                        ? "hidden"
                                        : ""}"
                                    >${this.error.email}</label
                                >
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    class="flex -mx-4 pt-4 px-4 border-t border-dark-grey justify-between"
                >
                    <button
                        title="Reset"
                        @click=${() => this.onReset()}
                        class="px-4 py-1 rounded border border-peppermint"
                    >
                        <span class="text uppercase">Reset</span>
                    </button>
                    <button
                        title="Confirm"
                        ?disabled=${this.confirmDisabled}
                        @click=${() => this.onConfirm()}
                        class="px-4 py-1 rounded border border-peppermint ${this
                            .confirmDisabled
                            ? "cursor-not-allowed"
                            : "hover:bg-peppermint-50"}"
                    >
                        <span class="text uppercase">Confirm</span>
                    </button>
                </section>
            </div>
        `;
    }
}

global.customElements.define("rm-customer-details", CustomerDetails);
