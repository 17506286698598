import store from "../store";
import { URL_MODE_PARAMS } from "../const/urlParams";

const getQuoteId = (urlParams) => {
    const keys = Array.from(urlParams.keys());
    try {
        return keys[0].trim().split(":")[0].trim();
    } catch (error) {
        return null;
    }
};

const getTerritoryId = (urlParams) => {
    const keys = Array.from(urlParams.keys());
    try {
        return keys[0].trim().split(":")[1].trim();
    } catch (error) {
        return null;
    }
};

const getModes = (urlParams) => {
    try {
        const modes = urlParams.get("mode");
        if (!modes) {
            return [];
        }
        return modes.split(",").map((mode) => mode.trim());
    } catch (error) {
        return [];
    }
};

export const isMissingTerritoryId = (urlParams) => {
    return (
        urlParams.modes.includes(URL_MODE_PARAMS.SINGLE_TERRITORY) &&
        !urlParams.territoryId
    );
};

export const constructParams = (queryString) => {
    const urlParams = new URLSearchParams(queryString);
    return {
        quoteId: getQuoteId(urlParams),
        territoryId: getTerritoryId(urlParams),
        modes: getModes(urlParams),
    };
};

export const isUrlContainMode = (mode) => {
    const urlParams = store.getState().urlParams;
    return urlParams.modes.includes(mode);
};

export const getUrlTerritoryId = () => {
    const urlParams = store.getState().urlParams;
    return urlParams.territoryId;
};
