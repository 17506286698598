import * as actions from "./actionTypes";

export const noop = (payload = null) => {
    return {
        type: actions.NOOP,
        payload,
    };
};

export const roundsNetworkToggle = (payload) => {
    return {
        type: actions.ROUNDS_NETWORK_TOGGLE,
        payload,
    };
};

export const mapBuildingModeToggle = (payload) => {
    return {
        type: actions.GMAP_BUILDING_MODE_TOGGLE,
        payload,
    };
};

export const mapDrawingModeToggle = (payload) => {
    return {
        type: actions.GMAP_DRAWING_MODE_TOGGLE,
        payload,
    };
};

export const mapRangeModeToggle = (payload) => {
    return {
        type: actions.GMAP_RANGE_MODE_TOGGLE,
        payload,
    };
};

export const mapRangeValuesUpdate = (payload) => {
    return {
        type: actions.GMAP_RANGE_VALUE_UPDATE,
        payload,
    };
};

export const printingSpecsUpdate = (payload) => {
    return {
        type: actions.PRINTING_CONFIG_UPDATE,
        payload,
    };
};

export const printingSpecsReset = () => {
    return {
        type: actions.PRINTING_CONFIG_RESET,
    };
};

export const quoteDetailsUpdate = (payload) => {
    return {
        type: actions.QUOTE_CONFIG_UPDATE,
        payload,
    };
};

export function updateLodgementDetails(site, option, volume, fee) {
    return {
        type: actions.UPDATE_LODGEMENT_DETAILS,
        payload: { site, option, volume, fee },
    };
}

export const quoteDetailsReset = () => {
    return {
        type: actions.QUOTE_CONFIG_RESET,
    };
};

export const quoteMetaUpdate = (payload) => {
    return {
        type: actions.QUOTE_META_UPDATE,
        payload,
    };
};

export const quoteMetaReset = () => {
    return {
        type: actions.QUOTE_META_RESET,
    };
};

export const territorialFilterUpdate = (payload) => {
    return {
        type: actions.TERRITORIAL_FILTER_UPDATE,
        payload,
    };
};

export const territorialFilterReset = () => {
    return {
        type: actions.TERRITORIAL_FILTER_RESET,
    };
};

export const toggleSelectMode = (mode, value) => {
    return {
        type: actions.TOGGLE_SELECT_MODE,
        payload: { mode, value },
    };
};

export const updateQuoteSettings = (payload) => {
    return {
        type: actions.QUOTE_SETTINGS_UPDATE,
        payload,
    };
};

export const updateUiSettings = (payload) => {
    return {
        type: actions.UPDATE_UI_SETTINGS,
        payload,
    };
};
