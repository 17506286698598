import { LitElement, html } from "lit-element";
import { subscribe, unsubscribe } from "redux-subscribe";

import store from "../../store";
import { noop } from "../../core/actions/actions";
import { weightBrackets } from "../../services/weight";
import { nothing } from "lit-html";

class ClientQuotePrintSpecs extends LitElement {
    constructor() {
        super();

        this.printSpecs = store.getState().printingSpecs;
        this.quoteDetails = store.getState().quoteDetails;
        store.dispatch(
            subscribe("printingSpecs", "printingSpecsWatcher", (data) => {
                this.printSpecs = data.next;
                return noop("printingSpecsWatcher");
            })
        );
        store.dispatch(
            subscribe("quoteDetails", "quoteDetailsWatcher", (data) => {
                this.quoteDetails = data.next;
                return noop("quoteDetailsWatcher");
            })
        );
    }

    static get properties() {
        return {
            printSpecs: { type: Object },
            quoteDetails: { type: Object },
        };
    }

    disconnectedCallback() {
        store.dispatch(unsubscribe("printingSpecs", "printingSpecsWatcher"));
        store.dispatch(unsubscribe("quoteDetails", "quoteDetailsWatcher"));
    }

    render() {
        return html`
        <style>@import 'main.css';</style>
        <div class="pt-4"></div>
            <h3 class="pb-1 text-lg font-medium">Print specs</h3>
            <div class="bg-peppermint-50 px-4 py-1">
                ${this.printSpecs.paperSize} /
                ${this.printSpecs.paperType} / ${this.printSpecs.pagination} /
                ${this.printSpecs.gsm} / ${this.printSpecs.colours}
                ${
                    this.quoteDetails.customWeightBracket
                        ? html` / Custom weight @
                          [${weightBrackets[
                              this.quoteDetails.customWeightBracket
                          ]}]gms`
                        : nothing
                }
                ${
                    this.quoteDetails.extraCopies > 0
                        ? html` / Extra [${this.quoteDetails.extraCopies}]
                          copies`
                        : nothing
                }
            </div>
        </div>
        `;
    }
}

global.customElements.define("rm-quote-print-specs", ClientQuotePrintSpecs);
