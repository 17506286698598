import store from "../../store";

/**
 * Calculate the volume of a particular slice based on each territory network and slice selections as it
 * counts up towards quote summary volumes.
 */
export const sliceVolume = (network, slice) => {
    return store
        .getState()
        .territories.filter(
            (territory) =>
                territory.networkSelections.includes(network) &&
                territory.volumeSelections[network].includes(slice)
        )
        .reduce(
            (acc, territory) => territory.volumesMatrix[network][slice] + acc,
            0
        );
};
