import { getIdToken } from "firebase/auth";
import { LOAD_QUOTE_PATH } from "../../components/routeServices/loadQuote";
import { OCI_GATEWAY_URL, URL_CX_VIEW } from "../../config/local";
import { NOTIFICATION_TEMPLATE } from "../../const/notification";
import { auth } from "../firebase/init";
import {
    TAG_NETWORK,
    TAG_NOTIFICATION,
    plainWarning,
} from "../notifications/apiWrapper";

export const customerChangeNotification = (quote) => {
    const notificationPayload = {
        template: NOTIFICATION_TEMPLATE.QUOTE_CHANGE,
        subject: `Quote # ${quote.quoteMeta.id} has been updated by the customer`,
        variables: {
            quote_id: quote.quoteMeta.id,
            quote_sputnik_link: `${URL_CX_VIEW}#/${LOAD_QUOTE_PATH}/${quote.quoteMeta.id}`,
        },
    };

    const to = [];
    const cc = [];
    const creator = quote.quoteMeta.createdBy;
    const updator = quote.quoteMeta.updatedBy;

    if (updator) {
        to.push(updator);
    } else if (creator) {
        to.push(creator);
    } else {
        to.push("COORDINATOR");
    }

    if (creator && creator !== updator) {
        cc.push(creator);
    } else {
        cc.push("MANAGER");
    }

    notificationPayload.recipients = { to, cc };

    return emailNotification({
        notifications: [notificationPayload],
    });
};

const emailNotification = async (payload) => {
    try {
        const token = await getIdToken(auth.currentUser);
        const response = await fetch(`${OCI_GATEWAY_URL}/notifications/email`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Firebase-Token": `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            plainWarning(
                "Failed to communicate changes!",
                "Please communicate with REACH team directly.",
                TAG_NOTIFICATION
            );
        }
    } catch (error) {
        plainWarning("Network issues!", error, TAG_NETWORK);
    }
};
