export const isEmailValid = (email) => {
    try {
        return email
            ? /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                  email.trim()
              )
            : false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const isEmailsValid = (emailArray) => {
    let hasError = false;
    emailArray.forEach((email) => {
        if (!isEmailValid(email)) {
            hasError = true;
        }
    });
    return hasError;
};

const ALLOWLIST_DOMAINS = ["@reach.nz", "@reachmedia.co.nz"];

export const isReachEmail = (email) => {
    return (
        isEmailValid(email) &&
        ALLOWLIST_DOMAINS.some((domain) => email.includes(domain))
    );
};
