import isEqual from "lodash-es/isEqual";
import { subscribe } from "redux-subscribe";

import { noop, quoteDetailsUpdate } from "../core/actions/actions";
import { getTargetedTerritories } from "../core/selectors/targetingSelector";
import { getQuotableTerritories } from "../core/selectors/territorySelector";
import { territoryUpdate } from "../core/actions/territoryActions";
import store from "../store";
import {
    apartmentsPremium,
    dairyFarmer,
    networkManagement,
    targetingFee,
} from "./costCalcs";
import debounce from "./debounce";
import { freightQuote } from "./httpApis/freightQuote";
import { printingQuote } from "./httpApis/printingQuote";
import { volumeQuote } from "./httpApis/volumeQuote";
import { loadEmptyWalks, syncRoundsVolumes } from "./httpApis/roundsQueries";

import {
    territoriesSubtotal,
    territoriesTotal,
    calcVolumesMatrix,
} from "./volumes";
import { bracketKey, calcPageWeight } from "./weight";

import { GMAP_ROUNDS_LAYERS } from "../const/dataLayers";
import { VOLUME_SLICES } from "../const/volumeSlices";
import { networkManagementQuote } from "./httpApis/networkManagementQuote";
const { URBAN, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;
const { INCLUSIVE } = VOLUME_SLICES;

import { adminFeeQuote } from "./httpApis/adminFee";
import { totalQuote } from "./httpApis/totalQuote";

// NOTE: Use STATIC_QUOTE to ensure quotes are not refreshed with the current network/calcs values

// provide item weight updates - used to get weight bracket
if (!STATIC_QUOTE) {
    store.dispatch(
        subscribe("printingSpecs.paperSize", "setItemWeight1", (data) => {
            const printingSpecs = store.getState().printingSpecs;
            store.dispatch(
                quoteDetailsUpdate({
                    weight: calcPageWeight(data.next, printingSpecs.gsm),
                })
            );
            return noop(`setItemWeight1`);
        })
    );
    store.dispatch(
        subscribe("printingSpecs.gsm", "setItemWeight2", (data) => {
            const printingSpecs = store.getState().printingSpecs;
            store.dispatch(
                quoteDetailsUpdate({
                    weight: calcPageWeight(printingSpecs.paperSize, data.next),
                })
            );
            return noop(`setItemWeight3`);
        })
    );

    // Trigger volume calculator separately in the following cases
    store.dispatch(
        subscribe("quoteDetails.weight", "quoteCallVolumeCalc1", () => {
            debounceVolumeCalc();
            return noop(`quoteCallVolumeCalc1`);
        })
    );
    store.dispatch(
        subscribe("quoteDetails.weight", "quoteCallFreightCalc2", () => {
            debounceFreightCalc();
            return noop(`quoteCallFreightCalc2`);
        })
    );
    store.dispatch(
        subscribe(
            "quoteDetails.customWeightBracket",
            "quoteCallVolumeCalc2",
            () => {
                debounceVolumeCalc();
                return noop(`quoteCallVolumeCalc2`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteDetails.customWeightBracket",
            "quoteCallFreightCalc3",
            () => {
                debounceFreightCalc();
                return noop(`quoteCallFreightCalc3`);
            }
        )
    );
    // Page counter update is deprecated and is always set to one
    /* store.dispatch(
        subscribe("printingSpecs.pageCount", "quoteCallVolumeCalc3", () => {
            debounceVolumeCalc();
            return noop(`quoteCallVolumeCalc3`);
        })
    ); */
    store.dispatch(
        subscribe("quoteMeta.customerCmsCode", "quoteCallVolumeCalc4", () => {
            debounceVolumeCalc();
            return noop(`quoteCallVolumeCalc4`);
        })
    );
    store.dispatch(
        subscribe("quoteMeta.customerCmsCode", "quoteCallFreightCalc1", () => {
            debounceFreightCalc();
            return noop(`quoteCallFreightCalc1`);
        })
    );
    store.dispatch(
        subscribe("quoteMeta.customerCmsCode", "quoteCallNetworkCalc", () => {
            quoteSubtotalMatrix();
            return noop(`quoteCallNetworkCalc`);
        })
    );
    // Always load admin fee when loading an existing quote
    store.dispatch(
        subscribe("quoteMeta.id", "quoteAdminFeeUpdate", () => {
            quoteAdminFee();
            return noop(`quoteAdminFeeUpdate`);
        })
    );
    store.dispatch(
        subscribe("quoteMeta.customerCmsCode", "quoteCallAdminFee1", () => {
            quoteAdminFee();
            return noop(`quoteCallAdminFee1`);
        })
    );
    store.dispatch(
        subscribe(
            "quoteMeta.campaignStartDate",
            "quoteEmptyWalksDateChange",
            () => {
                loadEmptyWalks();
                return noop(`quoteEmptyWalksDateChange`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteMeta.campaignStartDate",
            "quoteRoundsQntiesDateChange",
            () => {
                syncRoundsVolumes();
                return noop(`quoteRoundsQntiesDateChange`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteMeta.campaignStartDate",
            "quoteVolumeDateChange",
            () => {
                debounceVolumeCalc();
                return noop(`quoteVolumeDateChange`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteMeta.campaignStartDate",
            "quoteFreightDateChange",
            () => {
                debounceFreightCalc();
                return noop(`quoteFreightDateChange`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteMeta.campaignStartDate",
            "quoteNetworkDateChange",
            () => {
                quoteSubtotalMatrix();
                return noop(`quoteNetworkDateChange`);
            }
        )
    );
    store.dispatch(
        subscribe("quoteMeta.campaignStartDate", "quoteCallAdminFee2", () => {
            quoteAdminFee();
            return noop(`quoteCallAdminFee2`);
        })
    );
    store.dispatch(
        subscribe("quoteDetails.mailerType", "quoteCallVolumeCalc5", () => {
            debounceVolumeCalc();
            return noop(`quoteCallVolumeCalc5`);
        })
    );
    store.dispatch(
        subscribe("quoteDetails.enclosed", "quoteCallVolumeCalc6", () => {
            debounceVolumeCalc();
            return noop(`quoteCallVolumeCalc6`);
        })
    );
    store.dispatch(
        subscribe("quoteDetails.oversized", "quoteCallVolumeCalc7", () => {
            debounceVolumeCalc();
            return noop(`quoteCallVolumeCalc7`);
        })
    );

    // Refresh total
    store.dispatch(
        subscribe(
            "quoteDetails.volumeCost",
            "quoteVolumeUpdateTotalCalc",
            () => {
                debounceTotalCalc();
                return noop(`quoteVolumeUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteDetails.hasFreight",
            "quoteHasFreightUpdateTotalCalc",
            () => {
                debounceTotalCalc();
                return noop(`quoteHasFreightUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteDetails.freightCost",
            "quoteFreightUpdateTotalCalc",
            () => {
                debounceTotalCalc();
                return noop(`quoteFreightUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe("quoteDetails.vfrCost", "quoteVfrUpdateTotalCalc", () => {
            debounceTotalCalc();
            return noop(`quoteVfrUpdateTotalCalc`);
        })
    );
    store.dispatch(
        subscribe(
            "quoteDetails.networkManagementCost",
            "quoteNetworkUpdateTotalCalc",
            () => {
                debounceTotalCalc();
                return noop(`quoteNetworkUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteDetails.dairyFarmerPremium",
            "quoteDairyUpdateTotalCalc",
            () => {
                debounceTotalCalc();
                return noop(`quoteDairyUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteDetails.targetingCost",
            "quoteTargetingUpdateTotalCalc",
            () => {
                debounceTotalCalc();
                return noop(`quoteTargetingUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteDetails.adminFee",
            "quoteAdminFeeUpdateTotalCalc",
            () => {
                debounceTotalCalc();
                return noop(`quoteAdminFeeUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteDetails.extraCopiesFee",
            "quoteExtraCopyUpdateTotalCalc",
            () => {
                debounceTotalCalc();
                return noop(`quoteExtraCopyUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteDetails.lodgement",
            "quoteExtraLodgementUpdateTotalCalc",
            (data) => {
                if (!isEqual(data.prev, data.next)) {
                    // debounce the following code to avoid multiple API calls
                    debounce(() => {
                        const extraCopies = calcSubtotalExtraCopies(data.next);
                        store.dispatch(
                            quoteDetailsUpdate({
                                extraCopiesFee: extraCopies["fee"],
                                extraCopies: extraCopies["volume"],
                            })
                        );
                    }, 500)();
                }
                return noop(`quoteExtraLodgementUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe(
            "quoteDetails.printingLine",
            "quotePrintingLineUpdateTotalCalc",
            () => {
                debounceTotalCalc();
                return noop(`quotePrintingLineUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe("quoteDetails.printCost", "quotePrintUpdateTotalCalc", () => {
            debounceTotalCalc();
            return noop(`quotePrintUpdateTotalCalc`);
        })
    );
    store.dispatch(
        subscribe(
            "quoteMeta.campaignStartDate",
            "quoteStartDateUpdateTotalCalc",
            () => {
                debounceTotalCalc();
                return noop(`quoteStartDateUpdateTotalCalc`);
            }
        )
    );
    store.dispatch(
        subscribe("quoteMeta.promoCode", "quotePromoCodeTotalCalc", () => {
            debounceTotalCalc();
            return noop(`quotePromoCodeTotalCalc`);
        })
    );

    store.dispatch(
        subscribe("roundsData", "roundsDataSync", (data) => {
            // for each territory recalculate volumes via calcVolumesMatrix()
            // then recalculate subtotalMatrix
            const territories = store.getState().territories;
            const roundsData = data.next;

            for (let territory of territories) {
                territory.volumesMatrix = calcVolumesMatrix(
                    territory.networkSelections,
                    territory.volumesMatrix,
                    territory.rounds,
                    roundsData
                );

                store.dispatch(territoryUpdate(territory));
            }
            return noop(`roundsDataSync`);
        })
    );
}

export const calcNetworkFee = async (subtotalMatrix, targetedMarix) => {
    const rates = await networkManagementQuote();
    const isTargeted = store.getState().quoteDetails.targeted;

    const networkManagementCost = networkManagement(
        rates["T1"],
        territoriesTotal(subtotalMatrix)
    );

    // All quotes will have basic targeting T2 applied
    let targetingCost = targetingFee(
        rates["T2"],
        territoriesTotal(subtotalMatrix)
    );

    // Use T3 for any advanced targeting
    if (isTargeted || territoriesTotal(targetedMarix) > 0) {
        targetingCost = targetingFee(
            rates["T3"],
            territoriesTotal(subtotalMatrix)
        );
    }

    return { networkManagementCost, targetingCost };
};

// ensure quote subtotals are in sync with territories
if (!STATIC_QUOTE) {
    store.dispatch(
        subscribe("territories", "quoteSubtotalMatrix1", () => {
            quoteSubtotalMatrix();
            return noop(`quoteSubtotalMatrix1`);
        })
    );
    store.dispatch(
        subscribe("quoteMeta.campaignStartDate", "quoteSubtotalMatrix2", () => {
            quoteSubtotalMatrix();
            return noop(`quoteSubtotalMatrix2`);
        })
    );
    store.dispatch(
        subscribe(
            "quoteDetails.targeted",
            "quoteSubtotalMatrixTargeted",
            () => {
                quoteSubtotalMatrix();
                return noop(`quoteSubtotalMatrixTargeted`);
            }
        )
    );
}

const quoteSubtotalMatrix = async () => {
    const subtotalMatrix = territoriesSubtotal(getQuotableTerritories());
    const targetedMarix = territoriesSubtotal(getTargetedTerritories());

    debounce(async () => {
        const { networkManagementCost, targetingCost } = await calcNetworkFee(
            subtotalMatrix,
            targetedMarix
        );

        // update state subtotals / values
        store.dispatch(quoteDetailsUpdate({ subtotalMatrix }));
        store.dispatch(
            quoteDetailsUpdate({ networkManagementCost, targetingCost })
        );
    }, 500)();
};

// trigger external all calculators when volumes and campaign date are changing
if (!STATIC_QUOTE) {
    store.dispatch(
        subscribe(
            "quoteDetails.subtotalMatrix",
            "quoteCallCalcs1",
            (changeData) => {
                callCalcs(changeData);
                return noop(`quoteCallCalcs1`);
            }
        )
    );
    store.dispatch(
        subscribe("quoteMeta.campaignStartDate", "quoteCallCalcs2", () => {
            callCalcs(store.getState().quoteDetails.subtotalMatrix);
            return noop(`quoteCallCalcs2`);
        })
    );
}
const callCalcs = (changeData) => {
    const { prev, next } = changeData;

    // quote details update actions will cause subTotalMatrix to change too, have to avoid looping it here
    if (isEqual(prev, next)) {
        return;
    }

    // make sure we don't overload API with a bunch of request if there's plenty of quick changes
    debounce(() => {
        freight(next)
            .then((freightResult) =>
                store.dispatch(
                    quoteDetailsUpdate({
                        freightCost: Number(freightResult.total),
                        vfrCost: Number(freightResult.vfr || 0),
                        distribution: mergeFreightDistribution(
                            freightResult.freightDistribution
                        ),
                    })
                )
            )
            .catch((e) => console.error(e));
        volume(next)
            .then((volumeResult) =>
                store.dispatch(
                    quoteDetailsUpdate({
                        volumeCost: volumeResult.total,
                        distribution: mergeVolumeDistribution(
                            volumeResult.volumeDistribution
                        ),
                    })
                )
            )
            .catch((e) => console.error(e));
        print(next)
            .then((printCost) =>
                store.dispatch(quoteDetailsUpdate({ printCost }))
            )
            .catch((e) => console.error(e));
        dairyFarmer()
            .then((farmerRate) =>
                store.dispatch(
                    quoteDetailsUpdate({ dairyFarmerPremium: farmerRate })
                )
            )
            .catch((e) => console.error(e));
    }, 1000)();

    // calculate apartments premium
    store.dispatch(
        quoteDetailsUpdate({
            apartmentsPremium: apartmentsPremium(apartments()),
        })
    );
};

// trigger external Volume and Freight calculators when campaign date is changing
if (!STATIC_QUOTE) {
    store.dispatch(
        subscribe(
            "quoteMeta.campaignStartDate",
            "quoteCallVolumeFreightCalcs",
            () => {
                callFreightVolumeCalcs();
                return noop(`quoteCallVolumeFreightCalcs`);
            }
        )
    );
}
const callFreightVolumeCalcs = () => {
    // get subtotal matrix
    const subtotalMatrix = store.getState().quoteDetails.subtotalMatrix;

    // make sure we don't overload API with a bunch of request if there's plenty of quick changes
    debounce(() => {
        freight(subtotalMatrix)
            .then((freightResult) =>
                store.dispatch(
                    quoteDetailsUpdate({
                        freightCost: Number(freightResult.total),
                        vfrCost: Number(freightResult.vfr || 0),
                        distribution: mergeFreightDistribution(
                            freightResult.freightDistribution
                        ),
                    })
                )
            )
            .catch((e) => console.error(e));
        volume(subtotalMatrix)
            .then((volumeResult) =>
                store.dispatch(
                    quoteDetailsUpdate({
                        volumeCost: volumeResult.total,
                        distribution: mergeVolumeDistribution(
                            volumeResult.volumeDistribution
                        ),
                    })
                )
            )
            .catch((e) => console.error(e));
        dairyFarmer()
            .then((farmerRate) =>
                store.dispatch(
                    quoteDetailsUpdate({ dairyFarmerPremium: farmerRate })
                )
            )
            .catch((e) => console.error(e));
    }, 1000)();

    // calculate apartments premium
    store.dispatch(
        quoteDetailsUpdate({
            apartmentsPremium: apartmentsPremium(apartments()),
        })
    );
};

export const quotedNetworks = (subtotalMatrix) => {
    return Object.keys(subtotalMatrix).filter((channel) => {
        if (COUNTERS === channel) {
            return subtotalMatrix[POBOXES] > 0;
        }
        return subtotalMatrix[channel] > 0;
    });
};

/**
 * Helper function to select and dispatch the quote value from the provided list based on the
 * current printer selection. If there's no selection it defaults to the first option in the Array
 * of the quotes, or to zero if it's not avaialable either.
 * @param {Array} quotes A list of quotes from the calculator API
 * @returns {bool} Indicated wither quote was successfully selected or defaulted to zero.
 *                 This could be used as indication to reset the printer selection.
 */
export const selectPrintQuote = (quotes) => {
    const printer = store.getState().printingSpecs.printer;

    if (quotes && quotes.length > 0) {
        let quote;
        if (printer) {
            // select quote for existing printer
            quote = quotes.find((item) => printer === item.printer);
        } else {
            // printer is unset
            quote = quotes[0];
        }

        // quote might be not available for the given printer
        if (quote) {
            store.dispatch(
                quoteDetailsUpdate({
                    printCost: Number(quote.revenue).toFixedNumber(2),
                })
            );
            return true;
        } else {
            store.dispatch(quoteDetailsUpdate({ printCost: 0.0 }));
            return false;
        }
    }

    store.dispatch(quoteDetailsUpdate({ printCost: 0.0 }));
    return false;
};

const debounceVolumeCalc = () => {
    // make sure we don't overload API with a bunch of request if there's plenty of quick changes
    return debounce(() => {
        volume(store.getState().quoteDetails.subtotalMatrix)
            .then((volumeResult) =>
                store.dispatch(
                    quoteDetailsUpdate({
                        volumeCost: volumeResult.total,
                        distribution: mergeVolumeDistribution(
                            volumeResult.volumeDistribution
                        ),
                    })
                )
            )
            .catch((e) => console.error(e));
    }, 1000)();
};

const debounceFreightCalc = () => {
    // make sure we don't overload API with a bunch of request if there's plenty of quick changes
    return debounce(() => {
        freight(store.getState().quoteDetails.subtotalMatrix)
            .then((freightResult) =>
                store.dispatch(
                    quoteDetailsUpdate({
                        freightCost: Number(freightResult.total),
                        vfrCost: Number(freightResult.vfr || 0),
                        distribution: mergeFreightDistribution(
                            freightResult.freightDistribution
                        ),
                    })
                )
            )
            .catch((e) => console.error(e));
    }, 1000)();
};

export const quoteAdminFee = () => {
    // make sure we don't overload API with a bunch of request if there's plenty of quick changes
    return debounce(() => {
        adminFeeQuote()
            .then((adminFee) =>
                store.dispatch(quoteDetailsUpdate({ adminFee }))
            )
            .catch((e) => console.error(e));
    }, 1000)();
};

const debounceTotalCalc = () => {
    // make sure we don't overload API with a bunch of request if there's plenty of quick changes
    return debounce(() => {
        totalQuote()
            .then((totalResult) =>
                store.dispatch(
                    quoteDetailsUpdate({
                        subtotal: totalResult.totalCost,
                        minAmtAdjustment: totalResult.minAmtAdjustment,
                        discount: totalResult.discount ?? 0,
                    })
                )
            )
            .catch((e) => console.error(e));
    }, 1000)();
};

/**
 * Volume calculator interface with API. It considers either calculated weight bracket or used the override if set by a user.
 * Dairy farmer premium is calculated and added here.
 *
 * @param {Object} subtotalMatrix
 */
export const volume = async (subtotalMatrix) => {
    const printConfig = store.getState().printingSpecs;
    const quoteDetails = store.getState().quoteDetails;

    // respect custom weight bracket choice to override weight/bracket calculations
    const bracket = quoteDetails.customWeightBracket
        ? quoteDetails.customWeightBracket
        : bracketKey(quoteDetails.weight * printConfig.pageCount);

    // get all enabled channels and obtain separate quotes
    const enabledChannels = quotedNetworks(subtotalMatrix);
    const requests = enabledChannels.map((channel) => {
        return volumeQuote(
            channel,
            bracket,
            subtotalMatrix[channel] || 0,
            quoteDetails.mailerType,
            quoteDetails.enclosed,
            quoteDetails.oversized
        );
    });

    let total = 0;
    const volumeDistribution = {};

    await Promise.all(requests).then((data) => {
        const quotes = data.map((item) => (item || {}).amount || 0);
        let hasZero = false;

        for (const [index, channel] of enabledChannels.entries()) {
            volumeDistribution[channel] = quotes[index];

            if (quotes[index] === 0 && (subtotalMatrix[channel] || 0) !== 0) {
                hasZero = true;
            }
        }

        // Fallback to POA if we get at least one POA in response (zeroed)
        if (hasZero) {
            total = 0;
        } else {
            total = quotes.reduce((acc, val) => val + acc, 0);
        }
    });

    return {
        total,
        volumeDistribution,
    };
};

/**
 * Freight calculator interface with API, simply passes requests to API.
 * @param {Object} subtotalMatrix
 */
export const freight = async (subtotalMatrix) => {
    // get all enabled networks and obtain separate quotes
    const enabledChannels = quotedNetworks(subtotalMatrix);

    const printConfig = store.getState().printingSpecs;
    const quoteDetails = store.getState().quoteDetails;

    // respect custom weight bracket choice to override weight/bracket calculations
    const bracket = quoteDetails.customWeightBracket
        ? quoteDetails.customWeightBracket
        : bracketKey(quoteDetails.weight * printConfig.pageCount);

    const requests = enabledChannels.map((network) => {
        return freightQuote(network, subtotalMatrix[network], bracket);
    });

    let total = 0;
    let vfr = 0;
    const freightDistribution = {};

    await Promise.all(requests).then((data) => {
        const quotes = data.map((item) => item || {});

        for (const [index, channel] of enabledChannels.entries()) {
            const distribution = quotes[index] || {};
            freightDistribution[channel] = distribution;
            total += distribution.amount || 0;

            if (distribution.vfr) {
                vfr += distribution.vfr;
            }
        }
    });

    return {
        total,
        vfr,
        freightDistribution,
    };
};

/**
 * Print calculator interface with API, uses either a preselected printer or defaults to first option to produce a quote.
 * Respects extra copies volume.
 * @param {Object} subtotalMatrix
 */
const print = (subtotalMatrix) => {
    const printConfig = store.getState().printingSpecs;
    const extraCopies = store.getState().quoteDetails.extraCopies;
    const printJobStartDate = store.getState().quoteMeta.printJobStartDate;
    const printer = printConfig.printer;
    // sum up all the volumes and send single quote request
    const volume = quotedNetworks(subtotalMatrix).reduce(
        (acc, channel) => subtotalMatrix[channel] + acc,
        extraCopies
    );

    return printingQuote({ ...printConfig, printJobStartDate }, volume).then(
        (data) => {
            // see if current printer is quotable, reset if not to the first option (most revenue)
            const quote = (data.prices || []).find(
                (item) => printer === item.printer
            );
            if (printer && quote) {
                return Number(quote.revenue);
            } else {
                return Number(((data.prices || [])[0] || {}).revenue) || 0;
            }
        }
    );
};

/**
 * Ad hoc temporary logic to calculate apartments premium until a better approach is established
 * There are two rounds we are filtering here, 26268 nd 26281.
 * TODO: This is extrememly inefficient and must be reconsidered ASAP.
 */
const apartments = () => {
    const roundsData = store.getState().roundsData;
    return store
        .getState()
        .territories.filter(
            (territory) => territory.volumeSelections[URBAN].length > 0
        )
        .map((territory) => Object.keys(territory.rounds))
        .flat()
        .filter((roundId) => ["26268", "26281"].includes(roundId))
        .reduce(
            (acc, roundId) =>
                acc + roundsData[roundId].properties.volumes[URBAN][INCLUSIVE],
            0
        ); // Assumption here EXCL and INCL volumes are equal
};

const mergeVolumeDistribution = (volumeDistribution) => {
    const quoteDistribution = store.getState().quoteDetails.distribution;

    for (const channel of Object.keys(volumeDistribution)) {
        if (!quoteDistribution[channel]) {
            quoteDistribution[channel] = {};
        }
        quoteDistribution[channel].volume = volumeDistribution[channel];
    }

    return quoteDistribution;
};

const mergeFreightDistribution = (freightDistribution) => {
    const quoteDistribution = store.getState().quoteDetails.distribution;

    for (const channel of Object.keys(freightDistribution)) {
        if (!quoteDistribution[channel]) {
            quoteDistribution[channel] = {};
        }
        quoteDistribution[channel].freight = {
            amount: Number(freightDistribution[channel].amount || 0),
            vfr: Number(freightDistribution[channel].vfr || 0),
        };
    }

    return quoteDistribution;
};

/**
 * Summarize extra copies fees and volumes based on the lodgement details so it can be used in other calcs.
 * Iterates overall lodgement sites and their options and sums up the fee and volume state.
 * See core/reducers/quoteDetails.js for the structure.
 * @param {Object} lodgement The lodgement details object from the quoteDetails.lodgement state.
 * @returns {Object} The total fee and volume for extra copies object { fee: Number, volume: Number }
 */
const calcSubtotalExtraCopies = (lodgement) => {
    return Object.keys(lodgement).reduce(
        (acc, site) => {
            return {
                fee:
                    acc.fee +
                    Object.keys(lodgement[site]).reduce((acc, option) => {
                        return option === "COPIES"
                            ? acc + lodgement[site][option].fee
                            : acc;
                    }, 0),
                volume:
                    acc.volume +
                    Object.keys(lodgement[site]).reduce((acc, option) => {
                        return option === "COPIES"
                            ? acc + lodgement[site][option].volume
                            : acc;
                    }, 0),
            };
        },
        { fee: 0, volume: 0 }
    );
};
