import { LitElement, html } from "lit-element";
import { subscribe, unsubscribe } from "redux-subscribe";
import store from "../../store";
import { noop } from "../../core/actions/actions";
import { repeat } from "lit-html/directives/repeat";
import { nothing } from "lit-html";
import targetingVariables from "../../const/targetingVariables";
import {
    getActiveTerritory,
    getFirstTerritoryWithBoundingBox,
    getTerritoryById,
} from "../../core/selectors/territorySelector";
import { getUrlTerritoryId } from "../../services/urlHeplers";

class TargetingSummary extends LitElement {
    constructor() {
        super();
        this.settings = store.getState().settings;
        this.targetingFilters = store.getState().targetingFilters;

        store.dispatch(
            subscribe("settings", "targetingSummarySettingsWatcher", (data) => {
                this.settings = data.next;
                return noop("targetingSummarySettingsWatcher");
            })
        );

        store.dispatch(
            subscribe("activeTerritory", "activeTerritoryUpdate", (data) => {
                this.activeTerritory = data.next;
                return noop("activeTerritoryUpdate");
            })
        );

        store.dispatch(
            subscribe("targetingFilters", "targetingFiltersWatch", (data) => {
                this.targetingFilters = data.next;
                return noop("targetingFiltersWatch");
            })
        );
    }

    static get properties() {
        return {
            activeTerritory: { type: String },
            settings: { type: Object },
            targetingFilters: { type: Object },
        };
    }

    disconnectedCallback() {
        store.dispatch(
            unsubscribe("settings", "targetingSummarySettingsWatcher")
        );
        store.dispatch(
            unsubscribe("targetingFilters", "targetingFiltersWatch")
        );
        store.dispatch(unsubscribe("activeTerritory", "activeTerritoryUpdate"));
    }

    get targetingGroups() {
        const focusedTerritory =
            getTerritoryById(getUrlTerritoryId()) ||
            getActiveTerritory() ||
            getFirstTerritoryWithBoundingBox() ||
            {};
        return (
            (
                this.targetingFilters.find(
                    (t) => t.territoryId === focusedTerritory.id
                ) || {}
            ).groups || []
        );
    }

    groupType(group) {
        return targetingVariables[group.variablesGroup].type;
    }

    groupName(group) {
        return targetingVariables[group.variablesGroup].name;
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            ${this.settings.targetingSummary && this.targetingGroups.length > 0
                ? html`
                      <div class="pt-4">
                          <h3 class="pb-1 text-lg font-medium">
                              Targeting Variables
                          </h3>
                          <div class="bg-peppermint-50 px-4 py-1">
                              ${repeat(
                                  this.targetingGroups,
                                  (group, _) => html`
                                      <div class="flex flex-wrap">
                                          <span class="font-medium pr-1"
                                              >${this.groupName(group)}</span
                                          >
                                          ${repeat(
                                              group.variables,
                                              (variable, _) => html`
                                                  <span class="pr-1"
                                                      >/ ${variable}</span
                                                  >
                                              `
                                          )}
                                          ${repeat(
                                              group.values,
                                              (value, _) => html`
                                                  <span class="pr-1"
                                                      >/ ${value}</span
                                                  >
                                              `
                                          )}
                                          ${null !== group.minThreshold
                                              ? html`<span class="pr-1"
                                                    >/ Min :
                                                    ${group.minThreshold}${this.groupType(
                                                        group
                                                    ) === "Fraction"
                                                        ? "%"
                                                        : nothing}</span
                                                >`
                                              : nothing}
                                          ${null !== group.maxThreshold
                                              ? html`<span
                                                    >/ Max :
                                                    ${group.maxThreshold}${this.groupType(
                                                        group
                                                    ) === "Fraction"
                                                        ? "%"
                                                        : nothing}</span
                                                >`
                                              : nothing}
                                      </div>
                                  `
                              )}
                          </div>
                      </div>
                  `
                : nothing}
        `;
    }
}

global.customElements.define("rm-targeting-summary", TargetingSummary);
