import clonedeep from "lodash-es/cloneDeep";
import { GMAP_ROUNDS_LAYERS } from "../../const/dataLayers";
import { ROUNDS_NETWORK_TOGGLE } from "../actions/actionTypes";

export const initialState = {
    [GMAP_ROUNDS_LAYERS.URBAN]: true,
    [GMAP_ROUNDS_LAYERS.POSTIES]: false,
    [GMAP_ROUNDS_LAYERS.RURAL]: false,
    [GMAP_ROUNDS_LAYERS.POBOXES]: false,
};

export const networkVisibility = (state = initialState, action) => {
    switch (action.type) {
        case ROUNDS_NETWORK_TOGGLE: {
            const next = clonedeep(state);
            let toggle_layers = {};
            // we don't want to allow user to display both posties and walkers as they don't match
            if (
                action.payload[GMAP_ROUNDS_LAYERS.URBAN] &&
                action.payload[GMAP_ROUNDS_LAYERS.POSTIES]
            ) {
                toggle_layers = {
                    [GMAP_ROUNDS_LAYERS.URBAN]: !next[GMAP_ROUNDS_LAYERS.URBAN],
                    [GMAP_ROUNDS_LAYERS.POSTIES]:
                        !next[GMAP_ROUNDS_LAYERS.POSTIES],
                };
            }
            return { ...next, ...action.payload, ...toggle_layers };
        }
        default:
            return state;
    }
};
