import { LitElement, html } from "lit-element";
import "./appFooter";

export const clientBlockingErrorEvent = (detail) =>
    new CustomEvent("client:blocking:error", {
        detail,
        bubbles: true,
        composed: true,
    });

class BlockingErrorModal extends LitElement {
    constructor() {
        super();

        document.addEventListener(
            clientBlockingErrorEvent("").type,
            (e) => this.enableBlockingMode(e.detail),
            { once: true }
        );
    }

    static get properties() {
        return {
            message: { type: String },
        };
    }

    /**
     * Render template into light DOM.
     * TODO: could be reviewed once all components are migrated to lit-element
     */
    createRenderRoot() {
        return this;
    }

    enableBlockingMode(message) {
        this.message = message;
        this.classList.toggle("hidden", false);
    }

    render() {
        return html`
            <div
                class="absolute flex opacity-25 bg-midnight-blue w-screen h-screen z-10"
            ></div>
            <div class="absolute flex w-screen h-screen z-10">
                <div
                    class="rounded xl:w-2/5 lg:w-3/5 w-full m-auto bg-white z-10 p-4"
                >
                    <h2 class="text-2xl text-peppermint mb-1">Uh oh!</h2>
                    <details class="mb-4" open>
                        <summary class="outline-none cursor-pointer">
                            Unfortunately, something went wrong on our side.
                        </summary>
                        <p>${this.message}</p>
                    </details>

                    <h3>
                        For assistance, please give us a call at
                        <span class="text-peppermint">0800 732 2487</span>
                    </h3>
                    <rm-app-footer></rm-app-footer>
                </div>
            </div>
        `;
    }
}

global.customElements.define("rm-blocking-error-modal", BlockingErrorModal);
