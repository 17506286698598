import { QUOTE_SETTINGS_UPDATE } from "../actions/actionTypes";

export const settings = (state = {}, action) => {
    switch (action.type) {
        case QUOTE_SETTINGS_UPDATE: {
            return { ...action.payload };
        }
        default:
            return state;
    }
};
