const wait = (ms) => new Promise((r) => setTimeout(r, ms));

export const retryOperation = (operation, maxRetries, delay = 100, retry = 0) =>
    new Promise((resolve, reject) => {
        return operation()
            .then(resolve)
            .catch((reason) => {
                if (maxRetries > 0) {
                    return wait(delay * retry)
                        .then(
                            retryOperation.bind(
                                null,
                                operation,
                                maxRetries - 1,
                                delay,
                                retry + 1
                            )
                        )
                        .then(resolve)
                        .catch(reject);
                }
                return reject(reason);
            });
    });
