import clonedeep from "lodash-es/cloneDeep";

import {
    TARGETING_FILTER_ADD,
    TARGETING_FILTER_GROUP_ADD,
    TARGETING_FILTER_GROUP_ACTIVATE,
    TARGETING_FILTER_GROUP_UPDATE_VARS,
    TARGETING_FILTER_GROUP_UPDATE_VARS_POOL,
    TARGETING_FILTER_GROUP_UPDATE_VALUES,
    TARGETING_FILTER_GROUP_DELETE,
    TARGETING_FILTER_GROUP_UPDATE_THRESHOLDS,
    TARGETING_FILTER_UPDATE_BASE_ROUNDS,
    TARGETING_FILTER_UPDATE_VOLUME_LIMIT,
    TARGETING_TERRITORY_RESET,
    TERRITORY_DELETE,
    TERRITORIES_RESET,
    TERRITORY_UPDATE_ROUNDS,
    TARGETING_FILTERS_LOAD,
} from "../actions/actionTypes";
import { fromGeoJson } from "./territoryReducer";
import { getSelectedRounds } from "../../services/rounds";

const blankTargetingFilter = (territoryId) => ({
    territoryId,
    activeGroup: null,
    groups: [],
    rounds: {},
    volumeLimit: null,
});

const blankFilterGroup = () => ({
    variables: [],
    variablesPool: [],
    variablesGroup: "", // this is the name of the variable group so that we accept only one per filter group
    minThreshold: null,
    maxThreshold: null,
    values: [],
});

export const targetingFilters = (state = [], action, crossSliceState) => {
    const territoryId = crossSliceState.activeTerritory;

    switch (action.type) {
        case TARGETING_FILTER_ADD: {
            const next = [
                ...clonedeep(state),
                blankTargetingFilter(territoryId),
            ];
            return next;
        }
        case TARGETING_FILTERS_LOAD: {
            // bulk load targeting filters
            return [...clonedeep(action.payload)];
        }
        case TARGETING_FILTER_GROUP_ADD: {
            const next = clonedeep(state);
            const filter = activeFilter(next, territoryId);
            if (filter && filter.groups) {
                filter.groups.push(blankFilterGroup());
                filter.activeGroup = filter.groups.length - 1;
                return replaceFilter(next, territoryId, filter);
            }
            return state;
        }
        case TARGETING_FILTER_GROUP_DELETE: {
            const next = clonedeep(state);
            const filter = activeFilter(next, territoryId);
            if (filter && filter.groups) {
                filter.groups.splice(action.payload.index, 1);
                // TODO: update active group. Not breaking anything now though.
                return replaceFilter(next, territoryId, filter);
            }
            return state;
        }
        case TARGETING_FILTER_GROUP_ACTIVATE: {
            const next = clonedeep(state);
            const filter = activeFilter(next, territoryId);
            if (filter && filter.groups) {
                filter.activeGroup = action.payload.activeGroupId;
                return replaceFilter(next, territoryId, filter);
            }
            return state;
        }
        case TARGETING_FILTER_GROUP_UPDATE_VARS:
        case TARGETING_FILTER_GROUP_UPDATE_VARS_POOL: {
            // logic is very similar so we combine reducer
            const varsSet =
                TARGETING_FILTER_GROUP_UPDATE_VARS === action.type
                    ? "variables"
                    : "variablesPool";
            const next = clonedeep(state);
            const filter = activeFilter(next, territoryId);
            if (filter && filter.groups && filter.activeGroup !== null) {
                if ("" === filter.groups[filter.activeGroup].variablesGroup) {
                    filter.groups[filter.activeGroup].variablesGroup =
                        action.payload.variablesGroup;
                }

                // accept update only if proposed variables have the same type or this is a brand new set
                if (
                    filter.groups[filter.activeGroup].variablesGroup ===
                    action.payload.variablesGroup
                ) {
                    filter.groups[filter.activeGroup][varsSet] = [
                        ...action.payload.variables,
                    ];
                    return replaceFilter(next, territoryId, filter);
                }
            }

            return state;
        }
        case TARGETING_FILTER_GROUP_UPDATE_VALUES: {
            const next = clonedeep(state);
            const filter = activeFilter(next, territoryId);
            if (filter && filter.groups && filter.activeGroup !== null) {
                filter.groups[filter.activeGroup].values = [
                    ...action.payload.values,
                ];
                return replaceFilter(next, territoryId, filter);
            }
            return state;
        }
        case TARGETING_FILTER_GROUP_UPDATE_THRESHOLDS: {
            const next = clonedeep(state);
            const filter = activeFilter(next, territoryId);
            if (filter && filter.groups && filter.activeGroup !== null) {
                filter.groups[filter.activeGroup][
                    action.payload.thresholdType
                ] = action.payload.value;
                return replaceFilter(next, territoryId, filter);
            }
            return state;
        }
        case TARGETING_FILTER_UPDATE_VOLUME_LIMIT: {
            const next = clonedeep(state);
            const filter = activeFilter(next, territoryId);
            if (filter && filter.volumeLimit !== undefined) {
                filter.volumeLimit = action.payload.value;
                return replaceFilter(next, territoryId, filter);
            }
            return state;
        }
        case TARGETING_FILTER_UPDATE_BASE_ROUNDS: {
            const next = clonedeep(state);
            const filter = activeFilter(next, territoryId);
            if (filter && filter.rounds !== undefined) {
                filter.rounds = clonedeep(action.payload.rounds);
                return replaceFilter(next, territoryId, filter);
            }
            return state;
        }
        case TARGETING_TERRITORY_RESET:
            return state.filter((item) => item.territoryId != territoryId);
        case TERRITORY_UPDATE_ROUNDS: {
            const next = clonedeep(state);
            const filter = activeFilter(next, territoryId);
            if (filter && filter.rounds !== undefined) {
                const rounds = getSelectedRounds(fromGeoJson(action.payload));
                Object.keys(filter.rounds).map(
                    (network) =>
                        (filter.rounds[network] = [
                            ...filter.rounds[network],
                            ...rounds[network],
                        ])
                );
                return replaceFilter(next, territoryId, filter);
            }
            return state;
        }
        // garbage collector
        case TERRITORY_DELETE:
            return clonedeep(state).filter(
                (filter) => filter.territoryId != action.payload
            );
        case TERRITORIES_RESET:
            return [];
        // end of garbage collection
        default:
            return state;
    }
};

const activeFilter = (state, territoryId) => {
    return state.find((filter) => filter.territoryId === territoryId);
};

const replaceFilter = (state, territoryId, newItem) => {
    return state.map((item) => {
        return item.territoryId == territoryId ? newItem : item;
    });
};
