import { LitElement, html } from "lit-element";
import { nothing } from "lit-html";
import { repeat } from "lit-html/directives/repeat";
import { subscribe, unsubscribe } from "redux-subscribe";

import { URL_MODE_PARAMS } from "../../const/urlParams";
import { noop } from "../../core/actions/actions";
import { territoryActivate } from "../../core/actions/territoryActions";
import { getTerritoryById } from "../../core/selectors/territorySelector";
import { visualiseNetworkSelections } from "../../services/controlsModes";
import { map } from "../../services/googleMap";
import { isUrlContainMode } from "../../services/urlHeplers";
import { territorySubtotal } from "../../services/volumes";
import store from "../../store";
import "../territoryMetaTable";

class ClientQuoteTerritoriesList extends LitElement {
    constructor() {
        super();
        this.territories = store.getState().territories;
        this.activeTerritory = store.getState().activeTerritory;

        store.dispatch(
            subscribe("territories", "updateTerritoriesList", (data) => {
                this.territories = data.next;
                return noop("updateTerritoriesList");
            })
        );
        store.dispatch(
            subscribe("activeTerritory", "watchActiveTerritory", (data) => {
                this.activeTerritory = data.next;
                return noop("watchActiveTerritory");
            })
        );
    }

    static get properties() {
        return {
            territories: { type: Object },
            activeTerritory: { type: String },
            isTerritoryVolumeViewable: { type: Boolean },
        };
    }

    disconnectedCallback() {
        store.dispatch(unsubscribe("territories", "updateTerritoriesList"));
        store.dispatch(unsubscribe("activeTerritory", "watchActiveTerritory"));
    }

    selectTerritory(id) {
        const territory = getTerritoryById(id);
        if (territory.location.center) {
            map.panTo(territory.location.center);
        }
        store.dispatch(territoryActivate(territory.id));

        if (!isUrlContainMode(URL_MODE_PARAMS.SINGLE_TERRITORY)) {
            visualiseNetworkSelections();
        }
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="pt-4 px-4 block ${this.territories.length ? "" : "hidden"}">
                <h3 class="pb-1 text-xl font-medium text-dark-grey">Territories</h3>
                ${
                    repeat(
                        this.territories,
                        (territory) => html`
                            <div
                                @click=${() => this.selectTerritory(territory.id)}
                                class="${this.activeTerritory === territory.id
                                    ? "border-sunrise"
                                    : "border-bluesteel"} flex items-center justify-between px-4 py-1 border-l-4 mb-2 cursor-pointer hover:shadow hover:bg-peppermint-50"
                            >
                                <div class="">
                                    ${(territory.location.formatted || {}).main_text || ""}
                                    <span class="text-xs">[${territorySubtotal(territory)} mailers]</span>
                                </div>
                                <div class="h-4 w-4" style="background-color:${territory.colour}"></div>
                            </div>
                            ${this.isTerritoryVolumeViewable
                                ? html`
                                      <rm-territory-meta-table
                                          class="${this.activeTerritory === territory.id ? "" : "hidden"} pb-2"
                                          .territory=${territory}
                                          .clientView=${true}
                                      ></rm-territory-meta-table>
                                  `
                                : nothing}
                        `
                    )
                    // TODO: display something in place of title if unavailable
                }
            </div>
        `;
    }
}

global.customElements.define("rm-client-quote-territories-list", ClientQuoteTerritoriesList);
