export const EMAIL_PATTERN =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const PHONE_PATTERN = /^(\+?)(\d+[ ]{0,1}\d?)+$/;
export const POSTCODE_PATTERN = /^[0-9]{0,4}$/;

export const validateErrors = (rules, values) => {
    const result = {};
    Object.keys(rules).forEach((key) => {
        if (!isValueValid(rules[key], values[key])) {
            result[key] = rules[key].errMsg;
        }
    });

    return result;
};

export const rule = (required, pattern, errMsg) => ({
    required,
    pattern,
    errMsg,
});

export const isValueValid = (rule, value) => {
    const { required, pattern } = rule;
    try {
        return value
            ? pattern.test(String(value).trim())
            : required
            ? false
            : true;
    } catch (error) {
        console.log(error);
        return false;
    }
};
