import { LitElement, html } from "lit-element";
import { repeat } from "lit-html/directives/repeat";
import { classMap } from "lit-html/directives/class-map";

export const clientToPreviousOptionEvent = new Event("client:toprevios:option");
export const clientToNextOptionEvent = new Event("client:tonext:option");
export const clientSelectOptionEvent = new Event("client:select:option");
export const clientSelectedOptionEvent = (detail) =>
    new CustomEvent("client:selected:option", {
        detail,
        bubbles: true,
        composed: true,
    });

class TypeAheadOptions extends LitElement {
    constructor() {
        super();
        this.activeOption = 0;
        this.matches = [];

        this.baseCss = {
            "px-6": true,
            "py-2": true,
            uppercase: true,
            "border-b": true,
            "border-dark-grey": true,
            "cursor-pointer": true,
            "bg-white": true,
            "hover:bg-blue-25": true,
        };
        this.activeCss = {
            "bg-white": false,
            "bg-blue-25": true,
        };
    }

    static get properties() {
        return {
            selected: { type: Array, reflect: true },
            options: { type: Array },
            activeOption: { type: Number },
            matchString: { type: String, reflect: true },
            maxResults: { type: Number },
            value: { type: String, reflect: true },
        };
    }

    firstUpdated() {
        this.addEventListener(clientToPreviousOptionEvent.type, (event) => {
            event.stopPropagation();
            this.activeOption > 0 ? this.activeOption-- : null;
        });
        this.addEventListener(clientToNextOptionEvent.type, (event) => {
            event.stopPropagation();
            this.activeOption <
            Math.min(this.maxResults, this.matches.length) - 1
                ? this.activeOption++
                : null;
        });
        this.addEventListener(clientSelectOptionEvent.type, (event) => {
            event.stopPropagation();
            this.select(this.matches[this.activeOption]);
        });
    }

    get matches() {
        return this.options
            .filter((str) =>
                this.matchString
                    ? str
                          .toLowerCase()
                          .startsWith(this.matchString.toLowerCase()) &&
                      !this.selected.includes(str)
                    : false
            )
            .slice(0, this.maxResults);
    }

    set matches(val) {}

    updated(changedProperties) {
        if (changedProperties && changedProperties.get("matches")) {
            this.activeOption = 0;
            this.value = this.matches[this.activeOption]
                ? this.matches[this.activeOption]
                : "";
        }
    }

    optionCss(active) {
        return active
            ? classMap({ ...this.baseCss, ...this.activeCss })
            : classMap(this.baseCss);
    }

    select(data) {
        this.dispatchEvent(clientSelectedOptionEvent(data));
        // edge case when the last option is selected
        this.activeOption == this.matches.length - 1
            ? (this.activeOption = Math.max(this.activeOption - 1, 0))
            : null;
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <ul>
                ${repeat(
                    this.matches,
                    (item, i) => html`
                        <li
                            class="${this.optionCss(i == this.activeOption)}"
                            @click=${() => this.select(item)}
                        >
                            ${item}
                        </li>
                    `
                )}
            </ul>
        `;
    }
}

global.customElements.define("rm-typeahead-options", TypeAheadOptions);
