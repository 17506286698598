import { LitElement } from "lit-element";
import { classMap } from "lit-html/directives/class-map";

export const PROGRESS = "PROGRESS";
export const INACTIVE = "INACTIVE";
export const ACTIVE = "ACTIVE";

export class StatefulButtonBase extends LitElement {
    constructor() {
        super();
        this.status = ACTIVE;

        this.baseCss = {
            "px-4": true,
            "py-1": true,
            "rounded-sm": true,
            "bg-peppermint": true,
            "border-peppermint": true,
            "text-white": true,
            "items-center": true,
        };

        this.inactiveCss = {
            ...this.baseCss,
        };

        this.progressCss = {
            ...this.baseCss,
            "opacity-50": true,
            "pointer-events-none": true,
        };
    }

    static get properties() {
        return {
            status: { type: String },
        };
    }

    itemCss() {
        switch (this.status) {
            case PROGRESS:
                return classMap(this.progressCss);
            case INACTIVE:
                return classMap(this.inactiveCss);
            case ACTIVE:
            default:
                return classMap(this.baseCss);
        }
    }
}
