import {
    EMAIL_PATTERN,
    POSTCODE_PATTERN,
    PHONE_PATTERN,
    rule,
} from "./validator";

export const cmsRule = rule(false, /^([0-9]{8})?$/, "");

export const customerRules = {
    email: rule(false, EMAIL_PATTERN, "Please enter correct email"),
    postcode: rule(false, POSTCODE_PATTERN, "Please enter correct postcode"),
    phone: rule(false, PHONE_PATTERN, "Please enter correct phone number"),
};
