import { paperSheetsPsm } from "../core/reducers/printingSpecs";

export const weightBrackets = {
    25: "0 — 25",
    51: "26 — 50",
    100: "51 — 100",
    150: "101 — 150",
    200: "151 — 200",
    250: "201 — 250",
    300: "251 — 300",
    350: "301 — 350",
    400: "351 — 400",
    450: "401 — 450",
    500: "451 — 500",
    550: "501 — 550",
    600: "551 — 600",
};

export const calcPageWeight = (size, gsm) => {
    const sheetsNum = paperSheetsPsm[size];
    const gsmWeight = Number(gsm.replace("gsm", ""));
    if (!sheetsNum) {
        throw new Error(`Unexpected or undefined paper size! [${size}]`);
    }
    return (gsmWeight / sheetsNum).toFixedNumber(2);
};

export const bracketTitle = (weight) => {
    return weightBrackets[bracketKey(weight)];
};

export const bracketKey = (weight) => {
    return Object.keys(weightBrackets).find((item) => item >= weight);
};
