import clonedeep from "lodash-es/cloneDeep";

import { GMAP_BUILDING_MODE } from "../../const/buidlingMode";
import { GMAP_BUILDING_MODE_TOGGLE } from "../actions/actionTypes";

const initialState = {
    [GMAP_BUILDING_MODE.AREA]: true,
    [GMAP_BUILDING_MODE.RANGE]: false,
};

export const mapBuildingMode = (state = initialState, action) => {
    switch (action.type) {
        case GMAP_BUILDING_MODE_TOGGLE: {
            const next = clonedeep(state);
            Object.keys(next).forEach((key) => (next[key] = false));
            return { ...next, ...action.payload };
        }

        default:
            return state;
    }
};
