/*
    Both objects should have the same or similar structure, the function will iterate over the top properties
    only and will return the keys for mismatching values. Mismatching properties wouldn't get geturned. 
    First object used as a base for comparison.
*/
export const diffKeys = (obj1, obj2) => {
    return Object.keys(obj1).filter((key1) =>
        obj2[key1] !== undefined && obj1[key1] !== obj2[key1] ? true : false
    );
};
