import { EMPTY_ROUNDS_UPDATE } from "../actions/actionTypes";
import clonedeep from "lodash-es/cloneDeep";

export const emptyWalks = (state = [], action) => {
    switch (action.type) {
        case EMPTY_ROUNDS_UPDATE: {
            const next = clonedeep(action.payload);
            return [...next];
        }
        default:
            return state;
    }
};
