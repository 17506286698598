import store from "../../store";
import { getAllTerritoriesIds } from "./territorySelector";

export const getTargetingFilterById = (territoryId) => {
    return store
        .getState()
        .targetingFilters.find((filter) => filter.territoryId === territoryId);
};

export const isTerritoryTargeted = (territoryId) => {
    const filter = getTargetingFilterById(territoryId);
    return filter && filter.groups.length;
};

// get a list of targeted territories
export const getTargetedTerritories = (territories) => {
    const targetedIds = getTargetedTerritoriesIds();

    if (territories) {
        return territories.filter((territory) =>
            targetedIds.includes(territory.id)
        );
    }
    return store
        .getState()
        .territories.filter((territory) => targetedIds.includes(territory.id));
};

export const getTargetedTerritoriesIds = () => {
    // use two steps filtering here to make sure we get current territories only
    const targeted = store
        .getState()
        .targetingFilters.map((filter) => filter.territoryId);
    return targeted.filter((id) => getAllTerritoriesIds().includes(id));
};
