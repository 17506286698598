import { isEmptyWalk } from "../../core/selectors/roundSelector";

/**
 * Template used by feature info window to display round details.
 * @param {Object} feature An instance of google.maps.Data.Feature
 */
export const roundDetailsHTML = (feature) => {
    const isEmpty = isEmptyWalk(feature.getProperty("walk_id"));
    return `
    <table>
    <tbody>
        <tr class="border-b border-pastel-blue">
            <td class="py-2 px-2">Walk ID</td>
            <td class="text-right">${feature.getProperty("walk_id")}</td>
        </tr>
        <tr class="${isEmpty ? "border-b border-pastel-blue" : ""}">
            <td class="py-2 px-2">Round name</td>
            <td class="text-right">${feature.getProperty("round_name")}</td>
        </tr>
        ${
            isEmpty
                ? `<tr>
                <td class="py-2 px-2">EMPTY WALK</td>
            </tr>`
                : ""
        }
    </tbody>
    </table>
    `;
};
