export const isQuoteLocked = (quoteDetails) => {
    return quoteDetails.locked;
};

export const hasJobNumber = (quoteMeta) => {
    return quoteMeta.jobNumber && quoteMeta.jobNumber.length > 0;
};

export const isQuoteTargeted = (quoteDetails) => {
    return quoteDetails.targeted;
};

export const hasTargetingVariables = (targetingFilters) => {
    return targetingFilters && targetingFilters.length > 0;
};
