import { html } from "lit-element";
import { nothing } from "lit-html";

import "../progressSpinner";
import { StatefulButtonBase, PROGRESS } from "../base/statefulButtonBase";

class ImportRoundsButton extends StatefulButtonBase {
    constructor() {
        super();
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-row items-center">
                <rm-progress-spinner
                    class="mr-2 ${PROGRESS === this.status
                        ? nothing
                        : "hidden"}"
                ></rm-progress-spinner>
                <button title="Import" class="${this.itemCss()}">
                    <span class="text-sm font-medium uppercase">Import</span>
                </button>
            </div>
        `;
    }
}

global.customElements.define("rm-import-rounds-btn", ImportRoundsButton);
