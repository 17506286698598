import { GMAP_ROUNDS_LAYERS } from "../const/dataLayers";
const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;
import { VOLUME_SLICES } from "../const/volumeSlices";
import { territoryUpdate } from "../core/actions/territoryActions";
import store from "../store";
const {
    INCLUSIVE,
    EXCLUSIVE,
    RESIDENTIAL,
    BUSINESS,
    FARMER,
    DAIRY,
    NEWSPAPER_EXCLUSIVE,
    NEWSPAPER_INCLUSIVE,
    NEWSPAPER_ALL,
} = VOLUME_SLICES;

const roundVolumeMapper = (roundData, layer, slice) => {
    if (!roundData || !roundData.properties || !roundData.properties.volumes) {
        return 0;
    }

    const volumes = roundData.properties.volumes;
    return volumes[layer][slice] || 0;
};

const sliceVolumes = (layer, slice, rounds, roundsData) => {
    return Object.keys(rounds)
        .filter((roundId) => {
            if (!rounds[roundId].selected) return false;
            if (COUNTERS === layer) {
                return POBOXES === rounds[roundId].layer;
            }
            return layer === rounds[roundId].layer;
        })
        .map((roundId) => roundVolumeMapper(roundsData[roundId], layer, slice))
        .reduce((acc, volume) => acc + volume, 0);
};

export const layerVolumes = (layer, rounds, roundsData) => {
    // changes happen to layer level granularity only, so it can be optimised like this on invocation level
    // TODO: optimise it further so it doesn't walk through all rounds every time
    switch (layer) {
        case URBAN:
            return {
                [INCLUSIVE]: sliceVolumes(URBAN, INCLUSIVE, rounds, roundsData),
                [EXCLUSIVE]: sliceVolumes(URBAN, EXCLUSIVE, rounds, roundsData),
                [NEWSPAPER_INCLUSIVE]: sliceVolumes(
                    URBAN,
                    NEWSPAPER_INCLUSIVE,
                    rounds,
                    roundsData
                ),
                [NEWSPAPER_EXCLUSIVE]: sliceVolumes(
                    URBAN,
                    NEWSPAPER_EXCLUSIVE,
                    rounds,
                    roundsData
                ),
            };
        case POSTIES:
            return {
                [INCLUSIVE]: sliceVolumes(
                    POSTIES,
                    INCLUSIVE,
                    rounds,
                    roundsData
                ),
                [EXCLUSIVE]: sliceVolumes(
                    POSTIES,
                    EXCLUSIVE,
                    rounds,
                    roundsData
                ),
                [BUSINESS]: sliceVolumes(POSTIES, BUSINESS, rounds, roundsData),
            };
        case RURAL:
            return {
                [NEWSPAPER_ALL]: sliceVolumes(
                    RURAL,
                    NEWSPAPER_ALL,
                    rounds,
                    roundsData
                ),
                [INCLUSIVE]: sliceVolumes(RURAL, INCLUSIVE, rounds, roundsData),
                [RESIDENTIAL]: sliceVolumes(
                    RURAL,
                    RESIDENTIAL,
                    rounds,
                    roundsData
                ),
                [FARMER]: sliceVolumes(RURAL, FARMER, rounds, roundsData),
                [DAIRY]: sliceVolumes(RURAL, DAIRY, rounds, roundsData),
            };
        case POBOXES:
            return {
                [RESIDENTIAL]: sliceVolumes(
                    POBOXES,
                    RESIDENTIAL,
                    rounds,
                    roundsData
                ),
                [BUSINESS]: sliceVolumes(POBOXES, BUSINESS, rounds, roundsData),
                [FARMER]: sliceVolumes(POBOXES, FARMER, rounds, roundsData),
            };
        case COUNTERS:
            return {
                [RESIDENTIAL]: sliceVolumes(
                    COUNTERS,
                    RESIDENTIAL,
                    rounds,
                    roundsData
                ),
                [FARMER]: sliceVolumes(COUNTERS, FARMER, rounds, roundsData),
            };
    }
    // we are not supposed to get here
    throw new Error(`Unexpected call with layer = [${layer}]`);
};

export const calcVolumesMatrix = (
    layers,
    volumesMatrix,
    rounds,
    roundsData
) => {
    const volumes = { ...volumesMatrix };
    layers.forEach(
        (layer) => (volumes[layer] = layerVolumes(layer, rounds, roundsData))
    );
    return volumes;
};

export const layerSubtotal = (layerSlices, layerVolumes) => {
    if (!layerSlices || !layerVolumes) {
        return 0;
    }
    return layerSlices.reduce((acc, slice) => acc + layerVolumes[slice], 0);
};

export const territoriesSubtotal = (territories) => {
    const subtotalDefault = {
        [URBAN]: 0,
        [POSTIES]: 0,
        [RURAL]: 0,
        [POBOXES]: 0,
        [COUNTERS]: 0,
    };
    return territories
        .map((territory) =>
            territory.networkSelections.reduce(
                (acc, layer) => {
                    acc[layer] = layerSubtotal(
                        territory.volumeSelections[layer],
                        territory.volumesMatrix[layer]
                    );
                    return acc;
                },
                { ...subtotalDefault }
            )
        )
        .reduce(
            (acc, territorySubtotal) => {
                Object.keys(territorySubtotal).forEach(
                    (layer) => (acc[layer] += territorySubtotal[layer])
                );
                return acc;
            },
            { ...subtotalDefault }
        );
};

export const territoriesTotal = (subtotalMatrix) => {
    return Object.keys(subtotalMatrix).reduce(
        (acc, layer) => acc + subtotalMatrix[layer],
        0
    );
};

export const territorySubtotal = (territory) => {
    return territory.networkSelections.reduce((acc, layer) => {
        return (
            acc +
            layerSubtotal(
                territory.volumeSelections[layer],
                territory.volumesMatrix[layer]
            )
        );
    }, 0);
};

export const setupAllCustomerChanges = (
    territories,
    roundsData,
    recalculate = false
) => {
    return territories.map((territory) =>
        setupTerritoryCustomerChanges(territory, roundsData, recalculate)
    );
};

export const setupTerritoryCustomerChanges = (
    territory,
    roundsData,
    recalculate = false
) => {
    if (!territory.customerChanges) return territory;

    territory.customerChanges.added.forEach((roundId) => {
        territory.rounds[roundId].selected = true;
    });
    territory.customerChanges.removed.forEach((roundId) => {
        territory.rounds[roundId].selected = false;
    });

    if (recalculate) {
        territory.volumesMatrix = calcVolumesMatrix(
            territory.networkSelections,
            territory.volumesMatrix,
            territory.rounds,
            roundsData
        );
    }

    return territory;
};

export const purgeCustomerChanges = (territories) => {
    return territories.map((territory) => {
        delete territory.customerChanges;

        return territory;
    });
};

export const updateTerritoryState = (territories) => {
    territories.forEach((territory) =>
        store.dispatch(territoryUpdate(territory))
    );
};

export const hasTerritoryCustomerChanges = (territory) => {
    if (!territory.customerChanges) {
        return false;
    }
    return (
        territory.customerChanges.added.length > 0 ||
        territory.customerChanges.removed.length > 0
    );
};

export const hasCustomerChanges = (territories) => {
    for (const territory of territories) {
        if (
            territory.customerChanges &&
            (territory.customerChanges.added.length > 0 ||
                territory.customerChanges.removed.length > 0)
        ) {
            return true;
        }
    }
    return false;
};
