import store from "../../store";

export const territoryActive = (id) => {
    return store.getState().activeTerritory == id;
};

export const getActiveTerritory = () => {
    const activeId = store.getState().activeTerritory;
    return store
        .getState()
        .territories.find((territory) => territory.id == activeId);
};

export const getInactiveTerritories = () => {
    const activeId = store.getState().activeTerritory;
    return store
        .getState()
        .territories.filter((territory) => territory.id != activeId);
};

export const getTerritoryById = (id) => {
    return store.getState().territories.find((territory) => territory.id == id);
};

export const getAllTerritories = () => {
    return store.getState().territories;
};

export const getAllTerritoriesIds = () => {
    return getAllTerritories().map((territory) => territory.id);
};

export const isTerritoryQuotable = (territory) => {
    return territory.quotable === undefined ? true : territory.quotable;
};

export const getQuotableTerritories = (territories) => {
    if (territories && territories.length > 0) {
        return territories.filter((territory) =>
            isTerritoryQuotable(territory)
        );
    }
    return getAllTerritories().filter((territory) =>
        isTerritoryQuotable(territory)
    );
};

export const getFirstTerritoryWithBoundingBox = () => {
    return store
        .getState()
        .territories.find(
            (territory) => territory.location.boundingBox != null
        );
};
