import store from "../store";

export let map;

export const mapInit = (el) => {
    map = new google.maps.Map(el, {
        streetViewControl: false,
        mapTypeControl: false,
        clickableIcons: false,
    });
};

/* 
    Default NZ latLng -41.1417474, 173.1288766
*/
const defaultPosition = async (lat = -41.1417474, lng = 173.1288766) => {
    let latLng;
    return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                // Center on user's current location if geolocation prompt allowed
                latLng = new google.maps.LatLng(
                    position.coords.latitude,
                    position.coords.longitude
                );
                resolve({ latLng, zoom: 13 });
            },
            () => {
                // User denied geolocation prompt - default to params
                latLng = new google.maps.LatLng(lat, lng);
                resolve({ latLng, zoom: 5 });
            }
        );
    });
};

export const mapDefaultPan = () => {
    defaultPosition().then((position) => {
        map.setCenter(position.latLng);
        map.setZoom(position.zoom);
    });
};

export const getBoundFromBox = (coordinates) => {
    try {
        const sw = new google.maps.LatLng(coordinates[0][1], coordinates[0][0]);
        const ne = new google.maps.LatLng(coordinates[2][1], coordinates[2][0]);
        return new google.maps.LatLngBounds(sw, ne);
    } catch (error) {
        console.log("Can not initiate bound from coordinates.");
        console.log(error);
        return null;
    }
};

export const fitTerritoryBound = (territory) => {
    const boundingBox = (territory.location || {}).boundingBox || null; // handle in case active is not in quotable
    if (boundingBox) {
        const bound = getBoundFromBox(boundingBox);
        map.fitBounds(bound);
    }
};
export const mapBootstrappedEvent = new Event("client:map:bootstrapped");

export let geoCoder;
document.addEventListener(mapBootstrappedEvent.type, () => {
    geoCoder = new google.maps.Geocoder();
});

export const geoCodeLatLng = (address) => {
    return new Promise((resolve, reject) => {
        geoCoder.geocode({ address: address }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                const latLng = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                };

                resolve(latLng);
            } else {
                reject(new Error(status));
            }
        });
    });
};

const customerChangesList = {
    added: {
        name: "Proposed Addition",
    },
    removed: {
        name: "Proposed Removal",
    },
    addedBox: {
        name: "Proposed PO Box Addition",
    },
    removedBox: {
        name: "Proposed PO Box Removal",
    },
};

const legendList = {
    urban: {
        name: "REACH",
    },
    rural: {
        name: "Rural",
    },
    posties: {
        name: "Postie",
    },
    poboxes: {
        name: "PO Boxes",
    },
};

const setLegendItem = (legend, item, type) => {
    const name = item.name;
    const div = document.createElement("div");

    div.className = "label";
    div.innerHTML =
        '<div class="box ' +
        type +
        '"></div><span class="ml-2">' +
        name +
        "</span>";
    legend.appendChild(div);
};

const setLegendSeparator = (legend) => {
    const separator = document.createElement("hr");
    legend.appendChild(separator);
};

const setLegendForEditableQuote = (legend) => {
    for (const type in customerChangesList) {
        setLegendItem(legend, customerChangesList[type], type);
    }

    setLegendSeparator(legend);
};

export const setLegends = () => {
    const legend = document.getElementById("legend");
    legend.className = "m-3 mt-20 p-4 grid gap-2";

    const isQuoteEditable = store.getState().settings.isQuoteEditable;

    if (isQuoteEditable) {
        setLegendForEditableQuote(legend);
    }

    for (const type in legendList) {
        setLegendItem(legend, legendList[type], type);
    }

    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(legend);
};
