import { LitElement, html } from "lit-element";
import { nothing } from "lit-html";
import { subscribe, unsubscribe } from "redux-subscribe";
import { URL_CLIENT_VIEW } from "../config/local";
import {
    noop,
    quoteDetailsUpdate,
    quoteMetaUpdate,
} from "../core/actions/actions";
import { dayMonthYear, isDatePast } from "../services/dateHelper";
import {
    hasTargetingVariables,
    isQuoteLocked,
    isQuoteTargeted,
} from "../services/quoteHelper";
import store from "../store";
import circleCheckIcon from "./svg/checkmark-circle";
import linkIcon from "./svg/link";

export class QuoteDetailsPanel extends LitElement {
    constructor() {
        super();
        this.quote = store.getState().quoteDetails;
        this.meta = store.getState().quoteMeta;
        this.targetingFilters = store.getState().targetingFilters;

        store.dispatch(
            subscribe("quoteMeta", "updateMetaQuoteDetailsPanel", (data) => {
                this.meta = data.next;
                this.requestUpdate();
                return noop("updateMetaQuoteDetailsPanel");
            })
        );
        store.dispatch(
            subscribe(
                "quoteDetails",
                "updateDetailsQuoteDetailsPanel",
                (data) => {
                    this.quote = data.next;
                    return noop("updateDetailsQuoteDetailsPanel");
                }
            )
        );
        store.dispatch(
            subscribe(
                "targetingFilters",
                "updateTargetingFiltersQuoteDetailsPanel",
                (data) => {
                    this.targetingFilters = data.next;

                    if (hasTargetingVariables(data.next)) {
                        this.toggleTargetedValue(true);
                    }
                    return noop("updateTargetingFiltersQuoteDetailsPanel");
                }
            )
        );
    }

    static get properties() {
        return {
            quote: { type: Object },
            quoteMeta: { type: Object },
            targetingFilters: { type: Object },
        };
    }

    disconnectedCallback() {
        store.dispatch(unsubscribe("quoteMeta", "updateMetaQuoteDetailsPanel"));
        store.dispatch(
            unsubscribe("quoteDetails", "updateDetailsQuoteDetailsPanel")
        );
        store.dispatch(
            unsubscribe(
                "targetingFilters",
                "updateTargetingFiltersQuoteDetailsPanel"
            )
        );
    }

    toggleTargetedValue(value) {
        store.dispatch(quoteDetailsUpdate({ targeted: value }));
    }

    renderLockedDetails() {
        return html` <section class="flex flex-row flex-grow-0">
            <div class="flex flex-col my-1 gap-1">
                <p>
                    Campaign Start
                    Date:&nbsp;${this.meta.campaignStartDate
                        ? dayMonthYear(
                              new Date(this.meta.campaignStartDate),
                              "-"
                          )
                        : "N/A"}
                </p>
                <p>Promo Code:&nbsp;${this.meta.promoCode ?? "N/A"}</p>
                ${isQuoteTargeted(this.quote)
                    ? html`<div class="flex">
                          <span class="text-peppermint">
                              ${circleCheckIcon}</span
                          >
                          <p class="pl-1 self-end text-blue font-medium">
                              Demographic targeting applied
                          </p>
                      </div>`
                    : nothing}
            </div>
        </section>`;
    }

    renderClientView() {
        return html`<style>
                @import "main.css";
            </style>
            <div class="pt-4 px-4 block">
                <h1 class="font-bold text-2xl text-peppermint">
                    ${this.meta.name}
                </h1>
                <span class="italic text-sm text-gray-500"
                    >Quote ID: ${this.meta.id}</span
                >
                <h3 class="mt-4 pb-1 text-xl font-medium text-dark-grey">
                    Quote details
                </h3>
                ${this.renderLockedDetails()}
            </div>`;
    }

    render() {
        if (IS_CLIENT_APP) return this.renderClientView();

        return html`
            <style>
                @import "main.css";
            </style>
            <div
                class="relative border border-dark-grey rounded-sm px-3 py-2 mt-4 flex flex-col justify-between leading-normal"
            >
                ${this.meta.id
                    ? html`<a
                          href="${URL_CLIENT_VIEW}?${this.meta.id}"
                          target="_blank"
                          class="font-bold text-xl text-peppermint"
                          >${this.meta.name}&nbsp;${linkIcon}</a
                      >`
                    : html`<h1 class="font-bold text-xl">Untitled quote</h1>`}
                ${this.meta.id
                    ? html`<span class="text-sm text-gray-500"
                          >Quote ID: ${this.meta.id}</span
                      >`
                    : nothing}
                ${this.meta.updatedOn
                    ? html`<span class="italic"
                          >Last updated
                          ${dayMonthYear(
                              new Date(this.meta.updatedOn),
                              "-"
                          )}</span
                      >`
                    : nothing}
                ${isQuoteLocked(this.quote)
                    ? this.renderLockedDetails()
                    : html`<section class="flex flex-col pt-2">
                          <!-- Updated class -->
                          <div class="my-1">
                              <div class="flex flex-col">
                                  <!-- Updated class -->
                                  ${html`
                                      <div
                                          class="flex justify-between mb-2 
                                      ${isDatePast(this.meta.campaignStartDate)
                                              ? "p-2 border-2 border-danger"
                                              : ""}
                                  "
                                      >
                                          <!-- Updated class -->
                                          <label
                                              class="mr-2"
                                              for="save-valid-until"
                                          >
                                              Campaign Start Date
                                          </label>
                                          <input
                                              type="date"
                                              class="border w-2/5 rounded-sm leading-tight p-1"
                                              .min="${new Date()
                                                  .toISOString()
                                                  .slice(0, 10)}"
                                              .value="${this.meta
                                                  .campaignStartDate
                                                  ? new Date(
                                                        this.meta.campaignStartDate
                                                    )
                                                        .toISOString()
                                                        .slice(0, 10)
                                                  : null}"
                                              @input=${(e) =>
                                                  store.dispatch(
                                                      quoteMetaUpdate({
                                                          campaignStartDate:
                                                              e.target.value,
                                                      })
                                                  )}
                                          />
                                      </div>
                                      <div class="flex justify-between mb-2">
                                          <!-- Updated class -->
                                          <label class="mr-2" for="promo-code">
                                              Promo Code
                                          </label>
                                          <input
                                              type="text"
                                              class="border w-2/5 rounded-sm leading-tight p-1"
                                              .value="${this.meta.promoCode ??
                                              ""}"
                                              @input=${(e) =>
                                                  store.dispatch(
                                                      quoteMetaUpdate({
                                                          promoCode:
                                                              e.target.value,
                                                      })
                                                  )}
                                              @keydown=${(e) => {
                                                  if ("Enter" == e.key) {
                                                      e.target.blur();
                                                  }
                                              }}
                                          />
                                      </div>
                                  `}
                              </div>
                              <label class="block mt-1">
                                  <input
                                      type="checkbox"
                                      class="checkbox align-middle mr-1"
                                      .checked=${this.quote.targeted}
                                      .disabled=${isQuoteLocked(this.quote) ||
                                      hasTargetingVariables(
                                          this.targetingFilters
                                      )}
                                      @change=${(e) =>
                                          this.toggleTargetedValue(
                                              e.target.checked
                                          )}
                                  />
                                  ${this.quote.targeted
                                      ? html`<span
                                            class="align-middle text-blue font-medium"
                                        >
                                            Demographic targeting applied
                                        </span>`
                                      : html`<span class="align-middle"
                                            >Apply demographic targeting</span
                                        >`}
                              </label>
                          </div>
                      </section>`}
            </div>
        `;
    }
}

global.customElements.define("rm-quote-details-panel", QuoteDetailsPanel);
