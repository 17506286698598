import clonedeep from "lodash-es/cloneDeep";
import {
    TERRITORIAL_FILTER_UPDATE,
    TERRITORIAL_FILTER_RESET,
} from "../actions/actionTypes";

const initialState = () => {
    return {
        district: [],
        deliveryManager: [],
        deliveryArea: [],
        roundName: [],
        regionalCouncil: [],
        territorialAuthority: [],
        electorate: [],
        ward: [],
        localBoard: [],
        localBody: [],
    };
};

export const updateDeepArray = (target, update) => {
    Object.keys(update).map((entry) => {
        update[entry].forEach((item) => {
            let i;
            if ((i = target[entry].indexOf(item)) > -1) {
                // ensure item gets dropped if exists
                target[entry].splice(i, 1);
            } else {
                target[entry].push(item);
            }
        });
    });
    return target;
};

export const territorialFilters = (state = initialState(), action) => {
    switch (action.type) {
        case TERRITORIAL_FILTER_RESET:
            return initialState();
        case TERRITORIAL_FILTER_UPDATE: {
            const next = clonedeep(state);
            return updateDeepArray(next, action.payload);
        }
        default:
            return state;
    }
};
