import clonedeep from "lodash-es/cloneDeep";

import { ROUTER_UPDATE } from "../actions/actionTypes";

const initialState = () => {
    return {
        current: null,
        params: [],
    };
};

export const appRouter = (state = initialState(), action) => {
    switch (action.type) {
        case ROUTER_UPDATE: {
            const next = clonedeep(state);
            const { current, params } = action.payload;
            return { ...next, current, params };
        }
        default:
            return state;
    }
};
