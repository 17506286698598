import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export default (definitions, size) =>
    html`${unsafeHTML(`
<svg viewBox="0 0 1024 1024" class="fill-current stroke-current w-${
        size ? size : 4
    } h-${size ? size : 4} inline-block" xmlns="http://www.w3.org/2000/svg">
    ${definitions}
</svg>
`)}`;
