import { geoCodeLatLng } from "./googleMap";
import { plainWarning } from "./notifications/apiWrapper";
import "./stringHelpers";

export const parseCoordinates = (coordinateString) => {
    if (!coordinateString) return null;
    try {
        const [lat, lng] = coordinateString
            .split(",")
            .map((el) => Number(el.trim()));
        return { lat, lng };
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const parseCsv = (input) => {
    const lines = input.split(/(?:\r\n|\n)+/).filter((el) => el.length != 0);
    let [parsed, success] = [false, true];

    const result = lines.map((line) => {
        let items = line.split(",").map((item) => item.trim());

        for (let i = 0; i < items.length; i++) {
            if (items[i].length == 0) continue;
            if (items[i].charAt(0) == '"') {
                parsed = true;
                let j = 0;
                let foundCloseQuote = false;

                for (j = i; j < items.length; j++) {
                    if (
                        items[j].length > 0 &&
                        items[j].charAt(items[j].length - 1) == '"'
                    ) {
                        foundCloseQuote = true;
                        break;
                    }
                }
                if (!foundCloseQuote) {
                    success = false;
                    break;
                }
                if (foundCloseQuote) {
                    items[i] = items[i].removeAt(0); // remove the first quote
                    items[j] = items[j].removeAt(items[j].length - 1); // remove the last quote
                    if (i != j) {
                        for (let k = i + 1; k <= j; k++) {
                            items[i] = items[i] + "," + items[i + 1];
                            items.splice(i + 1, 1);
                        }
                    }
                }
            }
        }
        return items;
    });
    if (!(parsed && success)) {
        throw new Error("Invalid csv format");
    }
    return result;
};

export const territoriesFromCsvArray = async (csvArray) => {
    let territories = [];
    for (let i = 0; i < csvArray.length; i++) {
        const csvLine = csvArray[i];

        let [name, address, walkId, corrs] = csvLine;
        if (!name || !walkId) {
            throw `Line ${i + 1} is not valid!`;
        }
        let territory =
            territories.find((t) => t.name === name) ||
            (territories = territories.concat({
                name: name,
                walkIds: [],
                location: {},
            }))
                .slice(-1)
                .pop();

        territory.walkIds.push(walkId);

        const { location } = territory;
        location.address = location.address || address;
        location.center = location.center || parseCoordinates(corrs);
        if (!location.center && location.address) {
            try {
                location.center = await geoCodeLatLng(location.address);
            } catch (error) {
                plainWarning("Fail to geocode address!", error);
            }
        }
        territory = { ...territory, location };

        territories = territories.map((item) => {
            return item.name == name ? territory : item;
        });
    }
    return territories;
};
