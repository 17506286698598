import { collection } from "firebase/firestore";
import { LitElement, html } from "lit-element";
import { nothing } from "lit-html";
import { repeat } from "lit-html/directives/repeat";
import { until } from "lit-html/directives/until";

import { clientDialogCloseEvent } from "../dialog";
import "../progressSpinner";
import "../wordsCloud";

import { auth, firestore } from "../../services/firebase/init";
import { dayMonthYear } from "../../services/dateHelper";
import {
    loadAllQuotes,
    loadQuote,
    loadState,
    searchQuotesByTags,
} from "../../services/loadQuote";
import { ACTIVE, PROGRESS } from "../base/statefulButtonBase";

class QuotesList extends LitElement {
    constructor() {
        super();
        this.quoteCollRef = collection(firestore, "quotes");
        this.searchTags = [];

        // dummy value to init template safely
        this.list = Promise.resolve([]);
        this.loadList();

        this.status = ACTIVE;
    }

    static get properties() {
        return {
            status: { type: String },
            list: { type: Object },
            searchTags: { type: Array },
        };
    }

    firstUpdated() {
        super.firstUpdated();
        this.searchTagInputField.focus();
    }

    async loadList() {
        if (!auth.currentUser) {
            return;
        }

        if (this.searchTags.length === 0) {
            this.list = loadAllQuotes();
        } else {
            this.list = searchQuotesByTags(this.searchTags);
        }
    }

    get searchTagInputField() {
        return this.shadowRoot.querySelector("input");
    }

    progressBar() {
        return html` <rm-progress-spinner></rm-progress-spinner> `;
    }

    async loadQuote(id) {
        this.status = PROGRESS;
        const quoteData = await loadQuote(id);
        await loadState(quoteData);

        this.status = ACTIVE;
        document.dispatchEvent(clientDialogCloseEvent);
    }

    async quotesList() {
        return this.list.then((quotes) => {
            return html`
            <div class="absolute inset-0 dim-white-50 flex items-center justify-center ${
                ACTIVE === this.status ? "hidden" : nothing
            }">
                <rm-progress-spinner></rm-progress-spinner>
            </div>
            <div class="flex-grow-0">
                <table class="text-left w-full border-b border-t border-dark-grey">
                    <thead>
                        <tr class=" border-dark-grey border-l border-r">
                            <th class="pl-2 py-2 text-sm uppercase font-medium">Name</th>
                            <th class="w-32 text-sm uppercase font-medium">Campaign</th>
                            <th class="w-32 text-sm uppercase font-medium">Created On</th>
                            <th class="w-32 text-sm uppercase font-medium">Updated On</th>
                            <th class="w-48 text-sm uppercase font-medium">Owner</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="flex-1 overflow-y-auto">
                <table class="text-center w-full border-b border-t border-dark-grey">
                    <colgroup>
                        <col class="border-r border-l border-dark-grey"></col>
                        <col class="border-r border-dark-grey"></col>
                        <col class="border-r border-dark-grey"></col>
                        <col class="border-r border-dark-grey"></col>
                        <col class="border-r border-dark-grey"></col>
                    </colgroup>
                    <tbody>
                        ${repeat(quotes, (quote) => {
                            // quotes meta storage has all fields lowercased
                            return html`
                                <tr
                                    @click=${() =>
                                        this.loadQuote(quote.quoteMeta.id)}
                                    class="border-t border-dark-grey cursor-pointer hover:bg-peppermint-50"
                                >
                                    <td class="text-left pl-2 py-1">
                                        ${quote.quoteMeta.name}
                                        ${quote.quoteMeta.details
                                            ? html`<span class="pl-2 text-xs"
                                                  >(${quote.quoteMeta
                                                      .details})</span
                                              >`
                                            : nothing}
                                        ${quote.quoteMeta.tags.length
                                            ? html`${repeat(
                                                  quote.quoteMeta.tags,
                                                  (tag) =>
                                                      html`<span
                                                          class="pl-1 text-xs uppercase inline-block"
                                                          >[${tag}]</span
                                                      >`
                                              )}`
                                            : nothing}
                                    </td>
                                    <td class="w-32">
                                        ${quote.quoteMeta.campaignStartDate
                                            ? dayMonthYear(
                                                  new Date(
                                                      quote.quoteMeta.campaignStartDate
                                                  )
                                              )
                                            : "—"}
                                    </td>
                                    <td class="w-32">
                                        ${dayMonthYear(
                                            new Date(quote.quoteMeta.createdOn)
                                        )}
                                    </td>
                                    <td class="w-32">
                                        ${dayMonthYear(
                                            new Date(quote.quoteMeta.updatedOn)
                                        )}
                                    </td>
                                    <td
                                        class="text-left py-1 inline-block w-48 truncate"
                                    >
                                        ${quote.quoteMeta.createdBy}
                                    </td>
                                </tr>
                            `;
                        })}
                    </tbody>
                </table>
            </div>
            `;
        });
    }

    addSearchTag() {
        if ("" === this.searchTagInputField.value.trim()) {
            return;
        }

        this.searchTags = [
            ...this.searchTags,
            this.searchTagInputField.value.trim(),
        ];
        this.loadList();
    }

    removeSearchTag(tag) {
        this.searchTags.splice(this.searchTags.indexOf(tag), 1);
        this.searchTags = [...this.searchTags];
        this.loadList();
    }

    resetSearchTagsInput() {
        this.searchTagInputField.value = "";
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-col h-full">
                <div class="flex flex-wrap flex-grow-0 justify-between py-2">
                    <h3 class="text-xl font-medium">Load Quote</h3>
                    <button
                        @click=${() =>
                            document.dispatchEvent(clientDialogCloseEvent)}
                        title="Close [Esc]"
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">
                            Close
                            <span class="text-xs lowercase">[Esc]</span>
                        </span>
                    </button>
                </div>
                <div class="pt-2 pb-4 flex flex-col flex-grow-0">
                    <input
                        type="text"
                        class="block border rounded-sm leading-tight w-full p-1"
                        placeholder="Search by tag (case insensitive)"
                        @keydown=${(e) => {
                            if ("Enter" == e.key) {
                                this.addSearchTag();
                                this.resetSearchTagsInput();
                            }
                        }}
                    />
                </div>
                <div
                    class="flex flex-col ${this.searchTags.length > 0
                        ? ""
                        : "hidden"}"
                >
                    <rm-words-cloud
                        @client:selected:option=${(e) =>
                            this.removeSearchTag(e.detail)}
                        class="inline-block mb-1"
                        .items=${this.searchTags}
                        .selected=${this.searchTags}
                    ></rm-words-cloud>
                </div>
                <div class="pt-2 pb-4 flex flex-col flex-1 overflow-y-auto">
                    ${until(this.quotesList(), this.progressBar())}
                </div>
            </div>
        `;
    }
}

global.customElements.define("rm-quotes-list", QuotesList);
