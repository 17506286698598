import hash from "./hash";

// need to store timers references globally
global.debounceTimers = [];
export default (fn, time) => {
    return function () {
        // our functions are anonymous so identify them by source code hash
        const fnId = hash(fn.toString());

        const functionCall = () => fn.apply(this, arguments);

        global.clearTimeout(global.debounceTimers[fnId]);
        global.debounceTimers[fnId] = global.setTimeout(functionCall, time);
    };
};
