import clonedeep from "lodash-es/cloneDeep";
import DistinctColours from "distinct-colors";

import {
    TERRITORY_ADD,
    TERRITORY_UPDATE_LOCATION,
    TERRITORY_UPDATE_ROUNDS,
    TERRITORY_UPDATE_ROUNDS_SELECTION,
    TERRITORY_UPDATE_VOLUME_SELECTIONS,
    TERRITORY_COPY,
    TERRITORY_ORDERING,
    TERRITORY_PUSH,
    TERRITORY_UPDATE_NETWORK_SELECTIONS,
    TERRITORY_FLUSH_ROUNDS,
    TERRITORY_DELETE,
    TERRITORY_ROUND_VISIBILITY_TOGGLE,
    TERRITORIES_RESET,
    TERRITORY_NEIGHBOUR_ROUNDS,
    TERRITORY_SET_COLOUR,
    TERRITORY_LOAD,
    TERRITORY_ROUNDS_APPLY_TARGETING,
    TERRITORY_QUOTABLE_TOGGLE,
    TERRITORY_TOGGLE_EMPTY_WALKS_SELECTION,
    TERRITORY_UPDATE_CUSTOMER_CHANGES,
    TERRITORY_UPDATE,
} from "../actions/actionTypes";
import { GMAP_ROUNDS_LAYERS } from "../../const/dataLayers";
const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;
import { VOLUME_SLICES } from "../../const/volumeSlices";
const {
    INCLUSIVE,
    EXCLUSIVE,
    RESIDENTIAL,
    BUSINESS,
    FARMER,
    DAIRY,
    NEWSPAPER_EXCLUSIVE,
    NEWSPAPER_INCLUSIVE,
    NEWSPAPER_ALL,
} = VOLUME_SLICES;

import { calcVolumesMatrix } from "../../services/volumes";

let palette = [];
const getColour = () => {
    if (0 == palette.length) {
        palette = new DistinctColours({ count: 128 });
    }
    return palette.shift().hex("rgb");
};

const blankTerritory = ({ id = null, networkSelections = [] }) => {
    return {
        id,
        customerStoreId: null,
        colour: getColour(),
        location: {
            center: null,
            address: null,
            formatted: null,
        },
        volumeSelections: {
            [URBAN]: [EXCLUSIVE],
            [POSTIES]: [EXCLUSIVE],
            [RURAL]: [],
            [POBOXES]: [],
            [COUNTERS]: [],
        },
        volumesMatrix: {
            [URBAN]: {
                [INCLUSIVE]: 0,
                [EXCLUSIVE]: 0,
                [NEWSPAPER_INCLUSIVE]: 0,
                [NEWSPAPER_EXCLUSIVE]: 0,
            },
            [POSTIES]: {
                [INCLUSIVE]: 0,
                [EXCLUSIVE]: 0,
                [BUSINESS]: 0,
            },
            [RURAL]: {
                [NEWSPAPER_ALL]: 0,
                [RESIDENTIAL]: 0,
                [FARMER]: 0,
                [DAIRY]: 0,
            },
            [POBOXES]: {
                [RESIDENTIAL]: 0,
                [BUSINESS]: 0,
                [FARMER]: 0,
            },
            [COUNTERS]: {
                [RESIDENTIAL]: 0,
                [FARMER]: 0,
            },
        },
        networkSelections,
        rounds: {},
    };
};

const replaceTerritory = (state, territory) => {
    return clonedeep(state).map((item) => {
        return item.id == territory.id ? territory : item;
    });
};

const activeTerritory = (state, cross) => {
    return state.find((territory) => territory.id == cross.activeTerritory);
};

const territoryById = (state, id) => {
    return state.find((territory) => territory.id == id);
};

const copyTerritory = (territory, newId) => {
    const newTerritory = clonedeep(territory);
    newTerritory.id = newId;
    const formatted = territory.location.formatted || {};
    formatted.main_text = (formatted.main_text || "New territory") + "[Copy]";
    newTerritory.location.formatted = formatted;
    newTerritory.colour = getColour();
    return newTerritory;
};

const orderingTerritory = (oldIndex, newIndex, state) => {
    const stateCopy = clonedeep(state);
    stateCopy.splice(newIndex, 0, stateCopy.splice(oldIndex, 1)[0]);
    return stateCopy;
};

const getTerritoryCustomerChanges = (state, cross) => {
    const territory = activeTerritory(state, cross);
    if (!territory.customerChanges) {
        return {
            added: [],
            removed: [],
        };
    }
    return territory.customerChanges;
};

const updateTerritoryCustomerChanges = (state, territory, changes, roundId) => {
    const roundChanges = clonedeep(changes);
    const isRoundSelected = territory.rounds[roundId].selected;
    const isAddedIndex = roundChanges.added.indexOf(roundId);
    const isRemovedIndex = roundChanges.removed.indexOf(roundId);

    // Initial state (not added or removed)
    if (isAddedIndex < 0 && isRemovedIndex < 0) {
        if (isRoundSelected) {
            roundChanges.removed.push(roundId);
        } else {
            roundChanges.added.push(roundId);
        }
    } else if (isAddedIndex >= 0) {
        // Delete previously added round
        roundChanges.added.splice(isAddedIndex, 1);
    } else if (isRemovedIndex >= 0) {
        // Delete previously removed round
        roundChanges.removed.splice(isRemovedIndex, 1);
    }

    return replaceTerritory(state, {
        ...territory,
        ...{
            customerChanges: roundChanges,
        },
    });
};

const toggleAllEmtySelection = (territory, selected, emptyWalks) => {
    const territoryRounds = territory.rounds;
    Object.keys(territoryRounds).forEach((walk_id) => {
        const round = territoryRounds[walk_id];
        if (!round || undefined === round.selected) {
            return;
        }
        if (emptyWalks.includes(Number(walk_id))) {
            round.selected = selected;
        }
    });
};

const toggleTerritoryQuotable = (territoryId, quotable, state) => {
    const next = clonedeep(state);
    next.find((territory) => territory.id == territoryId).quotable = quotable;
    return next;
};

export const territories = (state, action, crossSliceState) => {
    switch (action.type) {
        case TERRITORIES_RESET: {
            // this one doesn't have access to crossslice
            return [blankTerritory({ id: action.payload })];
        }
        case TERRITORY_ADD: {
            const networkSelections = Object.keys(crossSliceState.networkVisibility).filter(
                (item) => crossSliceState.networkVisibility[item]
            );
            if (networkSelections.includes(POBOXES) && !networkSelections.includes(COUNTERS)) {
                networkSelections.push(COUNTERS);
            }
            return [
                {
                    ...blankTerritory({
                        id: action.payload,
                        networkSelections,
                    }),
                },
                ...clonedeep(state),
            ];
        }
        case TERRITORY_PUSH: {
            const networkSelections = Object.keys(crossSliceState.networkVisibility).filter(
                (item) => crossSliceState.networkVisibility[item]
            );
            if (networkSelections.includes(POBOXES) && !networkSelections.includes(COUNTERS)) {
                networkSelections.push(COUNTERS);
            }
            return [
                ...clonedeep(state),
                {
                    ...blankTerritory({
                        id: action.payload,
                        networkSelections,
                    }),
                },
            ];
        }
        case TERRITORY_LOAD: {
            // ensure territory is recalculated against loaded rounds data if loaded directly
            const volumesMatrix = calcVolumesMatrix(
                [URBAN, RURAL, POSTIES, POBOXES, COUNTERS],
                action.payload.volumesMatrix,
                action.payload.rounds,
                crossSliceState.roundsData
            );
            const territory = { ...action.payload, volumesMatrix };
            const next = clonedeep(state);
            next.push(territory);
            return next;
        }
        case TERRITORY_UPDATE: {
            const i = state.findIndex((territory) => territory.id == action.payload.id);
            const next = clonedeep(state);
            next.splice(i, 1, action.payload);
            return next;
        }
        case TERRITORY_DELETE: {
            const i = state.findIndex((territory) => territory.id == action.payload);
            const next = clonedeep(state);
            next.splice(i, 1);
            return next;
        }
        case TERRITORY_COPY: {
            return [
                ...clonedeep(state),
                {
                    ...copyTerritory(action.payload.territory, action.payload.newId),
                },
            ];
        }
        case TERRITORY_ORDERING: {
            return [...orderingTerritory(action.payload.oldIndex, action.payload.newIndex, state)];
        }
        case TERRITORY_QUOTABLE_TOGGLE: {
            return [...toggleTerritoryQuotable(action.payload.territoryId, action.payload.quotable, state)];
        }
        // next actions are performed with the active territory only
        case TERRITORY_SET_COLOUR:
            return replaceTerritory(state, {
                ...clonedeep(activeTerritory(state, crossSliceState)),
                colour: action.payload,
            });
        case TERRITORY_UPDATE_LOCATION: {
            const territory = clonedeep(
                action.payload.territoryId
                    ? territoryById(state, action.payload.territoryId)
                    : activeTerritory(state, crossSliceState)
            );
            const location = {
                ...territory.location,
                ...JSON.parse(JSON.stringify(action.payload.location)),
            }; // get rid of complex payload object
            return replaceTerritory(state, { ...territory, location });
        }
        case TERRITORY_UPDATE_VOLUME_SELECTIONS: {
            const { layer } = action.payload;
            const territory = clonedeep(activeTerritory(state, crossSliceState));
            const selections = territory.volumeSelections;

            let volumeSelections = {
                ...selections,
                ...constrainVolumeSelections(selections[layer], action.payload),
            };
            if (POBOXES === layer) {
                volumeSelections = {
                    ...volumeSelections,
                    ...constrainVolumeSelections(volumeSelections[COUNTERS], {
                        ...action.payload,
                        layer: COUNTERS,
                    }),
                };
            }
            return replaceTerritory(state, { ...territory, volumeSelections });
        }
        case TERRITORY_UPDATE_NETWORK_SELECTIONS: {
            const territory = clonedeep(activeTerritory(state, crossSliceState));
            const networkSelections = [...action.payload];
            if (networkSelections.includes(POBOXES) && !networkSelections.includes(COUNTERS)) {
                networkSelections.push(COUNTERS);
            }
            return replaceTerritory(state, {
                ...territory,
                networkSelections,
            });
        }
        case TERRITORY_UPDATE_ROUNDS_SELECTION: {
            const territory = clonedeep(
                action.payload.territoryId
                    ? territoryById(state, action.payload.territoryId)
                    : activeTerritory(state, crossSliceState)
            );

            return replaceTerritory(state, {
                ...territory,
                rounds: action.payload.rounds,
            });
        }
        case TERRITORY_UPDATE_ROUNDS: {
            const territory = clonedeep(activeTerritory(state, crossSliceState));
            const rounds = {
                ...territory.rounds,
                ...fromGeoJson(action.payload, action.roundsSelected),
            };
            // TODO: narrow down to updated layers only
            const volumesMatrix = calcVolumesMatrix(
                [URBAN, RURAL, POSTIES, POBOXES, COUNTERS],
                territory.volumesMatrix,
                rounds,
                crossSliceState.roundsData
            );
            return replaceTerritory(state, {
                ...territory,
                rounds,
                volumesMatrix,
            });
        }
        case TERRITORY_NEIGHBOUR_ROUNDS: {
            const territory = clonedeep(activeTerritory(state, crossSliceState));
            const currentRounds = Object.keys(territory.rounds).map((id) => Number(id));
            const neighbourRounds = action.payload.filter(
                (feature) => !currentRounds.includes(feature.properties.walk_id)
            );
            const rounds = {
                ...territory.rounds,
                ...fromGeoJson(neighbourRounds, false),
            };
            return replaceTerritory(state, { ...territory, rounds });
        }
        case TERRITORY_ROUND_VISIBILITY_TOGGLE: {
            const territory = clonedeep(activeTerritory(state, crossSliceState));
            territory.rounds[action.payload].selected = !territory.rounds[action.payload].selected;
            const layer = territory.rounds[action.payload].layer;
            const volumesMatrix = calcVolumesMatrix(
                [layer],
                territory.volumesMatrix,
                territory.rounds,
                crossSliceState.roundsData
            );
            return replaceTerritory(state, { ...territory, volumesMatrix });
        }
        case TERRITORY_ROUNDS_APPLY_TARGETING: {
            const { baseRounds, targetedRounds } = action.payload;
            const territory = clonedeep(territoryById(state, crossSliceState.activeTerritory));
            Object.keys(territory.rounds).map((roundId) => {
                if (baseRounds.includes(parseInt(roundId))) {
                    territory.rounds[roundId].selected = targetedRounds.includes(parseInt(roundId));
                }
            });
            // TODO: narrow down to updated layers only
            const volumesMatrix = calcVolumesMatrix(
                [URBAN, RURAL, POSTIES, POBOXES, COUNTERS],
                territory.volumesMatrix,
                territory.rounds,
                crossSliceState.roundsData
            );
            return replaceTerritory(state, { ...territory, volumesMatrix });
        }
        case TERRITORY_FLUSH_ROUNDS:
            return replaceTerritory(state, {
                ...clonedeep(activeTerritory(state, crossSliceState)),
                rounds: {},
                volumesMatrix: { ...blankTerritory({}).volumesMatrix },
            });
        case TERRITORY_TOGGLE_EMPTY_WALKS_SELECTION: {
            const territory = clonedeep(activeTerritory(state, crossSliceState));
            toggleAllEmtySelection(territory, action.payload.selectAllEmpty, action.payload.emptyWalks);
            const volumesMatrix = calcVolumesMatrix(
                [URBAN, RURAL, POSTIES, POBOXES, COUNTERS],
                territory.volumesMatrix,
                territory.rounds,
                crossSliceState.roundsData
            );
            return replaceTerritory(state, { ...territory, volumesMatrix });
        }
        case TERRITORY_UPDATE_CUSTOMER_CHANGES: {
            const territory = clonedeep(activeTerritory(state, crossSliceState));
            const customerChanges = clonedeep(getTerritoryCustomerChanges(state, crossSliceState));

            return updateTerritoryCustomerChanges(state, territory, customerChanges, action.payload);
        }
        // end of active territory manipulations
        default:
            return state;
    }
};

const constrainVolumeSelections = (prev, payload) => {
    // INCLUSIVE, EXCLUSIVE, RESIDENTIAL, BUSINESS, FARMER, DAIRY, NEWSPAPER_*
    const { layer, slice } = payload;

    // protect from a broken state
    if (undefined == prev) prev = [];

    let slices = [];
    switch (layer) {
        case URBAN:
            prev.includes(slice) ? null : slices.push(slice);
            break;
        case POSTIES:
            if (BUSINESS == slice) {
                prev.includes(BUSINESS) ? null : slices.push(BUSINESS);
                prev.includes(INCLUSIVE) ? slices.push(INCLUSIVE) : null;
                prev.includes(EXCLUSIVE) ? slices.push(EXCLUSIVE) : null;
            } else {
                prev.includes(BUSINESS) ? slices.push(BUSINESS) : null;
            }
            if (INCLUSIVE == slice) {
                prev.includes(INCLUSIVE) ? null : slices.push(INCLUSIVE);
            }
            if (EXCLUSIVE == slice) {
                prev.includes(EXCLUSIVE) ? null : slices.push(EXCLUSIVE);
            }
            break;
        case RURAL:
            if (RESIDENTIAL == slice) {
                prev.includes(RESIDENTIAL) ? null : slices.push(RESIDENTIAL);
                prev.includes(FARMER) ? slices.push(FARMER) : null;
                prev.includes(DAIRY) ? slices.push(DAIRY) : null;
            }
            if (FARMER == slice) {
                prev.includes(FARMER) ? null : slices.push(FARMER);
                prev.includes(RESIDENTIAL) ? slices.push(RESIDENTIAL) : null;
            }
            if (DAIRY == slice) {
                prev.includes(DAIRY) ? null : slices.push(DAIRY);
                prev.includes(RESIDENTIAL) ? slices.push(RESIDENTIAL) : null;
            }
            if (NEWSPAPER_ALL == slice) {
                prev.includes(NEWSPAPER_ALL) ? null : slices.push(NEWSPAPER_ALL);
            }
            break;
        case POBOXES:
            slices = [...prev];
            [RESIDENTIAL, BUSINESS, FARMER].forEach((item) => {
                if (item == slice) {
                    const i = prev.indexOf(item);
                    i > -1 ? slices.splice(i, 1) : slices.push(item);
                }
            });
            break;
        case COUNTERS:
            slices = [...prev];
            [RESIDENTIAL, FARMER].forEach((item) => {
                if (item == slice) {
                    const i = prev.indexOf(item);
                    i > -1 ? slices.splice(i, 1) : slices.push(item);
                }
            });
            break;
    }

    return { [layer]: slices };
};

export const fromGeoJson = (features, selected = true) => {
    // payload gets geoJson formatted features, we need to transform it
    const roundsData = {};
    features.forEach((feature) => {
        const walk_id = feature.properties.walk_id;
        roundsData[walk_id] = { selected };
        switch (feature.properties.network) {
            case "Urban":
                roundsData[walk_id].layer = URBAN;
                break;
            case "Rural":
                roundsData[walk_id].layer = RURAL;
                break;
            case "Postie":
                roundsData[walk_id].layer = POSTIES;
                break;
            case "PO Box and Counters":
                roundsData[walk_id].layer = POBOXES;
                break;
        }
    });

    return roundsData;
};
