export const ROUNDS_NETWORK_TOGGLE = "ROUNDS_NETWORK_TOGGLE";
export const GMAP_BUILDING_MODE_TOGGLE = "GMAP_BUILDING_MODE_TOGGLE";
export const GMAP_DRAWING_MODE_TOGGLE = "GMAP_DRAWING_MODE_TOGGLE";
export const GMAP_RANGE_MODE_TOGGLE = "GMAP_RANGE_MODE_TOGGLE";
export const GMAP_RANGE_VALUE_UPDATE = "GMAP_RANGE_VALUE_UPDATE";

export const ROUNDS_FLUSH_DATA = "ROUNDS_FLUSH_DATA";
export const ROUNDS_LOAD_DATA = "ROUNDS_LOAD_DATA";
export const ROUNDS_LOAD_TO_VISIBLE = "ROUNDS_LOAD_TO_VISIBLE";
export const EMPTY_ROUNDS_UPDATE = "EMPTY_ROUND_UPDATE";

export const TERRITORIES_RESET = "TERRITORIES_RESET";

export const TERRITORY_ADD = "TERRITORY_ADD";
export const TERRITORY_UPDATE_LOCATION = "TERRITORY_UPDATE_LOCATION";
export const TERRITORY_UPDATE_VOLUME_SELECTIONS =
    "TERRITORY_UPDATE_VOLUME_SELECTIONS";
export const TERRITORY_UPDATE_NETWORK_SELECTIONS =
    "TERRITORY_UPDATE_NETWORK_SELECTIONS";
export const TERRITORY_UPDATE_ROUNDS = "TERRITORY_UPDATE_ROUNDS";
export const TERRITORY_UPDATE_ROUNDS_SELECTION =
    "TERRITORY_UPDATE_ROUNDS_SELECTION";
export const TERRITORY_NEIGHBOUR_ROUNDS = "TERRITORY_NEIGHBOUR_ROUNDS";
export const TERRITORY_ACTIVATE = "TERRITORY_ACTIVATE";
export const TERRITORY_DELETE = "TERRITORY_DELETE";
export const TERRITORY_SET_COLOUR = "TERRITORY_SET_COLOUR";
export const TERRITORY_ROUND_VISIBILITY_TOGGLE =
    "TERRITORY_ROUND_VISIBILITY_TOGGLE";
export const TERRITORY_ROUNDS_APPLY_TARGETING =
    "TERRITORY_ROUNDS_APPLY_TARGETING";
export const TERRITORY_FLUSH_ROUNDS = "TERRITORY_FLUSH_ROUNDS";
export const TERRITORY_LOAD = "TERRITORY_LOAD";
export const TERRITORY_COPY = "TERRITORY_COPY";
export const TERRITORY_UPDATE = "TERRITORY_UPDATE";
export const TERRITORY_ORDERING = "TERRITORY_ORDERING";
export const TERRITORY_PUSH = "TERRITORY_PUSH";
export const TERRITORY_UPDATE_CUSTOMER_CHANGES =
    "TERRITORY_UPDATE_CUSTOMER_CHANGES";

export const PRINTING_CONFIG_UPDATE = "PRINTING_CONFIG_UPDATE";
export const PRINTING_CONFIG_RESET = "PRINTING_CONFIG_RESET";

export const UPDATE_LODGEMENT_DETAILS = "UPDATE_LODGEMENT_DETAILS";

export const QUOTE_CONFIG_UPDATE = "QUOTE_CONFIG_UPDATE";
export const QUOTE_CONFIG_RESET = "QUOTE_CONFIG_RESET";

export const QUOTE_META_UPDATE = "QUOTE_META_UPDATE";
export const QUOTE_META_RESET = "QUOTE_META_RESET";

export const TERRITORIAL_FILTER_UPDATE = "TERRITORIAL_FILTER_UPDATE";
export const TERRITORIAL_FILTER_RESET = "TERRITORIAL_FILTER_RESET";

export const TARGETING_FILTER_ADD = "TARGETING_FILTER_ADD";
export const TARGETING_FILTERS_LOAD = "TARGETING_FILTERS_LOAD";
export const TARGETING_FILTER_UPDATE_BASE_ROUNDS =
    "TARGETING_FILTER_UPDATE_BASE_ROUNDS";
export const TARGETING_FILTER_UPDATE_VOLUME_LIMIT =
    "TARGETING_FILTER_UPDATE_VOLUME_LIMIT";
export const TARGETING_FILTER_GROUP_ADD = "TARGETING_FILTER_GROUP_ADD";
export const TARGETING_FILTER_GROUP_DELETE = "TARGETING_FILTER_GROUP_DELETE";
export const TARGETING_FILTER_GROUP_ACTIVATE =
    "TARGETING_FILTER_GROUP_ACTIVATE";
export const TARGETING_FILTER_GROUP_UPDATE_VARS =
    "TARGETING_FILTER_GROUP_UPDATE_VARS";
export const TARGETING_FILTER_GROUP_UPDATE_VARS_POOL =
    "TARGETING_FILTER_GROUP_UPDATE_VARS_POOL";
export const TARGETING_FILTER_GROUP_UPDATE_VALUES =
    "TARGETING_FILTER_GROUP_UPDATE_VALUES";
export const TARGETING_FILTER_GROUP_UPDATE_THRESHOLDS =
    "TARGETING_FILTER_GROUP_UPDATE_THRESHOLDS";
export const TARGETING_TERRITORY_RESET = "TARGETING_TERRITORY_RESET";

export const TOGGLE_SELECT_MODE = "TOGGLE_DRAWING_MODE";
export const TERRITORY_TOGGLE_EMPTY_WALKS_SELECTION =
    "TERRITORY_TOGGLE_EMPTY_WALKS_SELECTION";
export const ROUTER_UPDATE = "ROUTER_UPDATE";

export const UPDATE_URL_PARAMS = "UPDATE_URL_PARAMS";

export const TERRITORY_QUOTABLE_TOGGLE = "TERRITORY_QUOTABLE_TOGGLE";
export const QUOTE_SETTINGS_UPDATE = "QUOTE_SETTINGS_UPDATE";
export const UPDATE_UI_SETTINGS = "UPDATE_UI_SETTINGS";
export const NOOP = "NOOP";
