import { LitElement, html } from "lit-element";
import { repeat } from "lit-html/directives/repeat";
import { until } from "lit-html/directives/until";

import store from "../../store";
import "../progressSpinner.js";
import { printingSpecsUpdate } from "../../core/actions/actions";
import { selectPrintQuote } from "../../services/quoteCalcs";

export class PrintingQuotes extends LitElement {
    static get properties() {
        return {
            printer: { type: String },
            quotes: { type: Object },
        };
    }

    updated() {
        this.watchQuotes();
    }

    async watchQuotes() {
        const quotes = await this.quotes;
        // make sure we reset printer list if there's a printer not in the quote
        if (quotes) {
            const prices = quotes.prices || [];
            if (
                this.printer &&
                !prices.map((row) => row.printer).includes(this.printer)
            ) {
                // printer is set but not quotable
                store.dispatch(printingSpecsUpdate({ printer: null }));
            }
            // ensure quote is refreshed
            selectPrintQuote(prices);
        }
    }

    async togglePrinter(row) {
        if (row.printer == this.printer) {
            store.dispatch(
                printingSpecsUpdate({
                    printer: null,
                    cost: row.volume_cost ?? null,
                })
            );
        } else {
            store.dispatch(
                printingSpecsUpdate({
                    printer: row.printer,
                    cost: row.volume_cost ?? null,
                })
            );
        }
        const quoteData = await this.quotes;
        selectPrintQuote(quoteData.prices);
    }

    quotesTable() {
        return this.quotes.then((quotes) => {
            const rows = (quotes || {}).prices || [];
            return html`${repeat(
                rows,
                (row) => html`
                    <tr
                        @click=${() => this.togglePrinter(row)}
                        class="border ${row.printer == this.printer
                            ? "bg-sunrise"
                            : ""} cursor-pointer hover:bg-peppermint-50"
                    >
                        <td class="py-2 pr-2 text-right uppercase">
                            ${row.printer}
                        </td>
                        <td>
                            ${row.unit_cost
                                ? `$${Number.parseFloat(row.unit_cost).toFixed(
                                      2
                                  )}`
                                : "—"}
                        </td>
                        <td>
                            ${row.volume_cost
                                ? `$${Number.parseFloat(
                                      row.volume_cost
                                  ).toFixed(2)}`
                                : "—"}
                        </td>
                        <td>
                            ${row.folding
                                ? `$${Number.parseFloat(row.folding).toFixed(
                                      2
                                  )}`
                                : "—"}
                        </td>
                        <td>
                            ${row.freight
                                ? `$${Number.parseFloat(row.freight).toFixed(
                                      2
                                  )}`
                                : "—"}
                        </td>
                        <td>${row.margin ? `${row.margin}%` : "—"}</td>
                        <td>
                            ${row.cost_plus_margin
                                ? `$${Number.parseFloat(
                                      row.cost_plus_margin
                                  ).toFixed(2)}`
                                : "—"}
                        </td>
                        <td>
                            ${row.admin_fee
                                ? `$${Number.parseFloat(row.admin_fee).toFixed(
                                      2
                                  )}`
                                : "—"}
                        </td>
                        <td>
                            ${row.revenue
                                ? `$${Number.parseFloat(row.revenue).toFixed(
                                      2
                                  )}`
                                : "—"}
                        </td>
                    </tr>
                `
            )}`;
        });
    }

    progressBar() {
        return html`
            <tr>
                <td colspan="9" class="text-center py-4">
                    <rm-progress-spinner></rm-progress-spinner>
                </td>
            </tr>
        `;
    }

    render() {
        return html`
        <style>@import 'main.css';</style>
        <table class="text-center w-full">
            <colgroup>
                <col class="border"></col>
                <col class="border"></col>
                <col class="border"></col>
                <col class="border"></col>
                <col class="border"></col>
                <col class="border"></col>
                <col class="border"></col>
                <col class="border"></col>
                <col class="border"></col>
            </colgroup>
            <thead>
                <tr class="bg-peppermint-50">
                    <th class="py-2 text-sm uppercase font-medium">Printer</th>
                    <th class="w-24 text-sm uppercase font-medium">Unit</th>
                    <th class="w-24 text-sm uppercase font-medium">Cost</th>
                    <th class="w-24 text-sm uppercase font-medium">Folding</th>
                    <th class="w-24 text-sm uppercase font-medium">Freight</th>
                    <th class="w-24 text-sm uppercase font-medium">Margin</th>
                    <th class="w-32 text-sm uppercase font-medium">Cost+Margin</th>
                    <th class="w-24 text-sm uppercase font-medium">Admin</th>
                    <th class="w-24 text-sm uppercase font-medium">Revenue</th>
                </tr>
            </thead>
            <tbody>
                ${until(this.quotesTable(), this.progressBar())}
            </tbody>
        </table>
        `;
    }
}

global.customElements.define("rm-printing-quotes", PrintingQuotes);
