import { LitElement } from "lit-element";
import { html, nothing } from "lit-html";
import { subscribe, unsubscribe } from "redux-subscribe";

import store from "../store";
import { quoteDetailsUpdate, noop } from "../core/actions/actions";
import { totalCost } from "../services/costCalcs";

import { GMAP_ROUNDS_LAYERS } from "../const/dataLayers";
const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS } = GMAP_ROUNDS_LAYERS;

import { territoriesTotal } from "../services/volumes";
import { numberFormat } from "../services/numberHelper";

import { openDialog } from "./dialog";
import settingIcon from "./svg/setting";

class QuoteSummaryTable extends LitElement {
    constructor() {
        super();
        this.quote = store.getState().quoteDetails;
        this.quoteName = store.getState().quoteMeta.name;

        store.dispatch(
            subscribe("quoteDetails", "updateQuoteSummary", (data) => {
                this.quote = data.next;
                this.totalMailers = territoriesTotal(this.quote.subtotalMatrix);
                return noop("updateQuoteSummary");
            })
        );

        store.dispatch(
            subscribe("quoteMeta", "updateAndRenderSettingButton", (data) => {
                this.quoteName = data.next.name;
                return noop(`updateAndRenderSettingButton`);
            })
        );
    }

    static get properties() {
        return {
            totalMailers: { type: Number },
            hideZeroLines: { type: Boolean },
            interactive: { type: Boolean },
            enableGst: { type: Boolean },
            quote: { type: Object },
            quoteName: { type: Object },
        };
    }

    disconnectedCallback() {
        store.dispatch(unsubscribe("quoteDetails", "updateQuoteSummary"));
        store.dispatch(
            unsubscribe("quoteMeta", "updateAndRenderSettingButton")
        );
    }

    toggleLine(line) {
        if (this.interactive) {
            store.dispatch(quoteDetailsUpdate({ [line]: !this.quote[line] }));
        }
    }

    channelHidden(value) {
        return value > 0 ? "bg-sunrise" : "";
    }

    lineEnabled(line) {
        return this.quote[line] ? "" : "text-grey";
    }

    openSettingDialog() {
        openDialog("rm-quote-setting");
    }

    render() {
        return html`
        <style>@import 'main.css';</style>
        <table class="w-full select-none">
        <colgroup>
            <col class="border-r border-dark-grey"></col>
            <col class="border-r border-dark-grey"></col>
            <col class="border-r border-dark-grey"></col>
            <col class="border-r border-dark-grey"></col>
            <col></col>
        </colgroup>
        <tr class="bg-dark-greyx">
            <th class="w-16 text-left">
                <button title="Quote settings" @click="${
                    this.openSettingDialog
                }"
                    class="px-2 pb-1 rounded-sm disabled:pointer-events-none disabled:bg-grey disabled:border-grey hover:bg-peppermint-50 border border-peppermint items-center ${
                        this.interactive ? "" : "hidden"
                    }" ?disabled=${!this.quoteName}>
                    ${settingIcon}
                </button>
            </th>
            <th class="text-sm uppercase font-medium ${this.channelHidden(
                this.quote.subtotalMatrix[URBAN]
            )}">Reach</th>
            <th class="text-sm uppercase font-medium ${this.channelHidden(
                this.quote.subtotalMatrix[POSTIES]
            )}">Postie</th>
            <th class="text-sm uppercase font-medium ${this.channelHidden(
                this.quote.subtotalMatrix[RURAL]
            )}">Rural</th>
            <th class="text-sm uppercase font-medium ${this.channelHidden(
                this.quote.subtotalMatrix[POBOXES] +
                    (this.quote.subtotalMatrix[COUNTERS] || 0)
            )}">Boxes</th>
        </tr>
        <tr class="border-t border-b-2 border-dark-grey">
            <td class="text-sm text-left">Subtotal</td>
            <td class="text-center text-sm">${numberFormat(
                this.quote.subtotalMatrix[URBAN],
                { minimumFractionDigits: 0 }
            )}</td>
            <td class="text-center text-sm">${numberFormat(
                this.quote.subtotalMatrix[POSTIES],
                { minimumFractionDigits: 0 }
            )}</td>
            <td class="text-center text-sm">${numberFormat(
                this.quote.subtotalMatrix[RURAL],
                { minimumFractionDigits: 0 }
            )}</td>
            <td class="text-center text-sm">${numberFormat(
                this.quote.subtotalMatrix[POBOXES] +
                    (this.quote.subtotalMatrix[COUNTERS] || 0),
                { minimumFractionDigits: 0 }
            )}</td>
        </tr>
        <tr class="border-t border-b-2 border-dark-grey">
            <td class="text-sm text-left">Volume</td>
            <td class="text-center text-sm">$${numberFormat(
                this.quote.distribution[URBAN].volume
            )}</td>
            <td class="text-center text-sm">$${numberFormat(
                this.quote.distribution[POSTIES].volume
            )}</td>
            <td class="text-center text-sm">$${numberFormat(
                this.quote.distribution[RURAL].volume
            )}</td>
            <td class="text-center text-sm">$${numberFormat(
                this.quote.distribution[POBOXES].volume +
                    ((this.quote.distribution[COUNTERS] || {}).volume || 0)
            )}</td>
        </tr>
        <tr class="border-t border-b-2 border-dark-grey">
            <td class="text-sm text-left">Freight</td>
            <td class="text-center text-sm">$${numberFormat(
                this.quote.distribution[URBAN].freight?.amount || 0
            )}</td>
            <td class="text-center text-sm">$${numberFormat(
                this.quote.distribution[POSTIES].freight?.amount || 0
            )}</td>
            <td class="text-center text-sm">$${numberFormat(
                this.quote.distribution[RURAL].freight?.amount || 0
            )}</td>
            <td class="text-center text-sm">$${numberFormat(
                (this.quote.distribution[POBOXES].freight?.amount || 0) +
                    ((this.quote.distribution[COUNTERS] || {}).freight
                        ?.amount || 0)
            )}</td>
        </tr>
        <tr class="border-t border-b-2 border-dark-grey">
            <td class="text-sm text-left">Freight Surcharge</td>
            <td class="text-center text-sm">$${numberFormat(
                this.quote.distribution[URBAN].freight?.vfr || 0
            )}</td>
            <td class="text-center text-sm">$${numberFormat(
                this.quote.distribution[POSTIES].freight?.vfr || 0
            )}</td>
            <td class="text-center text-sm">$${numberFormat(
                this.quote.distribution[RURAL].freight?.vfr || 0
            )}</td>
            <td class="text-center text-sm">$${numberFormat(
                (this.quote.distribution[POBOXES].freight?.vfr || 0) +
                    ((this.quote.distribution[COUNTERS] || {}).freight?.vfr ||
                        0)
            )}</td>
        </tr>
        </table>

        <table class="w-full select-none">
        <tr>
            <td class="text-sm text-left">Mailers volume</td>
            <td class="pr-1 text-sm text-right">
            ${numberFormat(this.totalMailers, { minimumFractionDigits: 0 })}
            </td>
        </tr>
        <tr class="border-t-2 border-dark-grey ${
            this.quote.extraCopies > 0 ? "" : "hidden"
        }">
            <td class="text-sm text-left">Extra copies volume</td>
            <td colspan="4" class="pr-1 text-right text-sm">
            ${numberFormat(this.quote.extraCopies, {
                minimumFractionDigits: 0,
            })}
            </td>
        </tr>
        <tr class="border-t-2 border-dark-grey">
            <td class="text-sm text-left">Total volume cost</td>
            <td class="pr-1 text-right text-sm">
                ${
                    this.quote.volumeCost > 0
                        ? html`$${numberFormat(this.quote.volumeCost)}`
                        : this.totalMailers > 0
                        ? "POA"
                        : "$0.00"
                }
            </td>
        </tr>
        ${
            this.quote.apartmentsPremium > 0 || !this.hideZeroLines
                ? html` <tr class="border-t border-dark-grey">
                      <td class="text-sm text-left">Apartment fee</td>
                      <td class="pr-1 text-right text-sm">
                          $${numberFormat(this.quote.apartmentsPremium)}
                      </td>
                  </tr>`
                : nothing
        }
        ${
            this.quote.dairyFarmerPremium > 0 || !this.hideZeroLines
                ? html`<tr class="border-t border-dark-grey">
                      <td class="text-sm text-left">Dairy fee</td>
                      <td class="pr-1 text-right text-sm">
                          $${numberFormat(this.quote.dairyFarmerPremium)}
                      </td>
                  </tr>`
                : nothing
        }
        <tr class="border-t border-dark-grey">
            <td class="text-sm text-left">Network fee</td>
            <td class="pr-1 text-right text-sm">
                $${numberFormat(this.quote.networkManagementCost)}
            </td>
        </tr>
        ${
            this.quote.targetingCost > 0 || !this.hideZeroLines
                ? html`<tr class="border-t border-dark-grey">
                      <td class="text-sm text-left">
                          ${this.quote.targeted
                              ? html`
                                    <span
                                        class="text-sm text-left text-blue font-bold"
                                        >Demographic targeting fee</span
                                    >
                                `
                              : html`Geographic targeting fee`}
                      </td>
                      <td class="pr-1 text-right text-sm">
                          $${numberFormat(this.quote.targetingCost)}
                      </td>
                  </tr>`
                : nothing
        }
        ${
            !this.lineEnabled("hasFreight") ||
            !this.hideZeroLines ||
            !IS_CLIENT_APP
                ? html` <tr
                      @click=${() => this.toggleLine("hasFreight")}
                      class="border-t border-dark-grey ${IS_CLIENT_APP
                          ? ""
                          : "cursor-pointer"}"
                      title="Click to toggle"
                  >
                      <td class="text-sm text-left">Total freight cost</td>
                      <td
                          class="pr-1 text-right text-sm ${this.lineEnabled(
                              "hasFreight"
                          )}"
                      >
                          $${numberFormat(this.quote.freightCost)}
                      </td>
                  </tr>`
                : nothing
        }
        ${
            !this.lineEnabled("hasFreight") &&
            (this.quote.vfrCost > 0 || !this.hideZeroLines)
                ? html`<tr class="border-t border-dark-grey">
                      <td class="text-sm text-left">
                          Total freight surcharge<br /><span
                              class="text-sm text-left italic pl-2"
                              >${numberFormat(
                                  (this.quote.vfrCost /
                                      this.quote.freightCost) *
                                      100
                              )}
                              % of total freight</span
                          >
                      </td>
                      <td class="pr-1 text-right text-sm">
                          $${numberFormat(this.quote.vfrCost)}
                      </td>
                  </tr>`
                : nothing
        }
        ${
            this.quote.minAmtAdjustment > 0 || !this.hideZeroLines
                ? html` <tr class="border-t border-dark-grey">
                      <td class="text-sm text-left">Minimum Fee</td>
                      <td class="pr-1 text-right text-sm">
                          $${numberFormat(this.quote.minAmtAdjustment)}
                      </td>
                  </tr>`
                : nothing
        }
        ${
            !this.lineEnabled("printingLine") ||
            !this.hideZeroLines ||
            !IS_CLIENT_APP
                ? html` <tr
                      @click=${() => this.toggleLine("printingLine")}
                      class="border-t border-dark-grey cursor-pointer"
                      title="Click to toggle"
                  >
                      <td class="text-sm text-left">Print cost</td>
                      <td
                          class="pr-1 text-right text-sm ${this.lineEnabled(
                              "printingLine"
                          )}"
                      >
                          ${this.quote.printCost > 0
                              ? html`$${numberFormat(this.quote.printCost)}`
                              : this.totalMailers > 0
                              ? "POA"
                              : "$0.00"}
                      </td>
                  </tr>`
                : nothing
        }
        <tr class="border-t border-dark-grey ${
            this.quote.extraCopiesFee > 0 ? "" : "hidden"
        }">
            <td class="text-sm text-left">Extra copies fee</td>
            <td colspan="4" class="pr-1 text-right text-sm">$${numberFormat(
                this.quote.extraCopiesFee
            )}</td>
        </tr>
        ${
            this.quote.adminFee > 0 || !this.hideZeroLines
                ? html`<tr class="border-t border-dark-grey">
                      <td class="text-sm text-left">Administration fee</td>
                      <td class="pr-1 text-right text-sm">
                          $${numberFormat(this.quote.adminFee)}
                      </td>
                  </tr>`
                : nothing
        }
       
       ${
           this.quote.discount > 0 || !this.hideZeroLines
               ? html`<tr class="border-t border-dark-grey">
                     <td class="text-sm text-left">Discount</td>
                     <td class="pr-1 text-right text-sm">
                         -$${numberFormat(this.quote.discount)}
                     </td>
                 </tr>`
               : nothing
       }

        <tr class="border-t border-dark-grey">
            <td class="text-sm text-left font-medium">Subtotal</td>
            <td colspan="4" class="pr-1 text-right text-sm font-medium">$${numberFormat(
                totalCost()
            )}</td>
        </tr>
        ${
            this.enableGst
                ? html` <tr class="border-t border-dark-grey">
                          <td class="text-sm text-left font-medium">GST 15%</td>
                          <td
                              colspan="4"
                              class="pr-1 text-right text-sm font-medium"
                          >
                              $${numberFormat(totalCost() * 0.15)}
                          </td>
                      </tr>
                      <tr class="border-t border-dark-grey">
                          <td class="text-sm text-left font-medium">Total</td>
                          <td
                              colspan="4"
                              class="pr-1 text-right text-sm font-medium"
                          >
                              $${numberFormat(totalCost() * 1.15)}
                          </td>
                      </tr>`
                : nothing
        }
        </table>
        `;
    }
}

global.customElements.define("rm-quote-summary-table", QuoteSummaryTable);
