import { OCI_GATEWAY_URL } from "../../config/local";
import store from "../../store";
import { formatDateForApi } from "../dateHelper";
import { plainWarning } from "../notifications/apiWrapper";
import { apiErrorEvent } from "./customEvents";

export const freightQuote = async (network, volume, weightRange) => {
    if (volume === 0) return;
    let networkCode;

    // translate network to Liberty naming convention
    if (["POSTIES", "RURAL", "POBOXES", "COUNTERS"].includes(network)) {
        networkCode = "P";
    } else if ("URBAN" === network) {
        networkCode = "S";
    } else {
        console.log(`Unknown freight API network: ${network}`);
        return;
    }

    const customerCmsCode = store.getState().quoteMeta.customerCmsCode;
    const campaignStartDate = store.getState().quoteMeta.campaignStartDate;
    const campaignStartDateFormatted = formatDateForApi(campaignStartDate);

    const apiUrl = new URL(`${OCI_GATEWAY_URL}/calculator/freight`);

    apiUrl.searchParams.append("network", networkCode);
    apiUrl.searchParams.append("qty", volume);
    customerCmsCode
        ? apiUrl.searchParams.append("cms_acct_code", customerCmsCode)
        : null;
    campaignStartDate
        ? apiUrl.searchParams.append("start_date", campaignStartDateFormatted)
        : null;

    apiUrl.searchParams.append("weight_range", Number(weightRange));

    try {
        const response = await fetch(apiUrl);

        if (response.ok) {
            const { freightcalc } = await response.json();
            return {
                amount: Number(freightcalc.amount || 0),
                vfr: Number(freightcalc.vfr || 0),
            };
        }

        document.dispatchEvent(
            apiErrorEvent({
                errorMsg: "Freight calulation failed, please try again.",
            })
        );
    } catch (error) {
        plainWarning("Network issues!", error);
        console.log(error);
    }
};
