import {
    TERRITORY_ACTIVATE,
    TERRITORY_ADD,
    TERRITORIES_RESET,
    TERRITORY_PUSH,
} from "../actions/actionTypes";

export const activeTerritory = (state = null, action) => {
    switch (action.type) {
        case TERRITORY_ADD:
        case TERRITORY_ACTIVATE:
        case TERRITORY_PUSH:
        case TERRITORIES_RESET:
            return action.payload;
        default:
            return state;
    }
};
