import { OCI_GATEWAY_URL } from "../../config/local";
import { plainWarning } from "../notifications/apiWrapper";
import { apiErrorEvent } from "./customEvents";
import { formatDateForApi } from "../dateHelper";

/**
 * NOTE: It's currently set NOT to accept any client side margin value and let calculator to handle this with its defaults.
 * To enable margin it has to be sent as `&margin=${config.margin}`
 *
 * @param {Object} config Printing configuration
 * @param {Number} volume Amount to send for quoting
 */
export const printingQuote = async (config, volume) => {
    const apiUrl = new URL(`${OCI_GATEWAY_URL}/calculator/print`);

    const printJobStartDateFormatted = formatDateForApi(
        config.printJobStartDate
    );

    apiUrl.searchParams.append("top", config?.top ?? 5);
    apiUrl.searchParams.append("printer", config.printer);
    apiUrl.searchParams.append("paper_size", config.paperSize);
    apiUrl.searchParams.append("pagination", config.pagination);
    apiUrl.searchParams.append("colours", config.colours);
    apiUrl.searchParams.append("gsm", config.gsm);
    apiUrl.searchParams.append("qty", volume);
    config.printJobStartDate
        ? apiUrl.searchParams.append("start_date", printJobStartDateFormatted)
        : null;

    try {
        const response = await fetch(apiUrl);

        if (response.ok) {
            const { printcalc } = await response.json();
            return printcalc;
        }

        document.dispatchEvent(
            apiErrorEvent({
                errorMsg: "Print calulation failed, please try again.",
            })
        );
    } catch (error) {
        plainWarning("Network issues!", error);
        console.log(error);
    }
};
