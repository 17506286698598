import { LitElement, html } from "lit-element";
import { nothing } from "lit-html";
import { subscribe, unsubscribe } from "redux-subscribe";
import logoImage from "../assets/images/Reach-web-logo.png";
import { URL_MODE_PARAMS } from "../const/urlParams";
import { noop } from "../core/actions/actions";
import { isUrlContainMode } from "../services/urlHeplers";
import store from "../store";
import "./blockingErrorModal";
import "./dialogs/saveCustomerChangesDialogButton";
import "./quotePresenter/quotePanel";

class ClientWrapper extends LitElement {
    constructor() {
        super();
    }
    /**
     * Render template into light DOM.
     * TODO: could be reviewed once all components are migrated to lit-element
     */
    createRenderRoot() {
        return this;
    }

    static get properties() {
        return {
            renderPanel: { type: Boolean },
            renderCustomerChangesButton: { type: Boolean },
            isTerritoryVolumeViewable: { type: Boolean },
        };
    }

    firstUpdated() {
        store.dispatch(
            subscribe("urlParams", "urlParamsUpdate", (_) => {
                this.updateRenderPanel();
                return noop("urlParamsUpdate");
            })
        );
        store.dispatch(
            subscribe("settings", "settingsUpdate", (data) => {
                this.renderCustomerChangesButton = data.next.isQuoteEditable;
                this.isTerritoryVolumeViewable =
                    data.next.isTerritoryVolumeViewable || false;
                return noop("settingsUpdate");
            })
        );
    }

    // the quoting panel can be hidden by providing "&mode=mapOnly" parameter to client URL
    // useful as "mobile friendly" link use case
    updateRenderPanel() {
        this.renderPanel = !isUrlContainMode(URL_MODE_PARAMS.MAP_ONLY);
    }

    disconnectedCallback() {
        store.dispatch(unsubscribe("urlParams", "urlParamsUpdate"));
        store.dispatch(unsubscribe("settings", "settingsUpdate"));
    }

    render() {
        return html`
            <nav class="nav-top-bar">
                <div class="h-12">
                    <img class="h-8 my-2" src="${logoImage}" />
                </div>
                <div class="flex flex-row items-center">
                    ${this.renderCustomerChangesButton
                        ? html`<rm-save-customer-changes-dialog-button></rm-save-customer-changes-dialog-button>`
                        : nothing}
                </div>
            </nav>
            ${this.renderPanel
                ? html`<rm-presenter-quote-panel
                      class="hidden"
                      .isTerritoryVolumeViewable=${this
                          .isTerritoryVolumeViewable}
                  ></rm-presenter-quote-panel>`
                : nothing}

            <dialog
                class="xl:w-3/5 lg:w-3/4 w-full h-full85 max-h-full85 m-auto bg-white border border-matte-blue rounded-sm px-4 pt-2"
            ></dialog>
            <rm-blocking-error-modal class="hidden"></rm-blocking-error-modal>
        `;
    }
}

global.customElements.define("rm-client-wrapper", ClientWrapper);
