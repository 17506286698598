import { UPDATE_UI_SETTINGS } from "../actions/actionTypes";

export const uiSettings = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_UI_SETTINGS: {
            return { ...action.payload };
        }
        default:
            return state;
    }
};
