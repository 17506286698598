import { html } from "lit-element";
import { nothing } from "lit-html";
import clonedeep from "lodash-es/cloneDeep";
import {
    StatefulButtonBase,
    PROGRESS,
    ACTIVE,
} from "../base/statefulButtonBase";
import "../progressSpinner";
import { clientInfoNoDelayEvent } from "../toast";

import { save } from "../../services/httpApis/saveQuote";
import { customerChangeNotification } from "../../services/notifications/email";
import store from "../../store";

export const clientCustomerChangesSaveEvent = new Event(
    "client:customer:changes:save"
);

class SaveCustomerChangesButton extends StatefulButtonBase {
    constructor() {
        super();
    }

    async notification() {
        const quote = clonedeep(store.getState());

        if (quote.quoteMeta.id) {
            await customerChangeNotification(quote);
        }
    }

    async save() {
        this.status = PROGRESS;

        await save(false);
        await this.notification();
        this.dispatchEvent(clientCustomerChangesSaveEvent);
        this.dispatchEvent(
            clientInfoNoDelayEvent({
                message: "Your proposed changes have been submitted.",
            })
        );
        this.status = ACTIVE;
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-row items-center">
                <rm-progress-spinner
                    class="mr-2 ${PROGRESS === this.status
                        ? nothing
                        : "hidden"}"
                ></rm-progress-spinner>
                <button
                    @click=${() => this.save()}
                    title="Propose Changes"
                    class="${this.itemCss()}"
                >
                    <span class="text-sm font-medium uppercase"
                        >Propose Changes</span
                    >
                </button>
            </div>
        `;
    }
}

global.customElements.define(
    "rm-save-customer-changes-button",
    SaveCustomerChangesButton
);
