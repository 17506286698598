import clonedeep from "lodash-es/cloneDeep";

import {
    EMPTY_WALK_BUTTON,
    GMAP_DRAWING_MODE,
    BULK_DESELECT,
} from "../../const/drawingMode";
import {
    GMAP_DRAWING_MODE_TOGGLE,
    TOGGLE_SELECT_MODE,
} from "../actions/actionTypes";

const initialState = {
    [GMAP_DRAWING_MODE.NONE]: true,
    [GMAP_DRAWING_MODE.CIRCLE]: false,
    [GMAP_DRAWING_MODE.POLYGON]: false,
    [GMAP_DRAWING_MODE.INFO]: false,
    [EMPTY_WALK_BUTTON]: false,
    [BULK_DESELECT]: false,
};

const isSelectMode = (mode) => {
    return [EMPTY_WALK_BUTTON, BULK_DESELECT].includes(mode);
};

export const mapDrawingMode = (state = initialState, action) => {
    switch (action.type) {
        case GMAP_DRAWING_MODE_TOGGLE: {
            const next = clonedeep(state);
            Object.keys(next).forEach((key) =>
                isSelectMode(key)
                    ? (next[key] = next[key])
                    : (next[key] = false)
            );
            return { ...next, ...action.payload };
        }
        case TOGGLE_SELECT_MODE: {
            const next = clonedeep(state);
            next[action.payload.mode] =
                undefined === action.payload.value
                    ? !next[action.payload.mode]
                    : action.payload.value;
            return next;
        }
        default:
            return state;
    }
};
