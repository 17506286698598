import store from "../../store";
import { subscribe } from "redux-subscribe";
import { noop } from "../../core/actions/actions";

import { OCI_GATEWAY_URL, URL_GEO_API } from "../../config/local";
import { reduxActiveRoundsUpdateEvent } from "../../const/dataLayers";
import {
    emptyWalksUpdate,
    roundsLoadData,
} from "../../core/actions/roundActions";
import {
    territoryNeighbourRounds,
    territoryUpdateRounds,
} from "../../core/actions/territoryActions";
import { initialState as defaultLayers } from "../../core/reducers/mapLayersReducer";
import { isTerritoryTargeted } from "../../core/selectors/targetingSelector";
import { getActiveTerritory } from "../../core/selectors/territorySelector";
import { targetActiveTerritory } from "./targetingQuery";
import { formatDateForApi } from "../dateHelper";

import {
    plainWarning,
    TAG_NETWORK,
    TAG_ROUNDS_LOOKUP,
} from "../notifications/apiWrapper";

import { getIdToken } from "firebase/auth";
import { BULK_DESELECT } from "../../const/drawingMode";
import { GMAP_RANGE_MODE } from "../../const/rangeMode";
import { getRoundOnSelectedNetwork } from "../../core/selectors/roundSelector";
import { auth } from "../../services/firebase/init";

// TODO: dispatched action payload is malformed when server sends unexpected format

// subscribe to campaign start date changes so we can update the API calls
let campaignStartDateOn = false;
store.dispatch(
    subscribe("quoteMeta.campaignStartDate", "syncCampaignDate", () => {
        campaignStartDateOn = !!store
            .getState()
            .quoteMeta.campaignStartDate?.trim();

        return noop(`syncCampaignDate`);
    })
);

export const getRoundsByCircle = async (circle) => {
    let data = { features: [] };

    const apiUrl = new URL(`${URL_GEO_API}/roundsByCircle`);
    apiUrl.searchParams.append("centerX", circle.getCenter().lng());
    apiUrl.searchParams.append("centerY", circle.getCenter().lat());
    apiUrl.searchParams.append("radius", circle.getRadius());
    campaignStartDateOn
        ? apiUrl.searchParams.append("start_date", campaignStartDate(apiUrl))
        : null;

    try {
        const response = await fetch(apiUrl);
        if (response.ok) {
            data = await response.json();
        } else {
            plainWarning(
                "Circular selection failed!",
                "Please try again.",
                TAG_ROUNDS_LOOKUP
            );
            return [];
        }
    } catch (error) {
        plainWarning("Network issues!", error, TAG_NETWORK);
        return [];
    }

    store.dispatch(roundsLoadData(data.features));
    store.dispatch(territoryUpdateRounds(data.features));
    document.dispatchEvent(reduxActiveRoundsUpdateEvent);

    isTerritoryTargeted(getActiveTerritory().id)
        ? targetActiveTerritory()
        : null;

    return collectRoundIds(data.features);
};

/**
 *
 * @param {any} center Location object with lng() and lat() methods on it
 * @param {number} range Distance value in meters or in seconds
 * @param {TIME|DISTANCE} mode Value type
 */
export const getRoundsByRange = async (
    center,
    range,
    mode = GMAP_RANGE_MODE.TIME
) => {
    let data = { features: [] };

    try {
        const apiUrl = new URL(`${URL_GEO_API}/roundsByIsochrone`);
        apiUrl.searchParams.append("centerX", center.lng());
        apiUrl.searchParams.append("centerY", center.lat());
        apiUrl.searchParams.append(mode.toLowerCase(), range);
        campaignStartDateOn
            ? apiUrl.searchParams.append(
                  "start_date",
                  campaignStartDate(apiUrl)
              )
            : null;

        const response = await fetch(apiUrl);
        if (response.ok) {
            data = await response.json();
        } else {
            plainWarning(
                "Range selection failed!",
                "Please try again.",
                TAG_ROUNDS_LOOKUP
            );
            return [];
        }
    } catch (error) {
        plainWarning("Network issues!", error, TAG_NETWORK);
        return [];
    }

    store.dispatch(roundsLoadData(data.features));
    store.dispatch(territoryUpdateRounds(data.features));
    document.dispatchEvent(reduxActiveRoundsUpdateEvent);

    isTerritoryTargeted(getActiveTerritory().id)
        ? targetActiveTerritory()
        : null;

    return collectRoundIds(data.features);
};

export const getRoundsByPolygon = async (polygon) => {
    let data = { features: [] };
    // a single polygon supported here
    // flip lat/lng to PostGIS lng/lat notation
    const path = polygon
        .getPath()
        .getArray()
        .map((latLng) => `${latLng.lng()} ${latLng.lat()}`);
    try {
        const apiUrl = new URL(`${URL_GEO_API}/roundsByPolygon`);
        campaignStartDateOn
            ? apiUrl.searchParams.append(
                  "start_date",
                  campaignStartDate(apiUrl)
              )
            : null;

        const response = await fetch(apiUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(path),
        });

        if (response.ok) {
            data = await response.json();
        } else {
            plainWarning(
                "Polygon selection failed!",
                "Please try again.",
                TAG_ROUNDS_LOOKUP
            );
            return [];
        }
    } catch (error) {
        plainWarning("Network issues!", error, TAG_NETWORK);
        return [];
    }

    store.dispatch(roundsLoadData(data.features));
    store.dispatch(
        territoryUpdateRounds(
            data.features,
            !store.getState().mapDrawingMode[BULK_DESELECT]
        )
    );
    document.dispatchEvent(reduxActiveRoundsUpdateEvent);

    isTerritoryTargeted(getActiveTerritory().id)
        ? targetActiveTerritory()
        : null;

    return collectRoundIds(data.features);
};

/**
 * This method is used in both importing rounds into current territory and
 * loading rounds data into state w/o territories updates
 * Targeting call if applicable should happen in import component to respect
 * its logic.
 *
 * @param {Array} roundsList
 * @param {Boolean} loadOnly Indicate if territory should not be updated
 * @returns {Array} List of round ids that were loaded
 */
export const getRoundsByList = async (roundsList, loadOnly = false) => {
    let data = { features: [] };
    try {
        const apiUrl = new URL(`${URL_GEO_API}/roundsByList`);
        campaignStartDateOn
            ? apiUrl.searchParams.append(
                  "start_date",
                  campaignStartDate(apiUrl)
              )
            : null;

        const response = await fetch(apiUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(roundsList),
        });

        if (response.ok) {
            data = await response.json();
        } else {
            plainWarning(
                "Round list lookup failed!",
                "Make sure the list is correct.",
                TAG_ROUNDS_LOOKUP
            );
            return [];
        }
    } catch (error) {
        plainWarning("Network issues!", error, TAG_NETWORK);
        return [];
    }

    store.dispatch(roundsLoadData(data.features));
    if (!loadOnly) {
        store.dispatch(territoryUpdateRounds(data.features));
        document.dispatchEvent(reduxActiveRoundsUpdateEvent);
    }

    return collectRoundIds(data.features);
};

export const roundsByNeighbour = async (roundsList) => {
    let data = { features: [] };
    try {
        const apiUrl = new URL(`${URL_GEO_API}/roundsByNeighbour`);
        campaignStartDateOn
            ? apiUrl.searchParams.append(
                  "start_date",
                  campaignStartDate(apiUrl)
              )
            : null;

        const response = await fetch(apiUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(roundsList),
        });

        if (response.ok) {
            data = await response.json();
        } else {
            plainWarning(
                "Neighbour lookup failed!",
                "Please try again.",
                TAG_ROUNDS_LOOKUP
            );
            return [];
        }
    } catch (error) {
        plainWarning("Network issues!", error, TAG_NETWORK);
        return [];
    }

    store.dispatch(roundsLoadData(data.features));
    store.dispatch(territoryNeighbourRounds(data.features));
    document.dispatchEvent(reduxActiveRoundsUpdateEvent);

    return collectRoundIds(data.features);
};

export const getRoundsBoundingBox = async (roundsList) => {
    let data = null;
    try {
        const apiUrl = new URL(`${URL_GEO_API}/roundsBoundingBox`);
        campaignStartDateOn
            ? apiUrl.searchParams.append(
                  "start_date",
                  campaignStartDate(apiUrl)
              )
            : null;

        const response = await fetch(apiUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(roundsList),
        });

        if (response.ok) {
            data = await response.json();
            if (Object.values(data).includes(null)) {
                data = null;
            }
        } else {
            plainWarning(
                "Bounding box unavailable!",
                "Please try again if necessary.",
                TAG_ROUNDS_LOOKUP
            );
            return null;
        }
    } catch (error) {
        plainWarning("Network issues!", error, TAG_NETWORK);
        return null;
    }
    return data;
};

export const getRoundsByTerritory = async (territoryFilter) => {
    let data = { features: [] };
    try {
        const apiUrl = new URL(`${URL_GEO_API}/roundsByTerritory`);
        campaignStartDateOn
            ? apiUrl.searchParams.append(
                  "start_date",
                  campaignStartDate(apiUrl)
              )
            : null;

        const response = await fetch(apiUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(territoryFilter),
        });

        if (response.ok) {
            data = await response.json();
        } else {
            plainWarning(
                "Territory lookup failed!",
                "Please try again.",
                TAG_ROUNDS_LOOKUP
            );
            return [];
        }
    } catch (error) {
        plainWarning("Network issues!", error, TAG_NETWORK);
        return [];
    }

    store.dispatch(roundsLoadData(data.features));
    store.dispatch(territoryUpdateRounds(data.features));
    document.dispatchEvent(reduxActiveRoundsUpdateEvent);

    isTerritoryTargeted(getActiveTerritory().id)
        ? targetActiveTerritory()
        : null;

    return collectRoundIds(data.features);
};

export const getRoundsByBoundary = async (boundaryFilter) => {
    let data = { features: [] };
    // respect only the first non-empty filter
    const filter = Object.keys(boundaryFilter).find(
        (item) => boundaryFilter[item].length
    );
    if (filter) {
        try {
            const apiUrl = new URL(`${URL_GEO_API}/roundsByBoundary/${filter}`);
            campaignStartDateOn
                ? apiUrl.searchParams.append(
                      "start_date",
                      campaignStartDate(apiUrl)
                  )
                : null;

            const response = await fetch(apiUrl, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(boundaryFilter[filter]),
            });

            if (response.ok) {
                data = await response.json();
            } else {
                plainWarning(
                    "Boundaries lookup failed!",
                    "Please try again.",
                    TAG_ROUNDS_LOOKUP
                );
                return [];
            }
        } catch (error) {
            plainWarning("Network issues!", error, TAG_NETWORK);
            return [];
        }

        store.dispatch(roundsLoadData(data.features));
        store.dispatch(territoryUpdateRounds(data.features));
        document.dispatchEvent(reduxActiveRoundsUpdateEvent);

        isTerritoryTargeted(getActiveTerritory().id)
            ? targetActiveTerritory()
            : null;

        return collectRoundIds(data.features);
    }
    return [];
};

export const getRoundsByPostCodes = async (postCodes) => {
    let data = { features: [] };
    try {
        const apiUrl = new URL(`${URL_GEO_API}/roundsByPostCodes`);
        campaignStartDateOn
            ? apiUrl.searchParams.append(
                  "start_date",
                  campaignStartDate(apiUrl)
              )
            : null;

        const response = await fetch(apiUrl, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postCodes),
        });

        if (response.ok) {
            data = await response.json();
        } else {
            plainWarning(
                "Lookup failed!",
                "Make sure post codes list is correct.",
                TAG_ROUNDS_LOOKUP
            );
            return [];
        }
    } catch (error) {
        plainWarning("Network issues!", error, TAG_NETWORK);
        return [];
    }

    store.dispatch(roundsLoadData(data.features));
    store.dispatch(territoryUpdateRounds(data.features));
    document.dispatchEvent(reduxActiveRoundsUpdateEvent);

    isTerritoryTargeted(getActiveTerritory().id)
        ? targetActiveTerritory()
        : null;

    return collectRoundIds(data.features);
};

export const getRoundsLayers = (roundsList) => {
    // reset all layers
    let layers = { ...defaultLayers };
    Object.keys(layers).forEach((id) => (layers[id] = false));

    const rounds = getActiveTerritory().rounds;
    return Object.keys(rounds)
        .filter((roundId) => roundsList.includes(roundId))
        .reduce((acc, roundId) => {
            rounds[roundId].selected
                ? (acc[rounds[roundId].layer] = true)
                : null;
            return acc;
        }, layers);
};

const collectRoundIds = (features) => {
    return features.map((feature) => feature.properties.walk_id);
};

// sync rounds volumes
export const syncRoundsVolumes = async () => {
    // collect current rounds ids in all territories from the state
    const territories = store.getState().territories;
    const roundIds = territories.reduce((acc, territory) => {
        return acc.concat(Object.keys(territory.rounds));
    }, []);
    // deduplicate the array of round ids
    const uniqueRoundIds = [...new Set(roundIds)];

    return await getRoundsByList(uniqueRoundIds, true);
};

// load empty walks from Liberty
export const loadEmptyWalks = async () => {
    let data = [];

    const apiUrl = new URL(`${OCI_GATEWAY_URL}/rounds/empty-walks`);
    // always fetch ranked empty walks
    apiUrl.searchParams.append("ranked", "true");
    campaignStartDateOn
        ? apiUrl.searchParams.append("start_date", campaignStartDate())
        : null;

    try {
        const token = await getIdToken(auth.currentUser);
        const response = await fetch(apiUrl, {
            // control cache settings here so they applied on the API gaterway via rewrite or any other mechanism
            headers: {
                "Cache-Control": "max-age=21600",
                Accept: "application/json,text/plain",
                "Firebase-Token": `Bearer ${token}`,
            },
        });
        if (response.ok) {
            data = await response.json();
        } else {
            plainWarning("Loading empty walks failed!", "Please try again.");
            return [];
        }
    } catch (error) {
        plainWarning("Network issues!", error);
        return [];
    }

    store.dispatch(emptyWalksUpdate(data));
};

export const getCentroidAndBox = async (territory) => {
    let selectedRounds = getRoundOnSelectedNetwork(territory);
    selectedRounds =
        selectedRounds.length > 0
            ? selectedRounds
            : Object.keys(territory.rounds);

    if (0 === selectedRounds.length) return {};
    return await getCentroidAndBoxByRounds(selectedRounds);
};

export const getCentroidAndBoxByRounds = async (roundList) => {
    try {
        const response = await fetch(`${URL_GEO_API}/centroidAndBound`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(roundList),
        });

        if (response.ok) {
            return await response.json();
        } else {
            plainWarning("Territory envelope lookup failed!");
            return {};
        }
    } catch (error) {
        plainWarning("Network issues!", error);
        return {};
    }
};

/**
 * Little helper to get campaign start date for API calls
 * @returns {Date}
 */
const campaignStartDate = () => {
    const campaignStartDate = store.getState().quoteMeta.campaignStartDate;
    return formatDateForApi(campaignStartDate);
};
