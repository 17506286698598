import { LitElement, html } from "lit-element";
import { subscribe } from "redux-subscribe";

import { noop, updateUiSettings } from "../../core/actions/actions";
import store from "../../store";

import { isQuoteId, loadQuote, loadState } from "../../services/loadQuote";
import "../progressSpinner";
import { clientWarningEvent } from "../toast";

export const LOAD_QUOTE_PATH = "load-quote";

class RSLoadQuote extends LitElement {
    static get properties() {
        return {
            stateRoute: { type: String },
            quoteId: { type: String },
            nextRoute: { type: String },
            showSaveReminder: { type: Boolean },
        };
    }

    constructor() {
        super();
        store.dispatch(
            subscribe(
                "appRouter",
                `RouteService_${LOAD_QUOTE_PATH}_Watcher`,
                (data) => {
                    if (
                        [data.next.current, data.prev.current].includes(
                            LOAD_QUOTE_PATH
                        )
                    ) {
                        this.stateRoute = data.next.current;
                        const paramsUrl = new URL(
                            `${window.location.protocol}//${window.location.host}/${data.next.params[0]}`
                        );
                        const params = paramsUrl.searchParams;
                        this.quoteId = paramsUrl.pathname.replace("/", "");
                        this.showSaveReminder =
                            params.get("reminder") === "true";
                        this.nextRoute = data.next.params[1] || "";
                    }
                    return noop(`RouteService_${LOAD_QUOTE_PATH}_Watcher`);
                }
            )
        );
    }

    async updated(changedProps) {
        if (this.stateRoute !== LOAD_QUOTE_PATH) {
            return;
        }

        if (changedProps.has("quoteId") && this.quoteId) {
            // validate quote ID
            if (isQuoteId(this.quoteId)) {
                let quoteData;
                try {
                    quoteData = await loadQuote(this.quoteId);
                } catch (error) {
                    global.document.dispatchEvent(
                        clientWarningEvent({
                            message:
                                "Could not load quote by ID provided in the link.",
                        })
                    );
                }
                if (quoteData) {
                    try {
                        await loadState(quoteData);

                        store.dispatch(
                            updateUiSettings({
                                showSaveReminder: this.showSaveReminder,
                            })
                        );
                    } catch (error) {
                        global.document.dispatchEvent(
                            clientWarningEvent({
                                message: "Could not load quote data.",
                            })
                        );
                    }
                }

                // redirect to the next route
                global.location.assign(`/#/${this.nextRoute}`);
            } else {
                // prompt user quote ID is not valid
                global.document.dispatchEvent(
                    clientWarningEvent({ message: "Quote ID is malformed." })
                );
                global.location.assign("/#/");
            }
        } else {
            // prompt user quote ID is missing
            global.document.dispatchEvent(
                clientWarningEvent({ message: "Quote ID is missing." })
            );
            global.location.assign("/#/");
        }
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="h-full flex items-center justify-center">
                <rm-progress-spinner></rm-progress-spinner>
            </div>
        `;
    }
}

global.customElements.define("rm-rs-load-quote", RSLoadQuote);
