import { LitElement, html } from "lit-element";
import { nothing } from "lit-html";
import { repeat } from "lit-html/directives/repeat";
import { subscribe, unsubscribe } from "redux-subscribe";

import store from "../../store";
import {
    paperSize,
    pagination,
    colours,
    gsm,
    paperType,
} from "../../core/reducers/printingSpecs";
import {
    printingSpecsUpdate,
    noop,
    quoteDetailsUpdate,
    quoteMetaUpdate,
    updateLodgementDetails,
} from "../../core/actions/actions";
import { printingQuote } from "../../services/httpApis/printingQuote";
import { clientDialogCloseEvent } from "../dialog";
import { diffKeys } from "../../services/objectHelpers";
import {
    bracketTitle,
    calcPageWeight,
    weightBrackets,
} from "../../services/weight";
import { quotedNetworks } from "../../services/quoteCalcs";

import { LODGEMENT_SITE, LODGEMENT_SITE_OPTIONS } from "../../const/lodgement";

import "./printingQuotes.js";

class PrintingSettings extends LitElement {
    constructor() {
        super();
        this.config = store.getState().printingSpecs;
        this.quoteDetails = store.getState().quoteDetails;
        this.meta = store.getState().quoteMeta;
        this.fetchQuotes();

        store.dispatch(
            subscribe("printingSpecs", "updatePrintingSettings", (data) => {
                this.config = data.next;

                // detect printer change and debounce if it's the only change
                const changedProps = diffKeys(data.prev, data.next);
                if (
                    !(
                        1 == changedProps.length &&
                        changedProps.includes("printer")
                    )
                ) {
                    this.fetchQuotes();
                }
                return noop("updatePrintingSettings");
            })
        );

        store.dispatch(
            subscribe("quoteDetails", "updatePrintQuoteConfig", (data) => {
                this.quoteDetails = data.next;

                // request new quotes when user updates extra copies
                const changedProps = diffKeys(data.prev, data.next);
                if (changedProps.includes("extraCopies")) {
                    this.fetchQuotes();
                }
                return noop("updatePrintQuoteConfig");
            })
        );

        store.dispatch(
            subscribe("quoteMeta", "updatePrintQuoteMeta", (data) => {
                this.meta = data.next;
                // request new quotes when user updates campaign date
                const changedProps = diffKeys(data.prev, data.next);
                if (
                    changedProps.filter((item) =>
                        ["campaignStartDate", "printJobStartDate"].includes(
                            item
                        )
                    )
                ) {
                    this.fetchQuotes();
                }
                return noop("updatePrintQuoteMeta");
            })
        );
    }

    static get properties() {
        return {
            config: { type: Object },
            quoteDetails: { type: Object },
            quotes: { type: Object },
            meta: { type: Object },
        };
    }

    weightBracket() {
        const pageWeight = calcPageWeight(
            this.config.paperSize,
            this.config.gsm
        );
        return bracketTitle(pageWeight * this.config.pageCount);
    }

    disconnectedCallback() {
        store.dispatch(unsubscribe("printingSpecs", "updatePrintingSettings"));
        store.dispatch(unsubscribe("quoteDetails", "updatePrintQuoteConfig"));
        store.dispatch(unsubscribe("quoteMeta", "updatePrintQuoteMeta"));
    }

    // Respect extra copies line
    fetchQuotes() {
        const subtotalMatrix = this.quoteDetails.subtotalMatrix;
        const volume = quotedNetworks(subtotalMatrix).reduce(
            (acc, channel) => subtotalMatrix[channel] + acc,
            this.quoteDetails.extraCopies
        );
        const printJobStartDate = this.meta.printJobStartDate;

        // ensure to nullify printer to get all possible quotes
        this.quotes = printingQuote(
            { ...this.config, printer: null, printJobStartDate },
            volume
        );
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <style>
                .first-letter-uppercase::first-letter {
                    text-transform: uppercase;
                }
            </style>
            <div class="flex flex-col h-full">
                <section
                    class="flex flex-wrap flex-grow-0 justify-between py-2 px-4 -mx-4 border-b border-dark-grey"
                >
                    <h3 class="text-xl font-medium">Mailer details</h3>
                    <button
                        @click=${() =>
                            document.dispatchEvent(clientDialogCloseEvent)}
                        title="Close [Esc]"
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">
                            Close
                            <span class="text-xs lowercase">[Esc]</span>
                        </span>
                    </button>
                </section>
                <section class="flex flex-row flex-grow-0 pt-2">
                    <div
                        class="w-1/2 flex flex-wrap items-center content-start"
                    >
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="printing-calc-paper-size"
                                >Print Job Start Date</label
                            >
                        </div>
                        <div class="w-2/3">
                            <input
                                type="date"
                                class="block border rounded-sm leading-tight w-48 p-1"
                                .min="${new Date().toISOString().slice(0, 10)}"
                                .value="${this.meta.printJobStartDate
                                    ? new Date(this.meta.printJobStartDate)
                                          .toISOString()
                                          .slice(0, 10)
                                    : null}"
                                @input=${(e) =>
                                    store.dispatch(
                                        quoteMetaUpdate({
                                            printJobStartDate: e.target.value
                                                ? e.target.value
                                                : null,
                                        })
                                    )}
                            />
                        </div>
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="printing-calc-paper-size"
                                >Paper Size</label
                            >
                        </div>
                        <div class="w-2/3">
                            <select
                                @input=${(e) =>
                                    store.dispatch(
                                        printingSpecsUpdate({
                                            paperSize: e.target.value,
                                        })
                                    )}
                                class="block border rounded-sm leading-tight w-48 p-1"
                                id="printing-calc-paper-size"
                            >
                                ${repeat(
                                    paperSize,
                                    (i) => html`
                                        <option
                                            ?selected=${i ===
                                            this.config.paperSize}
                                        >
                                            ${i}
                                        </option>
                                    `
                                )}
                            </select>
                        </div>
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="printing-calc-pagination"
                                >Pagination</label
                            >
                        </div>
                        <div class="w-2/3">
                            <select
                                @input=${(e) =>
                                    store.dispatch(
                                        printingSpecsUpdate({
                                            pagination: e.target.value,
                                        })
                                    )}
                                class="block border rounded-sm leading-tight w-48 p-1"
                                id="printing-calc-pagination"
                            >
                                ${repeat(
                                    pagination,
                                    (i) => html`
                                        <option
                                            ?selected=${i ===
                                            this.config.pagination}
                                        >
                                            ${i}
                                        </option>
                                    `
                                )}
                            </select>
                        </div>
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="printing-calc-colours"
                                >Colours</label
                            >
                        </div>
                        <div class="w-2/3">
                            <select
                                @input=${(e) =>
                                    store.dispatch(
                                        printingSpecsUpdate({
                                            colours: e.target.value,
                                        })
                                    )}
                                class="block border rounded-sm leading-tight w-48 p-1"
                                id="printing-calc-colours"
                            >
                                ${repeat(
                                    colours,
                                    (i) => html`
                                        <option
                                            ?selected=${i ===
                                            this.config.colours}
                                        >
                                            ${i}
                                        </option>
                                    `
                                )}
                            </select>
                        </div>
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="printing-calc-paper-gsm"
                                >GSM</label
                            >
                        </div>
                        <div class="w-2/3">
                            <select
                                @input=${(e) =>
                                    store.dispatch(
                                        printingSpecsUpdate({
                                            gsm: e.target.value,
                                        })
                                    )}
                                class="block border rounded-sm leading-tight w-48 p-1"
                                id="printing-calc-paper-gsm"
                            >
                                ${repeat(
                                    gsm,
                                    (i) => html`
                                        <option
                                            ?selected=${i === this.config.gsm}
                                        >
                                            ${i}
                                        </option>
                                    `
                                )}
                            </select>
                        </div>
                        <!---->

                        <!---->
                        <div class="w-2/3 border border-peppermint p-2">
                            <p class="text-center text-sm">
                                Use these values to cross check the Sundry
                                Deliveries
                            </p>
                        </div>
                        <div class="w-1/3 text-center text-4xl text-peppermint">
                            &rArr;
                        </div>
                        <!---->
                    </div>

                    <!-- COLUMNS SEPARATOR -->

                    <div
                        class="w-1/2 flex flex-wrap items-center content-start pb-4"
                    >
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="printing-calc-paper-type"
                                >Paper Type</label
                            >
                        </div>
                        <div class="w-2/3">
                            <select
                                @input=${(e) =>
                                    store.dispatch(
                                        printingSpecsUpdate({
                                            paperType: e.target.value,
                                        })
                                    )}
                                class="block border rounded-sm leading-tight w-48 p-1"
                                id="printing-calc-paper-type"
                            >
                                ${repeat(
                                    paperType,
                                    (i) => html`
                                        <option
                                            ?selected=${i ===
                                            this.config.paperType}
                                        >
                                            ${i}
                                        </option>
                                    `
                                )}
                            </select>
                        </div>
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right ${this.quoteDetails
                                    .customWeightBracket
                                    ? "text-grey"
                                    : ""}"
                                for="printing-calc-paper-size"
                                >Weight Bracket</label
                            >
                        </div>
                        <div
                            class="w-2/3 ${this.quoteDetails.customWeightBracket
                                ? "text-grey"
                                : ""}"
                        >
                            [${this.weightBracket()}]gms
                        </div>
                        <!---->
                        <div class="w-1/3">
                            <label
                                class="py-2 pr-2 block text-right ${this
                                    .quoteDetails.customWeightBracket
                                    ? "bg-sunrise"
                                    : ""}"
                                for="printing-calc-paper-size"
                                >Custom Weight Bracket</label
                            >
                        </div>
                        <div class="w-2/3">
                            <select
                                @input=${(e) =>
                                    store.dispatch(
                                        quoteDetailsUpdate({
                                            customWeightBracket: e.target.value,
                                        })
                                    )}
                                class="block border rounded-sm leading-tight w-48 p-1"
                                id="printing-calc-page-count"
                            >
                                <option
                                    ?selected=${null ===
                                    this.quoteDetails.customWeightBracket}
                                    value="null"
                                >
                                    Not applied
                                </option>
                                ${repeat(
                                    Object.keys(weightBrackets),
                                    (i) => html`
                                        <option
                                            ?selected=${i ===
                                            this.quoteDetails
                                                .customWeightBracket}
                                            value="${i}"
                                        >
                                            [${weightBrackets[i]}]gms
                                        </option>
                                    `
                                )}
                            </select>
                        </div>
                        <!---->

                        <!---->
                        <div class="w-1/3">
                            <label
                                class="py-2 pr-2 block text-right ${this
                                    .quoteDetails.enclosed
                                    ? "bg-sunrise"
                                    : nothing}"
                                for="printing-calc-enclosed"
                                >Enclosed</label
                            >
                        </div>
                        <div class="w-2/3">
                            <select
                                @input=${(e) =>
                                    store.dispatch(
                                        quoteDetailsUpdate({
                                            enclosed: "yes" === e.target.value,
                                        })
                                    )}
                                class="block border rounded-sm leading-tight w-48 p-1"
                                id="printing-calc-enclosed"
                            >
                                <option
                                    ?selected=${!this.quoteDetails.enclosed}
                                    value="no"
                                >
                                    No
                                </option>
                                <option
                                    ?selected=${this.quoteDetails.enclosed}
                                    value="yes"
                                >
                                    Yes
                                </option>
                            </select>
                        </div>
                        <!---->
                        <div class="w-1/3">
                            <label
                                class="py-2 pr-2 block text-right ${this
                                    .quoteDetails.oversized
                                    ? "bg-sunrise"
                                    : nothing}"
                                for="printing-calc-oversized"
                                >Oversized</label
                            >
                        </div>
                        <div class="w-2/3">
                            <select
                                @input=${(e) =>
                                    store.dispatch(
                                        quoteDetailsUpdate({
                                            oversized: "yes" === e.target.value,
                                        })
                                    )}
                                class="block border rounded-sm leading-tight w-48 p-1"
                                id="printing-calc-oversized"
                            >
                                <option
                                    ?selected=${!this.quoteDetails.oversized}
                                    value="no"
                                >
                                    No
                                </option>
                                <option
                                    ?selected=${this.quoteDetails.oversized}
                                    value="yes"
                                >
                                    Yes
                                </option>
                            </select>
                        </div>
                        <!---->
                        <div
                            class="flex flex-wrap items-center content-start border border-peppermint"
                        >
                            <div class="w-1/3 py-2 pr-2">
                                <label
                                    class="block text-right"
                                    for="printing-extra-copies"
                                    >Extra Copies</label
                                >
                            </div>
                            <div class="w-2/3">
                                <input
                                    @input=${(e) =>
                                        store.dispatch(
                                            quoteDetailsUpdate({
                                                extraCopies:
                                                    "" === e.target.value
                                                        ? 0
                                                        : parseInt(
                                                              e.target.value
                                                          ),
                                            })
                                        )}
                                    class="block border rounded-sm leading-tight w-48 p-1"
                                    id="printing-extra-copies"
                                    type="number"
                                    min="0"
                                    .value="${this.quoteDetails.extraCopies}"
                                    disabled
                                />
                            </div>
                            <!---->
                            <div class="w-1/3 py-2 pr-2">
                                <label
                                    class="block text-right"
                                    for="printing-extra-copies-fee"
                                    >Extra Copies Fee $</label
                                >
                            </div>
                            <div class="w-2/3">
                                <input
                                    @input=${(e) =>
                                        store.dispatch(
                                            quoteDetailsUpdate({
                                                extraCopiesFee:
                                                    "" === e.target.value
                                                        ? 0
                                                        : parseFloat(
                                                              e.target.value
                                                          ),
                                            })
                                        )}
                                    class="block border rounded-sm leading-tight w-48 p-1"
                                    id="printing-extra-copies-fee"
                                    type="number"
                                    min="0"
                                    .value="${this.quoteDetails.extraCopiesFee}"
                                    disabled
                                />
                            </div>
                        </div>
                        <!---->
                    </div>
                </section>

                <section class="pt-2 pb-4">
                    <h4 class="text-xl font-normal">Sundry Deliveries</h4>
                    <div class="overflow-x-auto">
                        <table class="w-full text-left">
                            <thead>
                                <tr class="bg-peppermint-50">
                                    <th
                                        class="border px-4 py-2 text-sm uppercase font-medium"
                                    >
                                        Site
                                    </th>
                                    <th
                                        class="border px-4 py-2 text-sm uppercase font-medium"
                                    >
                                        Type
                                    </th>
                                    <th
                                        class="border px-4 py-2 text-sm uppercase font-medium"
                                    >
                                        Quantity
                                    </th>
                                    <th
                                        class="border px-4 py-2 text-sm uppercase font-medium"
                                    >
                                        Fee <small>(per thousand)</small>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Loop through LODGEMENT_SITE and LODGEMENT_SITE_OPTIONS -->
                                ${Object.values(LODGEMENT_SITE).map((site) =>
                                    Object.values(LODGEMENT_SITE_OPTIONS).map(
                                        (option) =>
                                            html` <tr>
                                                <td
                                                    class="border px-4 py-2 lowercase first-letter-uppercase"
                                                >
                                                    ${site}
                                                </td>
                                                <td
                                                    class="border px-4 py-2 lowercase first-letter-uppercase"
                                                >
                                                    ${option}
                                                </td>
                                                <td class="border px-4 py-2">
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        class="border rounded-sm p-1"
                                                        .value=${this
                                                            .quoteDetails
                                                            .lodgement[site][
                                                            option
                                                        ].volume}
                                                        @input=${(e) =>
                                                            store.dispatch(
                                                                updateLodgementDetails(
                                                                    site,
                                                                    option,
                                                                    e.target
                                                                        .value,
                                                                    undefined
                                                                )
                                                            )}
                                                    />
                                                </td>
                                                <td class="border px-4 py-2">
                                                    ${"DROPS" === option
                                                        ? html`<!-- The fee is irrelevant to drops -->`
                                                        : html` $&ensp;<input
                                                                  type="number"
                                                                  min="0"
                                                                  step="0.01"
                                                                  class="border rounded-sm p-1"
                                                                  .value=${this
                                                                      .quoteDetails
                                                                      .lodgement[
                                                                      site
                                                                  ][option].fee}
                                                                  @input=${(
                                                                      e
                                                                  ) =>
                                                                      store.dispatch(
                                                                          updateLodgementDetails(
                                                                              site,
                                                                              option,
                                                                              undefined,
                                                                              e
                                                                                  .target
                                                                                  .value
                                                                          )
                                                                      )}
                                                              />`}
                                                </td>
                                            </tr>`
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </section>

                <section class="flex flex-col pt-2 pb-4">
                    <h4 class="text-xl font-normal">Production</h4>
                    <rm-printing-quotes
                        .printer=${this.config.printer}
                        .quotes=${this.quotes}
                    ></rm-printing-quotes>
                </section>
            </div>
        `;
    }
}

global.customElements.define("rm-printing-settings", PrintingSettings);
