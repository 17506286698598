const GMAP_DRAWING_MODE = {
    NONE: "NONE",
    CIRCLE: "CIRCLE",
    POLYGON: "POLYGON",
    INFO: "INFO",
};

export const EMPTY_WALK_BUTTON = "EMPTY_WALK_BUTTON";
export const SELECT_ALL_EMPTY = "TOGGLE_ALL_EMPTY";
export const BULK_DESELECT = "BULK_DESELECT";
export { GMAP_DRAWING_MODE };
