import { html } from "lit-element";
import { nothing } from "lit-html";
import clonedeep from "lodash-es/cloneDeep";

import {
    StatefulButtonBase,
    PROGRESS,
    ACTIVE,
} from "../base/statefulButtonBase";
import "../progressSpinner";
import { clientInfoNoDelayEvent } from "../toast";

import store from "../../store";
import {
    purgeCustomerChanges,
    setupAllCustomerChanges,
    updateTerritoryState,
} from "../../services/volumes";
import { reduxActiveRoundsUpdateEvent } from "../../const/dataLayers";

export const clientCustomerChangesAcceptEvent = new Event(
    "client:customer:changes:accept"
);
export const clientCustomerChangesDeclineEvent = new Event(
    "client:customer:changes:decline"
);

class ReviewCustomerChangesButtons extends StatefulButtonBase {
    constructor() {
        super();
    }

    async save(isDecline = false) {
        this.status = PROGRESS;

        const customerChangesState = clonedeep(store.getState());

        if (!isDecline) {
            // include customer changes into territories
            setupAllCustomerChanges(
                customerChangesState.territories,
                customerChangesState.roundsData,
                true
            );
        }

        // purge customer changes metadata
        purgeCustomerChanges(customerChangesState.territories);
        updateTerritoryState(customerChangesState.territories);
        document.dispatchEvent(reduxActiveRoundsUpdateEvent);

        if (isDecline) {
            this.dispatchEvent(clientCustomerChangesDeclineEvent);
            this.dispatchEvent(
                clientInfoNoDelayEvent({
                    message:
                        "Proposed changes declined. Please save the quote.",
                })
            );
        } else {
            this.dispatchEvent(clientCustomerChangesAcceptEvent);
            this.dispatchEvent(
                clientInfoNoDelayEvent({
                    message:
                        "Proposed changes accepted. Please save the quote.",
                })
            );
        }

        this.status = ACTIVE;
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-row items-center">
                <rm-progress-spinner
                    class="mr-2 ${PROGRESS === this.status
                        ? nothing
                        : "hidden"}"
                ></rm-progress-spinner>
                <button
                    @click=${() => this.save()}
                    title="Accept Changes"
                    class="${this.itemCss()} mr-4"
                >
                    <span class="text-sm font-medium uppercase">Accept</span>
                </button>
                <button
                    @click=${() => this.save(true)}
                    title="Decline Changes"
                    class="${this.itemCss()} bg-danger border-danger"
                >
                    <span class="text-sm font-medium uppercase">Decline</span>
                </button>
            </div>
        `;
    }
}

global.customElements.define(
    "rm-review-customer-changes-buttons",
    ReviewCustomerChangesButtons
);
