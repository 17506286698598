import { LitElement, html } from "lit-element";

class AppFooter extends LitElement {
    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-col justify-center text-center mt-4 mb-8">
                <hr />
                <div class="grid grid-flow-col gap-4 my-4">
                    <a
                        href="https://www.reach.nz/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                        class="text-peppermint"
                        >Privacy Policy</a
                    >
                </div>
                <div>
                    <p class="text-sm">
                        &copy; ${new Date().getFullYear()} REACH. All Rights
                        Reserved
                    </p>
                </div>
            </div>
        `;
    }
}

global.customElements.define("rm-app-footer", AppFooter);
