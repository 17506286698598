import {
    TARGETING_FILTER_ADD,
    TARGETING_FILTER_GROUP_ADD,
    TARGETING_FILTER_GROUP_ACTIVATE,
    TARGETING_FILTER_GROUP_UPDATE_VARS,
    TARGETING_FILTER_GROUP_UPDATE_VARS_POOL,
    TARGETING_FILTER_GROUP_UPDATE_VALUES,
    TARGETING_FILTER_GROUP_DELETE,
    TARGETING_FILTER_GROUP_UPDATE_THRESHOLDS,
    TARGETING_FILTER_UPDATE_VOLUME_LIMIT,
    TARGETING_FILTER_UPDATE_BASE_ROUNDS,
    TARGETING_FILTERS_LOAD,
    TARGETING_TERRITORY_RESET,
} from "./actionTypes";

export const addTargetingFilter = () => {
    return {
        type: TARGETING_FILTER_ADD,
    };
};

export const loadTargetingFilters = (payload) => {
    return {
        type: TARGETING_FILTERS_LOAD,
        payload,
    };
};

/**
 * Base rounds should be updated whenever user adds/removes (NOT toggling visibility) rounds from selection
 * or changes network selections
 *
 * @param {Object} payload
 */
export const updateTargetingFilterBaseRounds = (payload) => {
    return {
        type: TARGETING_FILTER_UPDATE_BASE_ROUNDS,
        payload,
    };
};

export const updateTargetingFilterVolumeLimit = (payload) => {
    return {
        type: TARGETING_FILTER_UPDATE_VOLUME_LIMIT,
        payload,
    };
};

export const addTargetingFilterGroup = () => {
    return {
        type: TARGETING_FILTER_GROUP_ADD,
    };
};

export const deleteTargetingFilterGroup = (payload) => {
    return {
        type: TARGETING_FILTER_GROUP_DELETE,
        payload,
    };
};

export const activateTargetingFilterGroup = (payload) => {
    return {
        type: TARGETING_FILTER_GROUP_ACTIVATE,
        payload,
    };
};

export const updateTargetingFilterGroupVars = (payload) => {
    return {
        type: TARGETING_FILTER_GROUP_UPDATE_VARS,
        payload,
    };
};

export const updateTargetingFilterGroupVarsPool = (payload) => {
    return {
        type: TARGETING_FILTER_GROUP_UPDATE_VARS_POOL,
        payload,
    };
};

export const updateTargetingFilterGroupValues = (payload) => {
    return {
        type: TARGETING_FILTER_GROUP_UPDATE_VALUES,
        payload,
    };
};

export const updateTargetingFilterGroupThresholds = (payload) => {
    return {
        type: TARGETING_FILTER_GROUP_UPDATE_THRESHOLDS,
        payload,
    };
};

export const resetTerritoryTargeting = () => {
    return {
        type: TARGETING_TERRITORY_RESET,
    };
};
