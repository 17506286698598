import { LitElement, html } from "lit-element";

import "./costChangesSummary";
import "./reviewCustomerChangesButtons";
import "./territoryChangesSummary";

import { clientDialogCloseEvent } from "../dialog";
import "../wordsCloud";

export class ReviewCustomerChanges extends LitElement {
    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-col h-full">
                <div
                    class="flex flex-wrap justify-between py-2 px-4 -mx-4 border-b border-dark-grey"
                >
                    <h3 class="text-xl font-medium">Review Proposed Changes</h3>
                    <button
                        @click=${() =>
                            document.dispatchEvent(clientDialogCloseEvent)}
                        title="Close [Esc]"
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">
                            Close
                            <span class="text-xs lowercase">[Esc]</span>
                        </span>
                    </button>
                </div>
                <rm-territory-changes-summary></rm-territory-changes-summary>
                <rm-cost-changes-summary></rm-cost-changes-summary>
                <div
                    class="flex flex-row-reverse flex-grow-0 -mx-4 pt-4 px-4 border-t border-dark-grey items-center"
                >
                    <rm-review-customer-changes-buttons
                        @client:customer:changes:accept=${() => {
                            document.dispatchEvent(clientDialogCloseEvent);
                        }}
                        @client:customer:changes:decline=${() => {
                            document.dispatchEvent(clientDialogCloseEvent);
                        }}
                    ></rm-review-customer-changes-buttons>
                </div>
            </div>
        `;
    }
}

global.customElements.define(
    "rm-review-customer-changes",
    ReviewCustomerChanges
);
