export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const dayMonthYear = (date, separator = "/") => {
    try {
        return `${String(date.getDate()).padStart(2, "0")}${separator}${
            months[date.getMonth()]
        }${separator}${String(date.getFullYear()).slice(-2)}`;
    } catch (error) {
        console.log("Date object is expected, got something different.");
        console.log(error);
    }
};

export const fullDateTime = (date) => {
    try {
        const dateString = date.toLocaleString().toLocaleLowerCase();
        const monthName = date.toLocaleString([], { month: "short" });
        const dateTokens = dateString.split(",");
        const hourParts = dateTokens[1].trim().split(" ");
        const hour = hourParts[0].split(":").slice(0, -1).join(":");
        const datePart = `${monthName} ${date.getDate()}, ${date.getFullYear()}`;
        return [datePart, [hour, hourParts[1]].join(" ")].join("  ");
    } catch (error) {
        console.log(error);
    }
};

export const formatDateForApi = (date) => {
    let dateFormatted = "";
    if (date) {
        const dt = new Date(date);

        // alter the date format to dd-MMM-YYYY to conform with API
        const month = new Intl.DateTimeFormat("en-US", {
            month: "short",
        }).format(dt);
        dateFormatted = `${dt.getDate()}-${month}-${dt.getFullYear()}`;
    }
    return dateFormatted;
};

export const isDatePast = (date) => {
    return new Date(date).getTime() < new Date().getTime();
};
