import { LitElement, html } from "lit-element";
import { clientDialogCloseEvent } from "../dialog";
import store from "../../store";
import { updateQuoteSettings } from "../../core/actions/actions";
import { saveQuoteSettings } from "../../services/httpApis/saveQuote";

class QuoteSetting extends LitElement {
    constructor() {
        super();
        this.settings = store.getState().settings;
    }

    static get properties() {
        return {
            settings: { type: Object },
        };
    }

    toggleSettingValue(prop, value) {
        this.settings[prop] = value;
        store.dispatch(updateQuoteSettings(this.settings));
    }

    async saveSettings() {
        if (await saveQuoteSettings()) {
            document.dispatchEvent(clientDialogCloseEvent);
        }
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-col h-full">
                <section
                    class="flex flex-wrap flex-grow-0 justify-between py-2 px-4 -mx-4 border-b border-dark-grey"
                >
                    <h3 class="text-xl font-medium">Quote Settings</h3>
                    <button
                        @click=${() =>
                            document.dispatchEvent(clientDialogCloseEvent)}
                        title="Close [Esc]"
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">
                            Close
                            <span class="text-xs lowercase">[Esc]</span>
                        </span>
                    </button>
                </section>
                <section class="flex flex-1 flex-wrap content-start pt-2">
                    <div class="w-1/2">
                        <label class="block">
                            <input
                                type="checkbox"
                                class="checkbox"
                                .checked=${this.settings.hideSurrounding}
                                @change=${(e) =>
                                    this.toggleSettingValue(
                                        "hideSurrounding",
                                        e.target.checked
                                    )}
                            />
                            Hide surrounding areas
                        </label>
                        <label class="block">
                            <input
                                type="checkbox"
                                class="checkbox"
                                .checked=${this.settings.targetingSummary}
                                @change=${(e) =>
                                    this.toggleSettingValue(
                                        "targetingSummary",
                                        e.target.checked
                                    )}
                            />
                            Include targeting variables summary
                        </label>
                        <label class="block">
                            <input
                                type="checkbox"
                                class="checkbox"
                                .checked=${this.settings.hideQuoteSummary}
                                @change=${(e) =>
                                    this.toggleSettingValue(
                                        "hideQuoteSummary",
                                        e.target.checked
                                    )}
                            />
                            Hide quote summary
                        </label>
                        <label class="block">
                            <input
                                type="checkbox"
                                class="checkbox"
                                .checked=${this.settings.isQuoteEditable}
                                @change=${(e) =>
                                    this.toggleSettingValue(
                                        "isQuoteEditable",
                                        e.target.checked
                                    )}
                            />
                            Allow client to edit quote
                        </label>
                        <label class="block">
                            <input
                                type="checkbox"
                                class="checkbox"
                                .checked=${this.settings
                                    .isTerritoryVolumeViewable}
                                @change=${(e) =>
                                    this.toggleSettingValue(
                                        "isTerritoryVolumeViewable",
                                        e.target.checked
                                    )}
                            />
                            Allow client to view volume breakdown
                        </label>
                    </div>
                </section>
                <section
                    class="flex -mx-4 pt-4 px-4 border-t border-dark-grey justify-end"
                >
                    <button
                        title="Save"
                        @click=${() => this.saveSettings()}
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">Save</span>
                    </button>
                </section>
            </div>
        `;
    }
}

global.customElements.define("rm-quote-setting", QuoteSetting);
