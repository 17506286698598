export default {
    population: {
        name: "Population",
        title: "Add population target",
        options: ["Population", "Dwellings", "DPID"],
        type: "Number",
    },
    income: {
        name: "Income",
        title: "Add income target",
        options: ["ave_hh_income"],
        type: "Number",
    },
    age: {
        name: "Age",
        title: "Add age target",
        options: [
            "age_0_4_years",
            "age_5_9_years",
            "age_10_14_years",
            "age_15_19_years",
            "age_20_24_years",
            "age_25_29_years",
            "age_30_34_years",
            "age_35_39_years",
            "age_40_44_years",
            "age_45_49_years",
            "age_50_54_years",
            "age_55_59_years",
            "age_60_64_years",
            "age_65_",
            "median_age",
        ],
        type: "Fraction",
    },
    medianAge: {
        name: "Median Age",
        title: "Add median age target",
        options: ["median_age"],
        type: "Number",
    },
    gender: {
        name: "Gender",
        title: "Add gender target",
        options: ["male", "female"],
        type: "Fraction",
    },
    ethnicity: {
        name: "Ethnicity",
        title: "Add ethnicity target",
        options: ["european", "maori", "pacific_peoples", "asian", "middle_eastern_latin_american", "other_ethnicity"],
        type: "Fraction",
    },
    profession: {
        name: "Profession",
        title: "Add profession target",
        options: [
            "managers",
            "professionals",
            "technicians_and_trades_workers",
            "community_and_personal_service",
            "clerical_and_administrative",
            "sales_workers",
            "machinery_operators_and_drivers",
            "labourers",
        ],
        type: "Fraction",
    },
    tenancy: {
        name: "Tenancy",
        title: "Add tenancy target",
        options: ["homeowners", "renters"],
        type: "Fraction",
    },
    employmentStatus: {
        name: "Employment ",
        title: "Add employment target",
        options: ["employed", "unemployed", "self_employed_"],
        type: "Fraction",
    },
    qualification: {
        name: "Qualification",
        title: "Add qualification target",
        options: ["no_qualification", "secondary_qualification", "tertiary_qualification"],
        type: "Fraction",
    },
    familyComposition: {
        name: "Family Composition",
        title: "Add family composition target",
        options: ["couple_without_children", "couple_with_child_ren", "one_parent_with_child_ren"],
        type: "Fraction",
    },
    student: {
        name: "Student",
        title: "Add student target",
        options: ["studying", "not_studying"],
        type: "Fraction",
    },
    marrige: {
        name: "Marriage",
        title: "Add marriage target",
        options: ["married_not_separated", "separated_divorced", "never_married_or_civil_union"],
        type: "Fraction",
    },
    origin: {
        name: "Origin",
        title: "Add origin target",
        options: ["nz_born", "overseas_born"],
        type: "Fraction",
    },
    internetAccess: {
        name: "Internet Access",
        title: "Add access to internet target",
        options: ["no_access_to_telecommunication", "access_to_the_internet"],
        type: "Fraction",
    },
    smokingStatus: {
        name: "Smoking Status",
        title: "Add smoking status",
        options: ["regular_smoker", "ex_smoker", "never_smoked_regularly"],
        type: "Fraction",
    },
    deprivationIndex: {
        name: "Deprivation Index",
        title: "Add deprivation index target",
        options: ["average_deprivation_index"],
        type: "Number",
    },
};
