import { UPDATE_URL_PARAMS } from "../actions/actionTypes";
import clonedeep from "lodash-es/cloneDeep";

export const urlParams = (state = { modes: [] }, action) => {
    switch (action.type) {
        case UPDATE_URL_PARAMS: {
            const params = clonedeep(state);
            return { ...params, ...action.payload };
        }

        default:
            return state;
    }
};
