import { collection } from "firebase/firestore";
import { LitElement, html } from "lit-element";
import { nothing } from "lit-html";
import { subscribe, unsubscribe } from "redux-subscribe";

import "../wordsCloud";
import "./saveQuoteButton";

import { TAG_REACHHOME } from "../../const/tags";
import { noop, quoteMetaUpdate } from "../../core/actions/actions";
import { fullDateTime, isDatePast } from "../../services/dateHelper";
import { firestore } from "../../services/firebase/init";
import { hasJobNumber, isQuoteLocked } from "../../services/quoteHelper";
import store from "../../store";
import { clientDialogCloseEvent } from "../dialog";

export class SaveQuote extends LitElement {
    constructor() {
        super();

        this.collectionRef = collection(firestore, "quotes");

        this.meta = store.getState().quoteMeta;
        this.quote = store.getState().quoteDetails;

        store.dispatch(
            subscribe("quoteMeta", "updateSaveQuoteMeta", (data) => {
                this.meta = data.next;
                return noop("updateSaveQuoteMeta");
            })
        );

        store.dispatch(
            subscribe("quoteDetails", "updateSaveQuoteDetails", (data) => {
                this.quote = data.next;
                return noop(`updateSaveQuoteDetails`);
            })
        );
    }

    static get properties() {
        return {
            meta: { type: Object },
            quote: { type: Object },
            saveAsNew: { type: Boolean },
        };
    }

    firstUpdated() {
        this.quoteNameField.focus();
        this.saveAsNew = 0 === this.meta.id.length || isQuoteLocked(this.quote);
    }

    disconnectedCallback() {
        store.dispatch(unsubscribe("quoteMeta", "updateSaveQuoteMeta"));
        store.dispatch(unsubscribe("quoteDetails", "updateSaveQuoteDetails"));
    }

    get quoteNameField() {
        return this.shadowRoot.querySelector("#save-quote-name");
    }

    get tagInputField() {
        return this.shadowRoot.querySelector("#save-tags");
    }

    toggleSaveAsNew(state) {
        if (isQuoteLocked(this.quote)) {
            this.saveAsNew = true;
        } else {
            this.saveAsNew = state;
        }
    }

    addTag(tag) {
        const tags = [...this.meta.tags];
        if (tag) {
            tags.push(tag.toLowerCase());
        } else {
            tags.push(this.tagInputField.value.toLowerCase());
        }
        store.dispatch(quoteMetaUpdate({ tags }));
    }

    removeTag(tag) {
        const tags = [...this.meta.tags];
        tags.splice(tags.indexOf(tag), 1);
        store.dispatch(quoteMetaUpdate({ tags }));
    }

    resetTagInput() {
        this.tagInputField.value = "";
    }

    toggleReachhomeTag() {
        if (this.hasReachhomeTag()) {
            this.removeTag(TAG_REACHHOME.toLowerCase());
        } else {
            this.addTag(TAG_REACHHOME);
        }
    }

    hasReachhomeTag() {
        return (
            this.meta.tags.filter(
                (tag) => tag.toLowerCase() === TAG_REACHHOME.toLowerCase()
            ).length > 0
        );
    }

    onNameInput(e) {
        this.toggleSaveAsNew(true);
        store.dispatch(quoteMetaUpdate({ name: e.target.value }));
    }

    updateJobNumber(value) {
        this.removeTag(this.meta.jobNumber);
        let tags = [...this.meta.tags];
        if (value) {
            tags = [...this.meta.tags, value.toLowerCase()];
        }
        store.dispatch(quoteMetaUpdate({ tags, jobNumber: value }));
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-col h-full">
                <div
                    class="flex flex-wrap justify-between py-2 px-4 -mx-4 border-b border-dark-grey"
                >
                    <h3 class="text-xl font-medium">
                        ${isQuoteLocked(this.quote) ? "Copy" : "Save"} Quote
                    </h3>
                    <button
                        @click=${() =>
                            document.dispatchEvent(clientDialogCloseEvent)}
                        title="Close [Esc]"
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">
                            Close
                            <span class="text-xs lowercase">[Esc]</span>
                        </span>
                    </button>
                </div>
                <div class="flex lg:flex-row flex-col flex-grow-0 pt-2 mt-2">
                    <div
                        class="lg:w-1/2 w-full flex flex-wrap items-center content-start"
                    >
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="save-quote-name"
                                >Quote Name</label
                            >
                        </div>
                        <div class="w-2/3">
                            <input
                                type="text"
                                class="block border rounded-sm leading-tight w-full p-1"
                                id="save-quote-name"
                                .value="${this.meta.name}"
                                @input=${(e) => this.onNameInput(e)}
                            />
                        </div>
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="save-extra-details"
                                >Extra Details</label
                            >
                        </div>
                        <div class="w-2/3">
                            <input
                                type="text"
                                class="block border rounded-sm leading-tight w-full p-1"
                                id="save-extra-details"
                                .value="${this.meta.details}"
                                @input=${(e) =>
                                    store.dispatch(
                                        quoteMetaUpdate({
                                            details: e.target.value,
                                        })
                                    )}
                            />
                        </div>
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="save-extra-details"
                                >Job #</label
                            >
                        </div>
                        <div
                            class="w-2/3 ${hasJobNumber(this.meta)
                                ? "p-2 rounded border-2 border-peppermint"
                                : nothing}"
                        >
                            ${this.meta.jobNumber}
                        </div>

                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="save-extra-details"
                                >Job Name</label
                            >
                        </div>
                        <div class="w-2/3">
                            <input
                                type="text"
                                class="block border rounded-sm leading-tight w-full p-1"
                                .value="${this.meta.jobName}"
                                @input=${(e) =>
                                    store.dispatch(
                                        quoteMetaUpdate({
                                            jobName: e.target.value,
                                        })
                                    )}
                            />
                        </div>
                        <!---->
                        <div class="block mt-2 ml-4">
                            <label>
                                <input
                                    type="checkbox"
                                    class="checkbox"
                                    .checked=${this.hasReachhomeTag()}
                                    @change=${() => this.toggleReachhomeTag()}
                                />
                                REACHHOME service quote
                            </label>
                        </div>
                    </div>
                    <div
                        class="lg:w-1/2 w-full flex flex-wrap items-center content-start"
                    >
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="printing-calc-paper-size"
                                >Created On</label
                            >
                        </div>
                        <div class="w-2/3">
                            ${this.meta.createdOn
                                ? fullDateTime(new Date(this.meta.createdOn))
                                : ""}
                        </div>
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="printing-calc-paper-size"
                                >Created By</label
                            >
                        </div>
                        <div class="w-2/3">${this.meta.createdBy}</div>
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="printing-calc-paper-size"
                                >Last Updated On</label
                            >
                        </div>
                        <div class="w-2/3">
                            ${this.meta.updatedOn
                                ? fullDateTime(new Date(this.meta.updatedOn))
                                : ""}
                        </div>
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label
                                class="block text-right"
                                for="printing-calc-paper-size"
                                >Last Updated By</label
                            >
                        </div>
                        <div class="w-2/3">${this.meta.updatedBy}</div>
                        <!---->
                        <div
                            class="flex w-full items-center ${isDatePast(
                                this.meta.campaignStartDate
                            )
                                ? "p-2 border-2 border-danger"
                                : ""}"
                        >
                            <div class="w-1/3 py-2 pr-2">
                                <label
                                    class="block text-right"
                                    for="printing-calc-paper-size"
                                    >Campaign Start Date</label
                                >
                            </div>
                            <div class="w-2/3">
                                ${this.meta.campaignStartDate
                                    ? new Intl.DateTimeFormat("en-NZ", {
                                          dateStyle: "long",
                                      }).format(
                                          new Date(this.meta.campaignStartDate)
                                      )
                                    : "N/A"}
                            </div>
                        </div>
                        <!---->
                    </div>
                </div>
                <div class="flex lg:flex-row flex-col flex-grow-0">
                    <div
                        class="lg:w-1/2 w-full flex flex-wrap items-center content-start"
                    >
                        <!---->
                        <div class="w-1/3 py-2 pr-2">
                            <label class="block text-right" for="save-tags"
                                >Tags</label
                            >
                        </div>
                        <div class="w-2/3">
                            <input
                                type="text"
                                class="block border rounded-sm leading-tight w-48 p-1"
                                id="save-tags"
                                @keydown=${(e) => {
                                    if ("Enter" == e.key) {
                                        this.addTag();
                                        this.resetTagInput();
                                    }
                                }}
                            />
                        </div>
                        <!---->
                        <div class="w-1/3"></div>
                        <div class="w-2/3">
                            <span
                                class="flex items-center ${(
                                    (this.meta || {}).tags || []
                                ).length
                                    ? ""
                                    : "hidden"}"
                            >
                                <rm-words-cloud
                                    @client:selected:option=${(e) =>
                                        this.removeTag(e.detail)}
                                    class="inline-block mb-1"
                                    .items=${this.meta.tags}
                                    .selected=${this.meta.tags}
                                ></rm-words-cloud>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex-grow"></div>
                <div
                    class="flex flex-row-reverse flex-grow-0 -mx-4 pt-4 px-4 border-t border-dark-grey items-center"
                >
                    <rm-save-quote-button
                        .saveAsNew=${this.saveAsNew}
                        @client:quote:save=${() => {
                            document.dispatchEvent(clientDialogCloseEvent);
                        }}
                    ></rm-save-quote-button>

                    ${this.meta.jobNumber &&
                    this.meta.jobNumber.toString().length > 0 &&
                    !this.saveAsNew
                        ? html`<span
                              class="bg-peppermint-50 text-sm px-2 py-1 mr-2"
                              >Saving this quote will allow updating the
                              respective job in Liberty
                          </span>`
                        : nothing}

                    <div class="flex-grow">
                        <label class="text-sm select-none" for="save-as-new"
                            >Save As New</label
                        >
                        <input
                            type="checkbox"
                            class="checkbox align-middle"
                            id="save-as-new"
                            .checked=${this.saveAsNew}
                            ?disabled="${0 === this.meta.id.length ||
                            isQuoteLocked(this.quote)}"
                            @change=${(e) =>
                                this.toggleSaveAsNew(e.target.checked)}
                        />

                        <span class="bg-peppermint-50 text-sm px-2 py-1 ml-2"
                            >${this.saveAsNew
                                ? `You will create a new quote if save now`
                                : `You will overwrite existing quote if save now`}
                        </span>
                    </div>
                </div>
            </div>
        `;
    }
}

global.customElements.define("rm-save-quote", SaveQuote);
