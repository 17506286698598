import { subscribe } from "redux-subscribe";
import clonedeep from "lodash-es/cloneDeep";

import { URL_GEO_API } from "../../config/local";

import store from "../../store";
import { noop } from "../../core/actions/actions";
import { territoryApplyTargeting } from "../../core/actions/territoryActions";

import { getTerritoryById } from "../../core/selectors/territorySelector";
import { getTargetingFilterById } from "../../core/selectors/targetingSelector";

import targetingVariables from "../../const/targetingVariables";
import { translateNetworkChannels } from "../../const/translateNetworksChannels";

import { reduxActiveRoundsUpdateEvent } from "../../const/dataLayers";
import { clientWarningEvent } from "../../components/toast";

let activeTerritory;
store.dispatch(
    subscribe("activeTerritory", "roundsService-Watcher", (data) => {
        activeTerritory = data.next;
        return noop("roundsService-Watcher");
    })
);

export const targetActiveTerritory = async () => {
    return targetTerritory(activeTerritory);
};

/**
 * Generates API request and dispatches territory update for rounds selection status
 * This function expects territory to have a targeting filter
 *
 * @param {Number} territoryId Territory ID to target - its rounds will get selected state update
 */
export const targetTerritory = async (territoryId) => {
    const request = {};

    const territory = getTerritoryById(territoryId);
    const filter = getTargetingFilterById(territoryId);
    let baseRounds = [];

    // respect network selections
    const volumeSelections = {};
    Object.keys(territory.volumeSelections)
        .filter((network) => territory.networkSelections.includes(network))
        .map(
            (network) =>
                (volumeSelections[network] = clonedeep(
                    territory.volumeSelections[network]
                ))
        );
    request.networkChannels = translateNetworkChannels(volumeSelections);

    if (filter.volumeLimit) {
        request.volumeLimit = filter.volumeLimit;
    }

    // targeting filter stores all base rounds across all networks, so actual
    // network selection has to be respected
    baseRounds = Object.keys(filter.rounds)
        .filter((network) => territory.networkSelections.includes(network))
        .flatMap((network) => filter.rounds[network]);

    if (baseRounds.length > 0) {
        request.rounds = baseRounds;
    }

    request.groups = filter.groups.map((group) => {
        const out = {
            variables: group.variables,
            minThreshold: group.minThreshold ? group.minThreshold : undefined,
            maxThreshold: group.maxThreshold ? group.maxThreshold : undefined,
            values: group.values.length ? group.values : undefined,
        };

        // adjust percent values - user will enter percent value, API needs fractions
        if ("Fraction" === targetingVariables[group.variablesGroup].type) {
            out.minThreshold /= 100;
            out.maxThreshold /= 100;
        }

        return out;
    });

    let data, response;
    try {
        response = await fetch(`${URL_GEO_API}/targeting`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        });

        if (!response.ok) {
            document.dispatchEvent(
                clientWarningEvent({ message: await response.text() })
            );
            return;
        }
    } catch (error) {
        document.dispatchEvent(
            clientWarningEvent({
                message: "Couldn't fetch results, please try again!",
            })
        );
        return;
    }

    try {
        data = await response.json();
    } catch (error) {
        console.error(error);
        document.dispatchEvent(
            clientWarningEvent({
                message: "Couldn't digest results, please try again!",
            })
        );
        return;
    }

    store.dispatch(
        territoryApplyTargeting({
            territoryId,
            baseRounds,
            targetedRounds: data.map((round) => parseInt(round.walk_id)),
        })
    );
    document.dispatchEvent(reduxActiveRoundsUpdateEvent);
};
